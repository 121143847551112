.u-height {
    &--100 {
        height: 100%;
    }

    &--70 {
        height: 70%;
    }

    &--80 {
        height: 80%;
    }
}
