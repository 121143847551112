.c-img {
    &--avatar {
        border-radius: 6px;
        width: 30px;
        height: 30px;
        object-fit: cover;

        &--med {
            height: calc($unit * 10);
            width: calc($unit * 10);
        }
    }

    &--overlay {
        border-radius: 6px;
        position: relative;
        display: inline-block;
        height: fit-content;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
            height: 30px;
            display: inline-block;
            background: rgba(21, 40, 137, 0.12);
            border-radius: 6px;
        }
        img {
            display: block;
        }

        &--med {
            &:after {
                height: calc($unit * 10);
                width: calc($unit * 10);
            }
        }
    }

    &--clickable {
        &:hover {
            cursor: pointer;
        }

        &--decor {
            display: flex;

            border: 1px solid transparent;
            border-radius: 100px;
            align-items: center;

            justify-content: center;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: $white;
                border: 1px solid $color-primary-10;
                transition: all 0.2s ease-in-out;
            }

            svg {
                border-radius: 100px;
            }
        }
    }
}
