.react-datetime-picker {
    font-family: $ff-sans !important;
    /* width: 280px !important; */

    &__wrapper {
        height: calc($unit * 10);
        border: 1px solid $color-lblue-12 !important;
        background-color: $white;
        border-radius: $radius-base;
        font-size: $type-sml;
        padding: calc($unit * 3) calc($unit * 3);
    }

    &__inputGroup {
        margin-top: -2px;

        &__divider:nth-child(3) {
            color: $color-lblue-16 !important;
        }

        &__divider:nth-child(5) {
            color: $color-lblue-16 !important;
        }
        &__input,
        &__amPm {
            &:placeholder {
                color: $color-dblue-6 !important;
            }

            &:invalid {
                background: transparent !important;
            }

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus {
                border: 1px solid $color-lblue-12;
                -webkit-text-fill-color: $color-dblue-12;
                transition: background-color 500ms ease-in-out 0ms;
            }

            &:focus {
                background: transparent !important;
                outline: 0;
                border-radius: calc($unit * 1);
                box-shadow: 0 0 0 2px $color-secondary;
            }
        }

        &__input {
            height: auto !important;
            padding: 0px !important;
            margin-bottom: 2px !important;
        }

        &__input {
            &:focus {
                box-shadow: 0 0 0 0px transparent;
            }

            &::placeholder {
                color: $color-dblue-6;
            }
        }

        &__amPm {
            background-size: calc($unit * 2) !important;
        }

        &__amPm,
        &__hour,
        &__minute {
            display: none;
        }

        &__divider {
            padding: 0px 1px !important;
            color: transparent;
        }

        &__leadingZero {
            display: none !important;
        }

        &__year,
        &__month,
        &__day {
            color: $color-dblue-9 !important;
        }
    }

    &__button {
        padding: 0px !important;
        svg {
            max-width: 80%;
            width: calc($unit * 3) !important;
            height: calc($unit * 3) !important;
            stroke: $color-dblue-7 !important;
        }
    }

    &__calendar {
        margin-top: calc($unit * 3);
        width: 280px !important;
    }

    &__clear-button {
        /* display: none; */
        margin-right: $unit;
    }

    &.light {
        .react-datetime-picker__wrapper {
            background-color: #f7f9ff;
        }
    }

    &.lrg {
        height: 41px;
    }

    &.error {
        .react-datetime-picker__wrapper {
            border: 1px solid $color-error !important;
        }
    }
}

.react-calendar {
    width: 280px !important;
    font-family: $ff-sans !important;
    background-color: $white;
    border-width: 0px !important;
    padding: calc($unit * 4) !important;
    border-radius: calc($unit * 3) !important;
    border: 1px solid #e9effe;

    box-shadow: 0px calc($unit * 3) calc($unit * 5) rgba(104, 124, 174, 0.3);

    button {
        margin: calc($unit/2) !important;
    }

    &__navigation {
        height: auto !important;
        padding: 0px !important;
        justify-content: space-between;
        margin-bottom: calc($unit * 2) !important;

        &__label {
            font-family: $ff-sans !important;
            padding: calc($unit * 3);
            border-radius: calc($unit * 2);
            background-color: $color-lblue-4 !important;
            color: $color-dblue-9;
            font-size: $type-xsml;
            font-weight: $type-weight-medium;
        }

        &__arrow {
            min-width: calc($unit * 7.5) !important;
            color: $color-dblue-4 !important;
            font-weight: $type-weight-semibold;
            font-size: $type-med;
            border-radius: calc($unit * 2);
            background-color: transparent;
            transition: all 0.18s ease-in-out;

            &:hover {
                border-radius: calc($unit * 2);
                background-color: $color-lblue-4 !important;
                transition: all 0.18s ease-in-out;
            }
        }
        &__prev2-button,
        &__next2-button {
            display: none;
        }
    }

    &__viewContainer {
    }

    &__tile {
        min-width: calc($unit * 7.5) !important;
        padding: calc($unit * 2) calc($unit * 3);
        border-radius: calc($unit * 2);
        /* background-color: $color-lblue-4 !important; */

        transition: all 0.16s ease-in-out;

        abbr {
            color: $color-dblue-7;
            font-size: $type-sml;
        }

        &--active {
            background-color: $color-primary-8 !important;
            abbr {
                color: $white;
            }
        }

        &:hover {
            transition: all 0.16s ease-in-out;
            background-color: $color-lblue-8 !important;
        }
    }

    &__month-view {
        &__weekdays {
            &__weekday {
                abbr {
                    color: #a5b2d1;
                    font-size: $type-tny;
                    font-weight: $type-weight-medium;
                    text-decoration: none !important;
                }
            }
        }

        &__days {
            gap: 0px;
            &__day {
                flex: 1 !important;
                abbr {
                    font-weight: $type-weight-medium !important;
                }
                &--neighboringMonth {
                    background-color: $white !important;
                    abbr {
                        color: $color-lblue-14 !important;
                    }
                }
            }
        }
    }

    &__tile--now {
        background-color: $color-lblue-5 !important;
        border: 1px solid color($color-primary-4 a(0.4)) !important;
        abbr {
            font-weight: $type-weight-bold !important;
            color: $color-primary-10;
        }
    }

    &__year-view {
        &__months {
            &__month {
                flex: 0 0 30% !important;
                text-align: center;
                align-items: center;
                abbr {
                    font-size: $type-xsml;
                }
            }
        }
    }

    &__decade-view {
        &__years {
            &__year {
                flex: 0 0 30% !important;
                text-align: center;
                align-items: center;
                color: color($color-dblue-7 a(0.7));
                font-size: $type-xsml;
            }
        }
    }

    &__century-view {
        &__decades {
            &__decade {
                flex: 0 0 30% !important;
                text-align: center;
                align-items: center;
                color: color($color-dblue-7 a(0.7));
                font-size: $type-xsml;
            }
        }
    }

    button.react-calendar__year-view__months__month {
        margin: calc($unit * 1) !important;
    }
}
