.u-width {
    &--100 {
        width: 100%;

        &--sml {
            @media (--screen-to-sml) {
                width: 100%;
            }
        }
    }

    &--180 {
        width: 180px;

        &--max {
            max-width: 180px;
        }
    }

    &--300 {
        width: 300px;

        &--max {
            max-width: 300px;
        }
    }

    &--500 {
        width: 500px;
        &--max {
            max-width: 500px;
        }
    }

    &--600 {
        width: 600px;
        &--max {
            max-width: 600px;
        }
    }

    &--615 {
        max-width: 615px;
    }

    &--full-screen {
        width: calc(100vw - 320px);
    }

    &--unset {
        min-width: unset;

        &--max {
            max-width: unset;
        }
    }

    &--min {
        &--xsml {
            min-width: 14px;
        }

        &--med {
            min-width: 120px;
        }
    }
}
