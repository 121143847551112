.notistack-MuiContent {
    background-color: $color-error-dark !important;
    border-radius: calc($unit * 2) !important;
    padding: calc($unit * 3) calc($unit * 5) !important;
}

.notistack-MuiContent.go1888806478 {
    background-color: $color-primary-10 !important;
}

.notistack-MuiContent.go1725278324 {
    background-color: $color-success !important;
}

.notistack-MuiContent.go4215275574 {
    background-color: $color-warning !important;
}

.notistack-MuiContent.go3651055292 {
    background-color: $color-error-dark !important;
}

#notistack-snackbar {
    font-size: $type-base !important;
    font-weight: $type-weight-regular !important;
}

#notistack-snackbar svg {
    font-size: $type-lrg !important;
}

.SnackbarItem-variantSuccess {
    background-color: $color-success !important;
}
