.c-empty {
    display: flex;
    flex-direction: column;
    flex: 1;

    padding-top: calc($unit * 6);
    padding-bottom: 50px;
    padding-right: 0px;
    padding-left: 0px;

    align-items: center;
    text-align: center;

    border-radius: calc($unit * 5);

    &--primary,
    &--tertiary {
        background-color: $white;
        border: 2px dashed color(#c2d3ff a(0.5));
    }

    &--secondary {
        background-color: #f7f9ff;
        border: 2px dashed color(#d1deff a(0.8));
    }

    &__title {
        margin-bottom: calc($unit * 2);

        font-size: $type-base;
        font-weight: $type-weight-bold;
        color: $color-dblue-10;
    }

    &__text {
        font-size: $type-sml;
        color: #5e72a5;
        font-weight: $type-weight-regular;
    }

    &--np {
        padding: 0px;
    }

    &--xsml {
        width: 140px;
        height: auto;
    }

    &--sml {
        width: 200px;
        height: auto;
    }

    &--base {
    }

    &--med {
    }

    &--lrg {
    }
}
