/**
* Styles used for Portal styling only
*/

@import '../../../assets/css/App.css';

/**
* Components
*/

@import './assets/css/c.features.css';

@import './assets/css/c.logo.css';
@import './assets/css/c.toggle.css';

/**
* Global
*/
html,
body,
#root {
    height: 100%;
    width: 100%;
}

.l-container {
    /* overflow: hidden; */
}

.l-wrapper {
    display: flex;

    height: 100%;
    width: 100%;
}

.l-content {
    height: 100%;
    width: 100%;
}

.l-header {
    margin: 12px;

    background-color: #339dff;
}


[aria-current='page'] {
    background-color: red;
}

.form-base {
    display: flex;
    align-items: center;
}
