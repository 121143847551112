.c-tabs {
    display: flex;
    /* flex: 0 0 60%; */
    padding: calc($unit * 1);

    gap: 8px;

    border-radius: calc($unit * 3);
    border: 1px solid $color-lblue-8;

    &--primary {
        background-color: $white;

        @media (--screen-to-sml) {
            flex: 1;
            flex-direction: column;
            width: 100%;
        }
    }

    &--secondary {
        background-color: none;
        padding: 0px;
        margin: 0px;
        border: none;
        gap: 0px;
    }

    &--tertiary {
        background-color: $color-lblue-7;
        width: 100%;
    }

    &__item {
        font-size: $type-sml;
        font-weight: $type-weight-medium;
        color: $color-primary-10;
        text-align: center;

        border-radius: calc($unit * 2.5);

        z-index: 2;

        &--xsml {
            padding: calc($unit * 1.5) calc($unit * 2);
        }

        &--sml {
            min-width: 80px;
            padding: calc($unit * 2.5) calc($unit * 2);
        }

        &--base {
            min-width: 100px;
            padding: calc($unit * 2.5) calc($unit * 2);

            @media (--screen-from-lrg) {
                min-width: 140px;
            }

            @media (--screen-to-lrg) {
                flex: 1;
            }

            @media (--screen-to-sml) {
                flex: 1;
            }
        }

        &--smed {
            min-width: 100px;
            padding: calc($unit * 2.5) calc($unit * 2);
        }

        &--med {
            min-width: 130px;
            padding: calc($unit * 2.5) calc($unit * 2);

            @media (--screen-from-lrg) {
                min-width: 196px;
            }
        }

        &--lrg {
            padding: calc($unit * 3);
        }

        &--full {
            width: 100%;
        }

        &:hover {
            color: $color-primary-12;
            background-color: $color-lblue-7;
            border-radius: calc($unit * 2.5);
        }

        &.active {
            color: $white;
            background-color: $color-primary-10;
            border-radius: calc($unit * 2.5);
        }

        &--secondary {
            font-size: $type-sml;
            line-height: calc($unit * 6);
            font-weight: $type-weight-medium;
            color: $color-primary-10;
            text-align: center;
            padding: 0px calc($unit * 8);
            border-bottom: 2px solid transparent;
            z-index: 2;

            transition: all 0.2s ease-in-out;

            cursor: pointer;

            &.active {
                border-bottom: 2px solid $color-primary-10;
                background-color: transparent !important;
                transition: all 0.2s ease-in-out;
                color: $color-primary-10;
            }

            &:hover {
                color: $color-primary-10;
                border-bottom: 2px solid $color-primary-6;
            }

            &--sml {
                padding: 0px calc($unit * 5);
            }
        }

        &--disabled {
            cursor: unset;
            color: $color-dblue-6;
            &:hover,
            &:focus {
                color: $color-dblue-6;
                background-color: transparent;
            }
        }

        /* --TODO - Add glider on tabs */

        /* &__glider {
            position: absolute;
            display: flex;
            height: 50px;
            width: 128px;
            background-color: $color-primary-10;
            z-index: 1;
            border-radius: calc($unit * 2.5);
            transition: 0.25s ease-out;
            transform: translateX(0);
        }
        &.js-item-2 {
            &.active {
                .c-tabs__glider {
                    transform: translateX(100%);
                }
            }
        } */
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: calc($unit * 3);
        flex-wrap: wrap;
        row-gap: calc($unit * 3);
        margin-bottom: calc($unit * 3);

        @media (--screen-to-sml) {
            flex-direction: column;
            width: 100%;
        }
    }
}
