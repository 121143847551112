.ReactModalPortal > .ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgba(52, 68, 101, 0.5) !important;
}

.ReactModalPortal
    > .ReactModal__Overlay.ReactModal__Overlay--after-open
    > .ReactModal__Content.ReactModal__Content--after-open {
    box-shadow: 0px 4px 20px rgba(91, 101, 142, 0.3);
    padding: 24px !important;
    border-radius: 16px !important;
    
}



