.c-features {
    max-width: 300px;
    padding: 16px;
    background-color: $white;
    border-radius: 12px;

    box-shadow: 0px 4px 30px rgba(155, 166, 212, 0.1);

    &__container {
        display: flex;
        justify-content: space-between;
    }

    &__feature {
        &__title {
            font-size: $type-sml;
            font-weight: $type-weight-bold;
            color: $color-dblue-9;
        }
        &__value {
            font-size: $type-sml;
            font-weight: $type-weight-regular;
            color: $color-dblue-12;
        }
    }

    .col {
        padding: 0px;
    }
}
