.c-modal {
    &__title {
        font-size: $type-med;
        /* color: #415680; */
        color: $color-dblue-10;
    }

    &__name {
        font-size: $type-xsml;
        font-weight: $type-weight-semibold;
        color: $color-primary-8;
        padding: 4px 8px;
        border-radius: 100px;
        background-color: $color-lblue-6;
        text-transform: uppercase;
    }

    &__subtitle {
        font-size: $type-xsml;
        font-weight: $type-weight-semibold;
        text-transform: uppercase;

        &--primary {
            color: $color-primary-10;
        }

        &--secondary {
            color: $color-primary-8;
            padding: 4px 8px;
            border-radius: 100px;
            background-color: $color-lblue-6;
        }
    }

    &__body {
        &--lrg {
            max-height: 300px;
            overflow-y: auto;
        }
    }

    &__buttons {
        display: flex;
        gap: 6px;
    }

    &__previous-value {
        display: flex;
        gap: 4px;
        align-items: center;

        &__title {
            font-weight: $type-weight-regular;
            font-size: $type-xsml;
        }

        &__tag {
            padding: 1px 6px;
            border-radius: 100px;
            background-color: $color-lblue-6;
        }

        &__value {
            font-weight: $type-weight-semibold;
            font-size: $type-xsml;
            color: $color-primary-10;
        }
    }

    &--primary {
        .ReactModal__Content--after-open {
            width: 344px !important;
        }

        .ReactModal__Overlay--after-open {
            z-index: 200 !important;
        }

        @media (--screen-to-med) {
            .ReactModal__Content--after-open {
                width: 320px !important;
            }
        }
    }

    &--secondary {
        .ReactModal__Content--after-open {
            width: 500px !important;
        }

        .ReactModal__Overlay--after-open {
            z-index: 200 !important;
        }
    }

    &--tertiary {
        .ReactModal__Content--after-open {
            width: 420px !important;
        }

        .ReactModal__Overlay--after-open {
            z-index: 200 !important;
        }
    }

    &--lrg {
        .ReactModal__Content--after-open {
            width: 600px !important;
        }

        .ReactModal__Overlay--after-open {
            z-index: 200 !important;
        }
    }

    &--huge {
        .ReactModal__Content--after-open {
            width: 700px !important;
        }

        .ReactModal__Overlay--after-open {
            z-index: 200 !important;
        }

        @media (--screen-to-med) {
            .ReactModal__Content--after-open {
                width: 320px !important;
                max-height: 500px;
                overflow-y: auto !important;
            }
        }
    }

    &--xhuge {
        .ReactModal__Content--after-open {
            width: 1025px !important;
        }

        .ReactModal__Overlay--after-open {
            z-index: 200 !important;
        }
    }

    &--shipping-instructions {
        ol {
            padding: 0px 12px;
            list-style: auto;
        }

        &__img {
            width: 70%;

            @media (--screen-to-sml) {
                width: 100%;
            }
        }

        @media (--screen-to-lrg) {
            flex-direction: column;
        }
    }
}

.ReactModal__Content.ReactModal__Content--after-open {
    border-radius: 12px !important;
}
