.c-separator {
    &--vertical {
        border-left: 1px solid $color-lblue-8;
        border-right: 1px solid $color-lblue-8;

        &--sml {
        }

        &--base {
            height: 40px;
        }

        &--lrg {
            height: 60px;
        }

        &--secondary {
            border-left: 1px solid $color-lblue-10;
            border-right: 0px;
            border-top: 0px;
            border-bottom: 0px;
            width: 4px;
            margin: 4px;
            padding: 0px;
        }
    }

    &--color-xlight {
        border: 0.5px solid $color-lblue-3;
    }

    &--color-light {
        border: 0.5px solid $color-lblue-7;
    }

    &--color-base {
        border: 0.5px solid color($color-lblue-9 a(0.6));
    }

    &--color-med {
        border: 0.5px solid $color-lblue-12;
    }

    &--color-xdark {
        border: 0.5px solid #0f2f4d;
    }
}
