/*------------------------------------*\
    # utils.cursor
\*------------------------------------*/

.u-cursor {
	&--pointer {
		cursor: pointer;
	}

	&--grab {
		cursor: grab;
	}	
}
