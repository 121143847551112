/*------------------------------------*\
    # utils.icon
\*------------------------------------*/
.u-icon {
    display: inline-block;

    background-repeat: no-repeat;
    background-position: center center;
    vertical-align: middle;
    background-size: contain;

    transition: all 250ms ease-out;

    /**
    * Sizes
    */
    &--sml {
        min-width: calc($unit * 2.5);
        height: calc($unit * 2.5);
    }

    &--sbase {
        width: calc($unit * 3);
        height: calc($unit * 3);
        background-size: calc($unit * 3);
    }

    &--base {
        width: calc($unit * 3.5);
        height: calc($unit * 3.5);
        background-size: calc($unit * 3.5);
    }

    &--med {
        min-width: calc($unit * 4);
        height: calc($unit * 4);
        background-size: calc($unit * 4);
    }

    &--slrg {
        width: calc($unit * 5);
        height: calc($unit * 5);
    }

    &--lrg {
        width: calc($unit * 6);
        height: calc($unit * 6);
    }

    &--xlrg {
        width: calc($unit * 8);
        height: calc($unit * 8);
    }

    &--xxlrg {
        width: calc($unit * 12);
        height: calc($unit * 12);
    }

    &--huge {
        width: calc($unit * 25);
        height: calc($unit * 25);
    }

    /**
    * Styles - Navigation
    */
    &--home {
        background-image: svg-load(
            "../postcss-svg/navigation-icons/home.svg",
            stroke: #afbcd8
        ); //stroke: #fff --> active
    }

    &--my-deals {
        background-image: svg-load("../postcss-svg/navigation-icons/my-deals.svg", stroke: #afbcd8);
    }

    &--deals {
        background-image: svg-load("../postcss-svg/navigation-icons/shopping-cart.svg", stroke: #afbcd8);
    }
    &--catalog {
        background-image: svg-load("../postcss-svg/navigation-icons/catalog.svg", stroke: #afbcd8);
    }
    &--commitments {
        background-image: svg-load("../postcss-svg/navigation-icons/commitments.svg", stroke: #afbcd8);
    }
    &--trackings {
        background-image: svg-load("../postcss-svg/navigation-icons/trackings.svg", stroke: #afbcd8);
    }
    &--balance {
        background-image: svg-load("../postcss-svg/navigation-icons/balance.svg", stroke: #afbcd8);
    }

    &--settings {
        background-image: svg-load("../postcss-svg/navigation-icons/settings.svg", stroke: #afbcd8);
    }

    &--faq {
        background-image: svg-load("../postcss-svg/navigation-icons/FAQ.svg", stroke: #afbcd8);
    }

    &--chat {
        background-image: svg-load("../postcss-svg/navigation-icons/chat.svg", stroke: #afbcd8);
    }

    &--tickets {
        background-image: svg-load("../postcss-svg/navigation-icons/tickets.svg", stroke: #afbcd8);
    }

    &--warehouse {
        background-image: svg-load("../postcss-svg/navigation-icons/warehouse.svg", stroke: #afbcd8);
    }

    &--user {
        background-image: svg-load("../postcss-svg/navigation-icons/user.svg", stroke: #afbcd8);
    }

    &--finances {
        background-image: svg-load("../postcss-svg/navigation-icons/finances.svg", stroke: #afbcd8);
    }

    /**
    * Styles - Errors
    */
    &--error {
        background-image: svg-load("../postcss-svg/error-icons/exclamation.svg", stroke: $color-error);
    }

    &--warning {
        background-image: svg-load("../postcss-svg/error-icons/exclamation.svg", stroke: $color-error);

        filter: brightness(0) saturate(100%) invert(51%) sepia(45%) saturate(3551%) hue-rotate(357deg) brightness(91%)
            contrast(96%);
    }

    &--success {
        background-image: svg-load("../postcss-svg/navigation-icons/commitments.svg", stroke: $color-success);
    }

    &--success-info {
        background-image: svg-load("../postcss-svg/navigation-icons/commitments.svg", stroke: $color-primary-10);
    }

    /**
    * Styles - Stock status
    */

    &--stock-status {
        min-width: 6px;
        width: 6px;
        height: 6px;

        margin-right: calc($unit * 1.5);

        border-radius: 100px;
    }

    &--InStock {
        background-color: $color-success;
    }

    &--OutOfStock {
        background-color: $color-error;
    }

    &--LowInStock {
        background-color: $color-warning;
    }

    &--Unknown {
        background-color: $color-lblue-14;
    }

    /**
    * Styles - General
    */
    &--check,
    &--shipped-out,
    &--packed {
        background-image: svg-load("../postcss-svg/default-icons/check.svg");
    }

    &--check-fill {
        background-image: svg-load("../postcss-svg/default-icons/check-fill.svg");
    }

    &--price {
        background-image: svg-load("../postcss-svg/default-icons/dollar.svg", fill: $color-primary-10 !important;);
    }

    &--commission {
    }

    &--committed {
        background-image: svg-load("../postcss-svg/default-icons/check-custom.svg", stroke: $color-primary-10);
        background-size: 12px;
    }

    &--arrow-down {
        background-image: svg-load("./../postcss-svg/default-icons/arrow-down.svg", stroke: #596f9b);
    }

    &--arrow-up {
        background-image: svg-load("./../postcss-svg/default-icons/arrow-down.svg", stroke: #596f9b);
        transform: rotate(180deg);
    }

    &--arrow-prev {
        background-image: svg-load("../postcss-svg/default-icons/arrow-prev.svg", stroke: $white);

        &--primary {
            background-image: svg-load("../postcss-svg/default-icons/arrow-prev.svg", stroke: #596f9b);
        }
    }

    &--arrow-next {
        background-image: svg-load("../postcss-svg/default-icons/arrow-next.svg", stroke: #7188bb);

        &--primary {
            background-image: svg-load("../postcss-svg/default-icons/arrow-next.svg", stroke: #596f9b);
        }

        &--white {
            background-image: svg-load("../postcss-svg/default-icons/arrow-next.svg", stroke: $white);
        }
    }

    &--arrow {
        &--left {
            background-image: svg-load("../postcss-svg/default-icons/arrow-left.svg", stroke: #afbcd8);
        }

        &--right {
            background-image: svg-load("../postcss-svg/default-icons/arrow-right.svg", stroke: $color-primary);
        }
    }

    &--arrow-first {
        background-image: svg-load("../postcss-svg/default-icons/arrow-first.svg");
    }

    &--arrow-last {
        background-image: svg-load("../postcss-svg/default-icons/arrow-last.svg");
    }

    &--help {
        background-image: svg-load("../postcss-svg/default-icons/help.svg");

        margin-top: 2px;
    }

    &--plus {
        background-image: svg-load("../postcss-svg/default-icons/plus.svg", stroke: $white);
    }

    &--plus-blue {
        background-image: svg-load("../postcss-svg/default-icons/plus.svg", stroke: $color-primary-10);
    }

    &--attachment {
        background-image: svg-load("../postcss-svg/actions/attachment.svg", stroke: $color-primary-10);
    }

    &--download {
        background-image: svg-load("../postcss-svg/actions/download.svg", stroke: $color-primary-10);
        &--white {
            background-image: svg-load("../postcss-svg/actions/download.svg", stroke: $white);
        }
    }

    &--copy {
        background-image: svg-load("../postcss-svg/default-icons/copy.svg", stroke: $color-primary-10);
        cursor: pointer;
    }

    /* User profile actions */
    &--note {
        background-image: svg-load(
            "../postcss-svg/default-icons/user-actions/note-2.svg",
            stroke: $color-primary-10 !important;
        );
    }

    &--ticket {
        background-image: svg-load(
            "../postcss-svg/default-icons/user-actions/ticket.svg",
            stroke: $color-primary-10 !important;
        );
    }

    &--user-edit {
        background-image: svg-load(
            "../postcss-svg/default-icons/user-actions/edit.svg",
            stroke: $color-primary-10 !important;
        );
    }

    &--edit {
        background-image: svg-load("../postcss-svg/default-icons/edit.svg", stroke: $color-primary-10 !important;);
    }

    &--delete {
        background-image: svg-load(
            "../postcss-svg/default-icons/user-actions/delete.svg",
            stroke: $color-primary-10 !important;
        );

        &--warning {
            background-image: svg-load("../postcss-svg/default-icons/user-actions/delete-warning.svg");
        }
    }

    &--import {
        background-image: svg-load("../postcss-svg/actions/upload.svg", stroke: $color-primary-10 !important;);
    }

    &--close {
        background-image: svg-load(
            "../postcss-svg/default-icons/close.svg",
            stroke: #b5b5b5 !important,
            fill: #b5b5b5 !important
        );

        &--dark {
            background-image: svg-load("../postcss-svg/default-icons/close.svg", fill: $system-primary;);
        }
    }

    &--arrow-top {
        background-image: svg-load("../postcss-svg/default-icons/arrow-top.svg");
    }

    &--arrow-bottom {
        background-image: svg-load("../postcss-svg/default-icons/arrow-bottom.svg");
    }

    &--loader,
    &--packing {
        background-image: svg-load("../postcss-svg/default-icons/loader.svg");
    }

    &--on-hold {
        background-image: svg-load("../postcss-svg/default-icons/close.svg");
    }

    &--messages {
        background-image: svg-load("../postcss-svg/default-icons/chat-bubble.svg");
    }

    &--user-icon {
        background-image: svg-load("../postcss-svg/default-icons/user.svg");
    }

    &--search {
        background-image: svg-load("../postcss-svg/default-icons/icon-search.svg", fill: #7188bb);
    }

    &--spr {
        &--1 {
            background-image: svg-load("../postcss-svg/default-icons/spr-new.svg");
        }

        &--2 {
            background-image: svg-load("../postcss-svg/default-icons/spr-packing.svg");
        }

        &--3 {
            background-image: svg-load("../postcss-svg/default-icons/spr-packed.svg");
        }

        &--4 {
            background-image: svg-load("../postcss-svg/default-icons/spr-shipped.svg");
        }
    }

    &--location {
        background-image: svg-load("../postcss-svg/default-icons/location.svg", stroke: $color-primary-10);
    }

    &--clock {
        background-image: svg-load("../postcss-svg/default-icons/clock.svg", stroke: $color-primary-10);
    }

    &--user-2 {
        background-image: svg-load("../postcss-svg/default-icons/user.svg", stroke: $color-primary-10);
    }

    &--level {
        background-image: svg-load("../postcss-svg/default-icons/level.svg", stroke: $color-primary-10 !important);
    }

    &--line {
        background-image: svg-load("../postcss-svg/default-icons/line.svg");
    }

    &--clickable {
        cursor: pointer;
    }

    &--hot-badge {
        background-image: svg-load("../postcss-svg/default-icons/hot-badge.svg");
    }

    &--hot {
        background-image: svg-load("../postcss-svg/default-icons/hot.svg");
    }

    &--sale {
        background-image: svg-load("../postcss-svg/default-icons/percent.svg");
    }

    &--profit {
        background-image: svg-load("../postcss-svg/default-icons/profit-dollar.svg");
    }

    &--coupon {
        background-image: svg-load("../postcss-svg/default-icons/coupon.svg");
    }

    &--membership {
        background-image: svg-load("../postcss-svg/default-icons/membership.svg");
    }

    &--outbid {
        background-image: svg-load("../postcss-svg/default-icons/outbid.svg");
    }
    &--white {
        filter: brightness(0) invert(1) !important;
    }
}
