.c-tag {
    max-width: 120px;
    padding: 2px 8px;
    height: fit-content;

    font-size: $type-xsml;
    font-weight: $type-weight-semibold;

    text-transform: uppercase;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    border-radius: 100px;

    &--common {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: $type-xsml;
        font-weight: $type-weight-semibold;
    }

    &--sml {
        height: calc($unit * 4);
        width: calc($unit * 4);
    }

    &--rounded {
        border-radius: 100px;
    }

    &--error {
        color: $color-error;
        border: 1px solid $color-error;
    }

    &--warning {
        color: $color-warning;
        border: 1px solid $color-warning;
    }

    &--info {
        color: $color-primary-10;
        border: 1px solid $color-primary-10;
    }

    &--secondary {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc($unit * 4);
        width: calc($unit * 4);

        color: $color-primary-10;

        font-size: $type-xsml;
        font-weight: $type-weight-semibold;

        border: 1px solid $color-primary-10;
        border-radius: 100px;
    }

    &--priority {
        padding: calc($unit / 2) calc($unit * 2);
        font-size: $type-xsml;
        font-weight: $type-weight-semibold;
        text-transform: uppercase;

        border-radius: 100px;

        display: flex;
        align-items: center;
        gap: calc($unit * 1);

        &--low {
            background-color: $color-success-light;
            color: $color-success;
            border: 1px solid color($color-success a(0.4));

            i {
                filter: brightness(0) saturate(100%) invert(15%) sepia(100%) saturate(5295%) hue-rotate(155deg)
                    brightness(93%) contrast(97%);
            }
        }

        &--medium {
            background-color: $status-low-background;
            color: #e4690c;
            border: 1px solid color(#e4690c a(0.4));

            i {
                filter: brightness(0) saturate(100%) invert(59%) sepia(39%) saturate(3380%) hue-rotate(346deg)
                    brightness(85%) contrast(112%);
            }
        }

        &--high {
            background-color: $status-outbid-background;
            color: $status-outbid;
            border: 1px solid color($status-outbid a(0.4));

            i {
                filter: brightness(0) saturate(100%) invert(18%) sepia(82%) saturate(6679%) hue-rotate(335deg)
                    brightness(92%) contrast(96%);
                margin-bottom: 2px;
            }
        }

        @media (--screen-to-sml) {
            width: fit-content;
        }
    }

    &--statuses {
        display: flex;
        align-items: center;
        padding: calc($unit / 2) calc($unit * 2);

        background-color: $color-lblue-10;
        color: $status-live;
        border: 1px solid color($color-primary-10 a(0.4));

        font-size: $type-xsml;
        font-weight: $type-weight-semibold;
        text-transform: uppercase;

        border-radius: 100px;

        i {
            scale: 0.8;
        }

        &--2,
        &--3 {
            background-color: $status-low-background;
            color: #e4690c;
            border: 1px solid color(#e4690c a(0.4));
        }

        &--4 {
            background-color: $status-full-background;
            color: $status-full;
            border: 1px solid color($status-full a(0.4));
        }

        &--on-hold {
            color: $color-error;
            border: 1px solid color($color-error a(0.4));
            background-color: $status-outbid-background;
        }
    }

    &--tertiary {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc($unit * 4);
        width: calc($unit * 4);

        color: $color-error;

        font-size: $type-xsml;
        font-weight: $type-weight-semibold;

        border: 1px solid $color-error;
        border-radius: 100px;
    }

    &--sml {
        padding: $unit calc($unit * 1.5);
    }

    &--live,
    &--active {
        background-color: $status-live-background;
        color: $status-live;
        border: 1px solid color($status-live a(0.4));
    }

    &--completed {
        background-color: $status-full-background;
        color: $status-full;
        border: 1px solid color($status-full a(0.4));
    }

    &--ended,
    &--inactive {
        background-color: $white;
        color: $color-text-dark;
        border: 1px solid color($color-text-dark a(0.4));
    }

    &--outbid,
    &--closed,
    &--canceled {
        background-color: $status-outbid-background;
        color: $status-outbid;
        border: 1px solid color($status-outbid a(0.4));
    }

    &--hot {
        z-index: 3;

        height: calc($unit * 5);

        padding: 2px 6px;
        min-width: 53px;

        background: linear-gradient(118deg, #ff7c33 10.9%, rgba(255, 51, 63, 0.86) 115.46%);
        color: $white;
        border: 1px solid color($status-outbid a(0.2));

        font-size: $type-tny;
        line-height: 14px;
    }

    &--other {
        z-index: 3;

        height: calc($unit * 5);

        padding: calc($unit / 2) calc($unit * 1.5);

        background: #fff;
        border: 1px solid #e0e5f3;
        color: $color-primary-10;

        font-size: $type-tny;
        line-height: 14px;

        text-transform: none;
    }

    &--other-2 {
        height: calc($unit * 5);

        position: absolute;
        top: calc($unit * 4);
        left: calc($unit * 4);

        padding: calc($unit / 2) calc($unit * 1.5);

        background: linear-gradient(167deg, #f21256 44.97%, #c8003c 91.58%);
        border: 1px solid color($status-outbid a(0.4));

        color: $white;

        font-size: $type-tny;
        line-height: 14px;

        text-transform: none;
    }

    &--other-3 {
        z-index: 3;

        height: calc($unit * 5);

        max-width: 48px;
        padding: calc($unit / 2) calc($unit * 1.5);

        background: linear-gradient(167deg, #f21256 44.97%, #c8003c 91.58%);
        border: 1px solid color($status-outbid a(0.4));

        color: $white;

        font-size: $type-tny;
        line-height: 14px;

        text-transform: none;
    }

    &--paused {
        background-color: $status-paused-background;
        color: $status-paused;
        border: 1px solid color($status-paused a(0.4));
    }

    &--draft {
        background-color: $status-draft-background;
        color: $status-draft;
        border: 1px solid color($status-draft a(0.4));
    }
    &--receiving {
        background-color: $status-receiving-background;
        color: $status-receiving;
        border: 1px solid color($status-receiving a(0.4));
    }

    &--commitments {
        &--low {
            background-color: $status-low-background;
            color: $status-low;
            border: 1px solid color($status-low a(0.4));
        }
        &--progress {
            background-color: $status-progress-background;
            color: #7d879d;
            border: 1px solid color(#7d879d a(0.4));
        }
        &--full {
            background-color: $status-full-background;
            color: $status-full;
            border: 1px solid color($status-full a(0.4));
        }
        &--empty {
            background-color: $status-empty-background;
            color: $status-empty;
            border: 1px solid color($status-empty a(0.4));
        }
    }

    &--success {
        display: flex;
        gap: calc($unit * 1);
        align-items: center;

        padding: calc($unit/2) calc($unit * 1.5);

        background-color: $color-success-light;
        color: $color-success;

        border: 1px solid color($color-success a(0.1));
    }

    &--basic {
        display: flex;
        gap: calc($unit * 2);
        align-items: center;

        padding: calc($unit * 2);

        background-color: $color-lblue-4;
        color: $color-primary-10;
        font-size: $type-xsml;
        font-weight: $type-weight-semibold;

        border-radius: calc($unit * 2);

        transition: all 0.2s ease-in-out;

        &--active {
            opacity: 1;

            transition: all 0.2s ease-in-out;
        }
    }

    &--primary {
        display: flex;
        align-items: center;
        padding: calc($unit / 4) calc($unit * 1.5);

        background-color: $color-lblue-10;
        color: $status-live;
        border: 1px solid color($status-live a(0.4));

        font-size: $type-xsml;
        font-weight: $type-weight-semibold;
        text-transform: uppercase;

        border-radius: 100px;

        &--success {
            background-color: #dbf2e0;
            color: #1a9d34;

            i {
                filter: brightness(0) saturate(100%) invert(26%) sepia(97%) saturate(856%) hue-rotate(111deg)
                    brightness(100%) contrast(97%);
                margin-bottom: 3px;
            }
        }

        &--low {
            background-color: #ffefe3;
            color: #ff6b00;

            i {
                filter: brightness(0) saturate(100%) invert(51%) sepia(45%) saturate(3551%) hue-rotate(357deg)
                    brightness(91%) contrast(96%);
                margin-bottom: 3px;
            }
        }

        &--sml {
            padding: 0px calc($unit * 1);
        }

        &--clickable {
            &:hover {
                cursor: pointer;
                background-color: $color-lblue-12;
                border: 1px solid color($status-live a(0.8));
                transition: all 0.3s ease-in-out;
            }
        }
    }

    &--abs {
        position: absolute;
        top: calc($unit * 4.5);
        left: calc($unit * 4.5);
    }

    &--verified,
    &--incomplete,
    &--declined {
        /* height: 16px;
        width: 16px; */
        line-height: unset !important;
        padding: 4px;
    }

    &--verified {
        background-color: $color-success-light;
        border: 1px solid color($color-success a(0.4));

        i {
            filter: brightness(0) saturate(100%) invert(32%) sepia(18%) saturate(3495%) hue-rotate(108deg)
                brightness(98%) contrast(97%);
        }
    }

    &--incomplete {
        background-color: $status-low-background;
        border: 1px solid color(#e4690c a(0.4));

        i {
            filter: brightness(0) saturate(100%) invert(37%) sepia(89%) saturate(729%) hue-rotate(351deg)
                brightness(106%) contrast(102%);
        }
    }

    &--declined {
        background-color: $status-outbid-background;
        border: 1px solid color($color-error a(0.4));

        i {
            filter: brightness(0) saturate(100%) invert(21%) sepia(100%) saturate(3290%) hue-rotate(322deg)
                brightness(89%) contrast(97%);
        }
    }
}
