.c-status {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2px;
    height: 8px;

    &__bar {
        background-color: $color-lblue-12;
        border-radius: 100px;
        width: 100%;

        &--active {
            border-radius: 100px;
            /* width: 70%; */
            /* width: attr(data-percent); */
            max-width: 100%;
            height: 8px;
            background: $color-primary-8;
            box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.1);
            transition: width 0.8s ease-in-out;

            &--sml {
                height: 6px;
            }

            &--zero {
                width: 0%;
            }
        }
    }

    &__label {
        color: $color-dblue-6;
        text-align: center;
        font-size: $type-xsml;
        font-weight: $type-weight-medium;
        font-family: $ff-sans;
        margin-left: 8px;
    }
}
