.c-table {
    display: flex;
    flex-direction: column;
    /* width: 920px; */
    min-width: 580px;
    max-width: 600px;
    padding: 16px;
    padding-bottom: 8px;
    border-radius: 12px;
    background-color: $white;

    box-shadow: 0px 4px 30px rgba(155, 166, 212, 0.1);

    @media (--screen-to-sml) {
        overflow-x: auto;
        min-width: unset;
    }

    &--basic {
        padding: 0px;
        box-shadow: none;
    }

    &--base {
        height: fit-content;
    }

    &--med {
        max-width: 956px;
        width: 100%;
        height: fit-content;
    }

    &--lrg {
        max-width: unset;
        width: 100%;
    }

    &__header {
        padding: 0px 0px calc($unit * 3) 0px;
        text-align: left;
        font-size: $type-sml;
        font-weight: $type-weight-bold;
        color: $color-primary-8;

        @media (--screen-to-sml) {
            min-width: 100px;
        }

        &--mobile {
            @media (--screen-to-sml) {
                display: block;
                position: absolute;
                top: -9999px;
                left: -9999px;
            }
        }
    }

    &__row {
        position: relative;
        align-items: center;
        padding: calc($unit * 3) calc($unit * 1);
        text-align: left;
        border-top: 1px solid $color-lblue-7;

        &--basic {
            border-top: 0px solid transparent;
        }

        &--lrg {
            padding: calc($unit * 3);
        }

        &--middle {
            text-align: center;
        }

        &--mobile {
            @media (--screen-to-sml) {
                border-top: none;

                background-color: $color-lblue-5;
                border-radius: 12px;
                padding: 12px;
                padding-top: 8px;
                margin-bottom: 12px;

                border: 1px solid transparent;
                transition: all 0.2s ease-in-out;
            }

            &--active {
                @media (--screen-to-sml) {
                    border: 1px solid $color-primary-10;
                    transition: all 0.2s ease-in-out;
                }
            }
        }
    }

    &__body {
        &--base {
            max-height: 600px;
            overflow-y: scroll;

            @media (--screen-to-huge) {
                overflow-y: visible;
            }
        }
    }

    &__cell {
        color: #2a498a;
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        line-height: 17px;
        /* max-width: 110px; */
        max-height: calc($unit * 9);
        overflow: hidden;
        text-overflow: ellipsis;

        &--lrg {
            max-width: 180px;

            @media (--screen-from-xlrg) {
                max-width: 220px;
            }

            @media (--screen-from-xhuge) {
                max-width: 320px;
            }
        }

        &--med {
            max-width: 130px;

            @media (--screen-from-xhuge) {
                max-width: 230px;
            }
        }

        &--base {
            display: unset;
            max-width: 130px;
            -webkit-line-clamp: 1;
            height: 17px;
        }

        @media (--screen-to-sml) {
            min-width: 100px;
            max-width: 110px;
        }

        &--border {
            border: 1px solid transparent;
        }

        &--mobile {
            @media (--screen-to-sml) {
                position: relative;

                display: flex;
                align-items: center;

                padding-left: 50%;
                min-height: 32px;
                width: 100%;

                border: none;
                border-bottom: 1px solid $color-lblue-6;

                padding-top: $unit;
                padding-bottom: $unit;

                &:last-of-type {
                    border-bottom: none;
                }

                &:before {
                    position: absolute;
                    left: 0px;
                    width: 45%;
                    padding-right: 10px;
                    white-space: nowrap;

                    font-weight: $type-weight-medium;
                    color: $color-dblue-10;
                }

                input {
                    text-overflow: ellipsis;
                }

                &:nth-of-type(1):before {
                    content: "Select store";
                }
                &:nth-of-type(2):before {
                    content: "Store";
                }
                &:nth-of-type(3):before {
                    content: "Status";
                }
                &:nth-of-type(4):before {
                    content: "Price";
                }
                &:nth-of-type(5):before {
                    content: "Coupon";
                }
                &:nth-of-type(6):before {
                    content: "Instructions";
                }

                &.product-name:before {
                    content: "Product name" !important;
                }

                &.upc:before {
                    content: "UPC" !important;
                }

                &.model-number:before {
                    content: "Model number" !important;
                }

                &.available-for-shipment:before {
                    content: "Available for shipment" !important;
                }

                &.shipping-qty:before {
                    content: "Shipping QTY" !important;
                }

                &.shippingReqItemName:before {
                    content: "Product" !important;
                }

                &.shippingReqUPC:before {
                    content: "UPC" !important;
                }

                &.shippingReqModelNumber:before {
                    content: "Model number" !important;
                }

                &.shippingReqAvailability:before {
                    content: "Available" !important;
                }

                &.shippingReqQuantity:before {
                    content: "QTY to ship" !important;
                }
            }
        }
    }

    @media (--screen-to-huge) {
        max-width: none;
    }

    &--secondary {
        min-width: unset;
        max-width: unset;
        padding: 0px;
        background-color: $white;

        box-shadow: unset;

        & .c-table__header {
            padding: 0px;
        }
    }

    &--full {
        min-width: unset;
        max-width: unset;

        &--overflow {
            @media (--screen-to-huge) {
                overflow-x: auto;
            }
        }
    }
}

@media (--screen-to-sml) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
        display: block;
    }
}
