/*------------------------------------*\
    # component.form
\*------------------------------------*/

.c-form {
    &--white {
        background-color: $white;
    }

    &--sml {
    }

    &--base {
        padding: 16px;
        border-radius: 12px;
        width: 100%;
        height: fit-content;

        @media (--screen-from-lrg) {
            max-width: 500px;
        }
    }

    &--lrg {
    }

    &__additional {
        display: flex;
        align-items: center;

        font-size: $type-sml;
        font-weight: $type-weight-medium;
        color: $color-error;

        /* i {
            filter: brightness(0) saturate(100%) invert(31%) sepia(82%) saturate(2301%) hue-rotate(216deg) brightness(100%) contrast(103%);
        } */
    }

    &__container {
        flex: 1;
        padding: calc($unit * 4);
        background-color: white;
        border-radius: calc($unit * 3);
        border: 1px solid $color-lblue-8;

        &--base {
            width: 450px;
        }

        &--med {
            width: 600px;
        }

        &--sml {
            flex: unset;
            width: 300px;
        }
    }

    &__wrapper {
        width: 100%;
    }

    &__field {
        display: flex;
        flex-direction: column;

        position: relative;
        margin-bottom: calc($unit * 5);

        &__label {
            display: inline-block;
            width: 100%;

            font-size: $type-sml;
            line-height: 20px;
            margin-bottom: calc($unit * 2);

            &--info {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        &--med {
            width: 100%;

            @media (--screen-from-med) {
                max-width: calc($unit * 95);
            }
        }
    }

    &__footer {
        margin-top: calc($unit * 5);

        &__list {
            display: flex;
            flex-wrap: wrap;

            margin-bottom: calc($unit * 3);

            @media (--screen-from-sml) {
                margin-bottom: $unit;
            }
        }
    }

    &__group {
        padding: 0px 2px;
    }

    &__fieldset {
        margin: 0px;
        padding: 0px;
        border: 0px solid transparent;

        &--row {
            display: flex;

            align-items: flex-start;

            &--base {
                gap: calc($unit * 5);
            }

            &--lrg {
                gap: calc($unit * 10);
            }
        }
    }
}
