.c-ag {
    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        min-height: calc($unit * 14);

        padding: calc($unit * 5);
        background-color: white;

        border-top: 1px solid color($color-lblue-8 a(0.7)) !important;

        border-radius: 0px 0px 10px 10px !important;

        &--sml {
            min-height: unset;

            padding: $unit calc($unit * 2);

            .c-input {
                border-radius: calc($unit * 1.5);
            }

            .c-input--select {
                height: unset;
                padding: calc($unit * 3) calc($unit * 2);
                background-position: 100% 45%;
            }

            .c-input--select__text {
                height: unset;
                top: 4px;
                padding: 0px calc($unit / 2);
            }

            .c-ag__pager__entries {
                padding: calc($unit * 2);
            }

            .c-input--sml {
                min-width: 72px !important;
            }
        }
    }

    &__pager {
        display: flex;
        align-items: center;
        gap: 20px;

        &__pages {
            display: flex;
            gap: 6px;
        }

        &__entries {
            display: flex;
            gap: 6px;
            /* background-color: $color-lblue-4; */
            padding: 12px 8px;
            border-radius: 8px;

            color: color($color-dblue-6 a(0.9));

            /* width: 200px; */

            font-weight: $type-weight-regular !important;

            &--current {
                color: $color-dblue-9;
                font-weight: $type-weight-semibold !important;
            }
        }

        &__wrapper {
            display: flex;
            gap: 12px;
            align-items: center;

            @media (--screen-to-sml) {
                flex-direction: column;
                align-items: flex-start;
                gap: 0px;
            }
        }
    }

    &__text {
        &--sml {
            max-width: 150px;
        }
    }
}
