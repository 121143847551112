/*------------------------------------*\
    # util.paddings
\*------------------------------------*/

/** 
* you can use custom multiplyer for huge spacing
* $custom: 15;

* You can use spacers by axis 
---------------------------------
--   x-axis ->  left & right   --
--   y-axis ->  top & bottom   --  
---------------------------------
*/

.u {
    /* Padding top */
    &-pt {
        &--sml {
            &--0 {
                padding-top: 0;
            }
            &--1 {
                padding-top: $unit;
            }
            &--2 {
                padding-top: calc($unit * 2);
            }
            &--3 {
                padding-top: calc($unit * 3);
            }
            &--4 {
                padding-top: calc($unit * 4);
            }
            &--5 {
                padding-top: calc($unit * 5);
            }
        }
        
        &--med {
            @media (--screen-from-sml) {
                &--0 {
                    padding-top: 0;
                }
                &--1 {
                    padding-top: $unit;
                }
                &--2 {
                    padding-top: calc($unit * 2);
                }
                &--3 {
                    padding-top: calc($unit * 3);
                }
                &--4 {
                    padding-top: calc($unit * 4);
                }
                &--5 {
                    padding-top: calc($unit * 5);
                }
            }
        }

        &--lrg {
            @media (--screen-from-med) {
                &--0 {
                    padding-top: 0;
                }
                &--1 {
                    padding-top: $unit;
                }
                &--2 {
                    padding-top: calc($unit * 2);
                }
                &--3 {
                    padding-top: calc($unit * 3);
                }
                &--4 {
                    padding-top: calc($unit * 4);
                }
                &--5 {
                    padding-top: calc($unit * 5);
                }
            }
        }

        &--xlrg {
            @media (--screen-from-lrg) {
                &--0 {
                    padding-top: 0;
                }
                &--1 {
                    padding-top: $unit;
                }
                &--2 {
                    padding-top: calc($unit * 2);
                }
                &--3 {
                    padding-top: calc($unit * 3);
                }
                &--4 {
                    padding-top: calc($unit * 4);
                }
                &--5 {
                    padding-top: calc($unit * 5);
                }
            }
        }

        &--xxlrg {
            @media (--screen-from-xlrg) {
                &--0 {
                    padding-top: 0;
                }
                &--1 {
                    padding-top: $unit;
                }
                &--2 {
                    padding-top: calc($unit * 2);
                }
                &--3 {
                    padding-top: calc($unit * 3);
                }
                &--4 {
                    padding-top: calc($unit * 4);
                }
                &--5 {
                    padding-top: calc($unit * 5);
                }
            }
        }

        &--huge {
            @media (--screen-from-xxlrg) {
                &--0 {
                    padding-top: 0;
                }
                &--1 {
                    padding-top: $unit;
                }
                &--2 {
                    padding-top: calc($unit * 2);
                }
                &--3 {
                    padding-top: calc($unit * 3);
                }
                &--4 {
                    padding-top: calc($unit * 4);
                }
                &--5 {
                    padding-top: calc($unit * 5);
                }
            }
        }
    }

    /* Padding right */
    &-pr {
        &--sml {
            &--0 {
                padding-right: 0;
            }
            &--1 {
                padding-right: $unit;
            }
            &--2 {
                padding-right: calc($unit * 2);
            }
            &--3 {
                padding-right: calc($unit * 3);
            }
            &--4 {
                padding-right: calc($unit * 4);
            }
            &--5 {
                padding-right: calc($unit * 5);
            }
        }
        
        &--med {
            @media (--screen-from-sml) {
                &--0 {
                    padding-right: 0;
                }
                &--1 {
                    padding-right: $unit;
                }
                &--2 {
                    padding-right: calc($unit * 2);
                }
                &--3 {
                    padding-right: calc($unit * 3);
                }
                &--4 {
                    padding-right: calc($unit * 4);
                }
                &--5 {
                    padding-right: calc($unit * 5);
                }
            }
        }

        &--lrg {
            @media (--screen-from-med) {
                &--0 {
                    padding-right: 0;
                }
                &--1 {
                    padding-right: $unit;
                }
                &--2 {
                    padding-right: calc($unit * 2);
                }
                &--3 {
                    padding-right: calc($unit * 3);
                }
                &--4 {
                    padding-right: calc($unit * 4);
                }
                &--5 {
                    padding-right: calc($unit * 5);
                }
            }
        }

        &--xlrg {
            @media (--screen-from-lrg) {
                &--0 {
                    padding-right: 0;
                }
                &--1 {
                    padding-right: $unit;
                }
                &--2 {
                    padding-right: calc($unit * 2);
                }
                &--3 {
                    padding-right: calc($unit * 3);
                }
                &--4 {
                    padding-right: calc($unit * 4);
                }
                &--5 {
                    padding-right: calc($unit * 5);
                }
            }
        }

        &--xxlrg {
            @media (--screen-from-xlrg) {
                &--0 {
                    padding-right: 0;
                }
                &--1 {
                    padding-right: $unit;
                }
                &--2 {
                    padding-right: calc($unit * 2);
                }
                &--3 {
                    padding-right: calc($unit * 3);
                }
                &--4 {
                    padding-right: calc($unit * 4);
                }
                &--5 {
                    padding-right: calc($unit * 5);
                }
            }
        }

        &--huge {
            @media (--screen-from-xxlrg) {
                &--0 {
                    padding-right: 0;
                }
                &--1 {
                    padding-right: $unit;
                }
                &--2 {
                    padding-right: calc($unit * 2);
                }
                &--3 {
                    padding-right: calc($unit * 3);
                }
                &--4 {
                    padding-right: calc($unit * 4);
                }
                &--5 {
                    padding-right: calc($unit * 5);
                }
            }
        }
    }

    /* Padding bottom */
    &-pb {
        &--sml {
            &--0 {
                padding-bottom: 0;
            }
            &--1 {
                padding-bottom: $unit;
            }
            &--2 {
                padding-bottom: calc($unit * 2);
            }
            &--3 {
                padding-bottom: calc($unit * 3);
            }
            &--4 {
                padding-bottom: calc($unit * 4);
            }
            &--5 {
                padding-bottom: calc($unit * 5);
            }
        }
        
        &--med {
            @media (--screen-from-sml) {
                &--0 {
                    padding-bottom: 0;
                }
                &--1 {
                    padding-bottom: $unit;
                }
                &--2 {
                    padding-bottom: calc($unit * 2);
                }
                &--3 {
                    padding-bottom: calc($unit * 3);
                }
                &--4 {
                    padding-bottom: calc($unit * 4);
                }
                &--5 {
                    padding-bottom: calc($unit * 5);
                }
            }
        }

        &--lrg {
            @media (--screen-from-med) {
                &--0 {
                    padding-bottom: 0;
                }
                &--1 {
                    padding-bottom: $unit;
                }
                &--2 {
                    padding-bottom: calc($unit * 2);
                }
                &--3 {
                    padding-bottom: calc($unit * 3);
                }
                &--4 {
                    padding-bottom: calc($unit * 4);
                }
                &--5 {
                    padding-bottom: calc($unit * 5);
                }
            }
        }

        &--xlrg {
            @media (--screen-from-lrg) {
                &--0 {
                    padding-bottom: 0;
                }
                &--1 {
                    padding-bottom: $unit;
                }
                &--2 {
                    padding-bottom: calc($unit * 2);
                }
                &--3 {
                    padding-bottom: calc($unit * 3);
                }
                &--4 {
                    padding-bottom: calc($unit * 4);
                }
                &--5 {
                    padding-bottom: calc($unit * 5);
                }
            }
        }

        &--xxlrg {
            @media (--screen-from-xlrg) {
                &--0 {
                    padding-bottom: 0;
                }
                &--1 {
                    padding-bottom: $unit;
                }
                &--2 {
                    padding-bottom: calc($unit * 2);
                }
                &--3 {
                    padding-bottom: calc($unit * 3);
                }
                &--4 {
                    padding-bottom: calc($unit * 4);
                }
                &--5 {
                    padding-bottom: calc($unit * 5);
                }
            }
        }

        &--huge {
            @media (--screen-from-xxlrg) {
                &--0 {
                    padding-bottom: 0;
                }
                &--1 {
                    padding-bottom: $unit;
                }
                &--2 {
                    padding-bottom: calc($unit * 2);
                }
                &--3 {
                    padding-bottom: calc($unit * 3);
                }
                &--4 {
                    padding-bottom: calc($unit * 4);
                }
                &--5 {
                    padding-bottom: calc($unit * 5);
                }
            }
        }
    }
    
    /* Padding left */
    &-pl {
        &--sml {
            &--0 {
                padding-left: 0;
            }
            &--1 {
                padding-left: $unit;
            }
            &--2 {
                padding-left: calc($unit * 2);
            }
            &--3 {
                padding-left: calc($unit * 3);
            }
            &--4 {
                padding-left: calc($unit * 4);
            }
            &--5 {
                padding-left: calc($unit * 5);
            }
        }
        
        &--med {
            @media (--screen-from-sml) {
                &--0 {
                    padding-left: 0;
                }
                &--1 {
                    padding-left: $unit;
                }
                &--2 {
                    padding-left: calc($unit * 2);
                }
                &--3 {
                    padding-left: calc($unit * 3);
                }
                &--4 {
                    padding-left: calc($unit * 4);
                }
                &--5 {
                    padding-left: calc($unit * 5);
                }
            }
        }

        &--lrg {
            @media (--screen-from-med) {
                &--0 {
                    padding-left: 0;
                }
                &--1 {
                    padding-left: $unit;
                }
                &--2 {
                    padding-left: calc($unit * 2);
                }
                &--3 {
                    padding-left: calc($unit * 3);
                }
                &--4 {
                    padding-left: calc($unit * 4);
                }
                &--5 {
                    padding-left: calc($unit * 5);
                }
            }
        }

        &--xlrg {
            @media (--screen-from-lrg) {
                &--0 {
                    padding-left: 0;
                }
                &--1 {
                    padding-left: $unit;
                }
                &--2 {
                    padding-left: calc($unit * 2);
                }
                &--3 {
                    padding-left: calc($unit * 3);
                }
                &--4 {
                    padding-left: calc($unit * 4);
                }
                &--5 {
                    padding-left: calc($unit * 5);
                }
            }
        }

        &--xxlrg {
            @media (--screen-from-xlrg) {
                &--0 {
                    padding-left: 0;
                }
                &--1 {
                    padding-left: $unit;
                }
                &--2 {
                    padding-left: calc($unit * 2);
                }
                &--3 {
                    padding-left: calc($unit * 3);
                }
                &--4 {
                    padding-left: calc($unit * 4);
                }
                &--5 {
                    padding-left: calc($unit * 5);
                }
            }
        }

        &--huge {
            @media (--screen-from-xxlrg) {
                &--0 {
                    padding-left: 0;
                }
                &--1 {
                    padding-left: $unit;
                }
                &--2 {
                    padding-left: calc($unit * 2);
                }
                &--3 {
                    padding-left: calc($unit * 3);
                }
                &--4 {
                    padding-left: calc($unit * 4);
                }
                &--5 {
                    padding-left: calc($unit * 5);
                }
            }
        }
    }
}