/*------------------------------------*\
    # component.btn
\*------------------------------------*/

.c-btn {
    /**
    * Button base
    */
    /* To keep the button inline, and flex container at the same time */
    display: inline-flex;
    align-items: center;
    align-self: center;
    justify-content: center;

    touch-action: manipulation;
    user-select: none;

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;

    &:focus {
        outline: 0;
    }

    /**
    * Button base project styling
    */
    /* Min-width commented out for better display. Bring it back if it caused any problems */
    /* min-width: 70px; */
    font-family: $ff-sans;
    font-weight: $type-weight-semibold;
    border-radius: $radius-base;
    transition: all 300ms $transition;

    /**
    * Sizes
    */

    &--xsml {
        padding: 8px 12px;
        font-size: $type-tny;
        border-radius: 6px;

        &::after {
            width: 12px;
            height: 12px;
        }
    }

    &--sml {
        /* min-height: 40px; */
        padding: 8px 12px;
        font-size: $type-xsml;
        border-radius: 6px;
        font-weight: $type-weight-medium;

        &::after {
            width: 12px;
            height: 12px;
        }
    }

    &--med {
        padding: 12px 16px;
        font-size: $type-sml;
    }

    &--base {
        min-height: 42px;
        padding: 12px;
        font-size: $type-sml;
    }

    &--lrg {
        min-height: 44px;
        padding: 16px 20px;
        font-size: $type-sml;
    }

    &--full {
        width: 100%;
        flex: 1;
    }

    /**
    * Styles
    */
    &--primary {
        background: $color-primary-10;
        color: $color-text-light;
        border: 1px solid transparent;

        &:hover {
            background: #1757fb;
            color: $color-text-light;
        }

        &--action {
            background: $color-success-secondary;
            color: $color-success-light;

            &:hover {
                background: $status-full;
            }
        }

        &.c-btn--icon-only {
            &::before {
                filter: brightness(0) invert(1);
            }
        }
    }

    &--secondary {
        background: $color-lblue-10;
        color: $color-primary-10;
        border: 1px solid color($color-primary-10 a(0.1));
        font-weight: $type-weight-semibold;

        &:hover {
            background: $color-lblue-12;
            color: $color-primary-10;
        }

        &:focus {
        }
    }

    &--accent {
        background: $gradient-primary;
        color: $color-text-light;
        border: 1px solid transparent;
        box-shadow: 0px 5px 15px rgba(9, 25, 65, 0.08);

        background-size: 300% 100%;

        moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: $color-text-light;
            /* background: $gradient-primary-hover; */

            background-position: 100% 0;

            moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }

        &:focus {
        }
    }

    &--outline {
        background: $white;
        border: 1px solid color($color-primary-8 a(0.4));
        color: $color-primary-10;

        font-weight: $type-weight-semibold;
        transition: all 0.2s ease-in-out;

        &:hover {
            border: 1px solid color($color-primary-8 a(0.8));
            color: $color-primary-12;
            transition: all 0.2s ease-in-out;
        }

        &.transparent {
            background: none;
            border: 1px solid transparent;
            color: $white;

            &:hover {
                border: 1px solid transparent;
                color: $white;
            }
        }
    }

    &--delete {
        background: $white;
        border: 1px solid color($color-error a(0.4));
        color: $color-error;

        font-weight: $type-weight-semibold;
        transition: all 0.2s ease-in-out;

        &:hover {
            border: 1px solid color($color-error a(0.8));
            color: $color-error-dark;
            background: $white;
            transition: all 0.2s ease-in-out;
        }
    }

    &--tertiary {
        padding: 0px;
        min-height: 0px !important;

        color: #485f8d;
        border: 0px;

        background-color: transparent;

        &:hover {
            color: $color-primary-10;
        }

        &:focus {
        }

        &::after {
            content: svg-load("../postcss-svg/default-icons/arrow-bg.svg");

            width: 16px;
            height: 16px;

            margin-left: calc($unit * 1.5);
        }
    }

    &--icon {
        max-height: 42px;
        &:hover {
            &::after {
                margin-left: calc($unit * 3);

                transition: all 0.2s ease-in-out;
            }
        }

        &--default {
            &::after {
                content: "";

                background-image: svg-load("../postcss-svg/default-icons/arrow.svg", stroke: $white);

                background-position: center;
                background-size: calc($unit * 4);
                background-repeat: no-repeat;

                width: calc($unit * 4);
                height: calc($unit * 4);

                margin-left: calc($unit * 1.5);
                margin-bottom: calc($unit / 2);

                transition: all 0.2s ease-in-out;
            }
        }

        &--new {
            &::after {
                content: "";

                background-image: svg-load("../postcss-svg/default-icons/plus.svg", stroke: $white);

                top: 1;

                background-position: center;
                background-size: calc($unit * 4);
                background-repeat: no-repeat;

                width: calc($unit * 4);
                height: calc($unit * 4);

                margin-left: calc($unit * 1.5);

                transition: all 0.2s ease-in-out;
            }
        }

        &--view {
            &::before {
                content: "";
                margin-right: calc($unit * 1.5);

                background-image: svg-load("../postcss-svg/actions/view.svg", stroke: $color-primary-10 !important);
                background-position: center;
                background-size: calc($unit * 3.5);
                background-repeat: no-repeat;

                width: calc($unit * 3.5);
                height: calc($unit * 3.5);

                top: 1;
            }
        }

        &--edit {
            &::before {
                content: "";
                margin-right: calc($unit * 1.5);

                background-image: svg-load("../postcss-svg/actions/edit.svg", stroke: $color-primary-10 !important);
                background-position: center;
                background-size: calc($unit * 3.5);
                background-repeat: no-repeat;

                width: calc($unit * 3.5);
                height: calc($unit * 3.5);

                top: 1;
            }
        }

        &--delete {
            &::before {
                content: "";
                margin-right: calc($unit * 1.5);

                background-image: svg-load("../postcss-svg/actions/delete.svg");
                background-position: center;
                background-size: calc($unit * 3.5);
                background-repeat: no-repeat;

                width: calc($unit * 3.5);
                height: calc($unit * 3.5);

                top: 1;
            }
        }

        &--assign {
            &::before {
                content: "";
                margin-right: calc($unit * 1.5);

                background-image: svg-load("../postcss-svg/actions/assign.svg", stroke: $color-primary-10 !important);
                background-position: center;
                background-size: calc($unit * 3.5);
                background-repeat: no-repeat;

                width: calc($unit * 3.5);
                height: calc($unit * 3.5);

                top: 1;
            }
        }

        &--create {
            &::before {
                content: "";
                margin-right: calc($unit * 1.5);

                background-image: svg-load("../postcss-svg/actions/create.svg", stroke: $color-primary-10 !important);
                background-position: center;
                background-size: calc($unit * 3.5);
                background-repeat: no-repeat;

                width: calc($unit * 3.5);
                height: calc($unit * 3.5);

                top: 1;
            }
        }

        &--change {
            &::before {
                content: "";
                margin-right: calc($unit * 1.5);

                background-image: svg-load("../postcss-svg/actions/change.svg", stroke: $color-primary-10 !important);
                background-position: center;
                background-size: calc($unit * 3.5);
                background-repeat: no-repeat;

                width: calc($unit * 3.5);
                height: calc($unit * 3.5);

                top: 1;
            }
        }

        &--complete {
            &::before {
                content: "";
                margin-right: calc($unit * 1.5);

                background-image: svg-load("../postcss-svg/actions/complete.svg", stroke: $color-primary-10 !important);
                background-position: center;
                background-size: calc($unit * 3.5);
                background-repeat: no-repeat;

                width: calc($unit * 3.5);
                height: calc($unit * 3.5);

                top: 1;
            }
        }

        &--download {
            &::before {
                content: "";
                margin-right: calc($unit * 1.5);

                background-image: svg-load("../postcss-svg/actions/download.svg", stroke: $color-primary-10);
                background-position: center;
                background-size: calc($unit * 3.5);
                background-repeat: no-repeat;

                width: calc($unit * 3.5);
                height: calc($unit * 3.5);

                top: 1;
            }
        }

        &--details {
            &::before {
                content: "";
                margin-right: calc($unit * 1.5);

                background-image: svg-load("../postcss-svg/actions/details.svg", stroke: $color-primary-10 !important);
                background-position: center;
                background-size: calc($unit * 3.5);
                background-repeat: no-repeat;

                width: calc($unit * 3.5);
                height: calc($unit * 3.5);

                top: 1;
            }
        }

        &--more {
            &::before {
                content: "";

                background-image: svg-load("../postcss-svg/actions/more.svg", stroke: $color-primary-10 !important);
                background-position: center;
                background-repeat: no-repeat;

                width: calc($unit * 3.5);
                height: calc($unit * 3.5);

                top: 1;
            }
        }

        &--deactivate {
            &::before {
                content: "";
                margin-right: calc($unit * 1.5);

                background-image: svg-load("../postcss-svg/actions/deactivate.svg");

                background-position: center;
                background-size: calc($unit * 3.5);
                background-repeat: no-repeat;

                width: calc($unit * 3.5);
                height: calc($unit * 3.5);

                top: 1;
            }
        }

        &--deactivate-icon {
            &::before {
                content: "";
                margin-right: calc($unit * 1.5);

                background-image: svg-load("../postcss-svg/actions/deactivate-icon.svg");

                background-position: center;
                background-size: calc($unit * 3);
                background-repeat: no-repeat;

                width: calc($unit * 3);
                height: calc($unit * 3);

                top: 1;
            }
        }

        &--import {
            &::before {
                content: "";
                margin-right: calc($unit * 1.5);

                background-image: svg-load("../postcss-svg/actions/upload.svg");

                background-position: center;
                background-size: calc($unit * 3.5);
                background-repeat: no-repeat;

                width: calc($unit * 3.5);
                height: calc($unit * 3.5);

                top: 1;
            }
        }
    }

    &--icon-only {
        min-width: 0px;

        padding: calc($unit * 2) calc($unit * 2.5);

        &::after {
            width: 16px;
            height: 16px;

            margin: 0px;

            transition: all 0.2s ease-in-out;
        }

        &:hover {
            &::after {
                transition: all 0.2s ease-in-out;
            }
        }
    }

    &--group {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    &--basic {
        border: 0px;
        background-color: transparent;
        padding: 0px;
        color: $color-dblue-12;
        font-weight: $type-weight-semibold;
        cursor: pointer;
        border-bottom: 1px solid transparent;
        border-radius: 0px;

        &:hover {
            border-bottom: 1px solid $color-dblue-12;
            transition: all 0.2s ease-in-out;
        }

        &--white {
            color: color($white a(0.8));
            border-bottom: 1px solid color($white a(0.8));
            font-size: $type-sml;
            font-weight: $type-weight-medium;

            &:hover {
                border-bottom: 1px solid $white;
                color: $white;
                transition: all 0.2s ease-in-out;
            }

            &--med {
                font-weight: $type-weight-regular;
            }
        }

        &--secondary {
            font-weight: $type-weight-medium;
            color: $color-primary-10;

            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            border-radius: 0px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            /* white-space: break-spaces; */

            text-align: left;
            max-height: 36px;

            border-bottom: 1px solid transparent;

            &--lrg {
                width: fit-content;
                max-width: 400px;
            }

            &--base {
                max-width: 200px;
            }

            &--sml {
                max-width: 160px;
                display: -webkit-box;
            }

            &:hover {
                color: $color-primary-10;
                border-bottom: 1px solid $color-primary-10;
                transition: all 0.2s ease-in-out;
            }
        }

        &--base {
            height: 40px;
            display: flex;
            align-items: center;
        }

        &--tertiary,
        &--quaternary {
            font-weight: $type-weight-semibold;
            color: $color-dblue-7;

            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            border-radius: 0px;

            &:hover {
                color: $color-dblue-12;
                border-bottom: 1px solid $color-dblue-12;
                transition: all 0.2s ease-in-out;
            }
        }

        &--quaternary {
            display: block;
        }

        &--underline {
            border-bottom: 1px solid $color-primary-10;
        }
    }

    &--disabled {
        background: #fefefe;
        color: #8c95a8;
        border: 1px solid #b7c6e5;
        font-weight: $type-weight-semibold;

        cursor: auto;

        &:hover {
            background: #fefefe;
            color: #8c95a8;
            border: 1px solid #b7c6e5;

            &::after {
                margin-left: calc($unit * 1.5);
            }
        }

        &:before {
            filter: brightness(0) invert(1);
            filter: brightness(0) saturate(100%) invert(67%) sepia(10%) saturate(565%) hue-rotate(182deg)
                brightness(88%) contrast(86%);
        }

        &::after {
            filter: brightness(0) saturate(100%) invert(67%) sepia(10%) saturate(565%) hue-rotate(182deg)
                brightness(88%) contrast(86%);
        }
    }

    &--abs {
        top: calc($unit * 2.5);
        right: calc($unit * 3);
        cursor: pointer;

        &--med {
            top: calc($unit * 5.5);
            right: calc($unit * 4);
        }
    }

    &--close {
        position: absolute;
        top: 8px;
        right: 8px;

        width: 28px;
        height: 28px;
        border-radius: 100px;

        i {
            filter: brightness(0) saturate(100%) invert(87%) sepia(0%) saturate(6%) hue-rotate(336deg) brightness(79%)
                contrast(94%);
        }

        &--secondary {
            right: 40px;
            top: unset;

            i {
                width: 10px;
                height: 10px;
            }

            @media (--screen-to-sml) {
                right: 30px;
            }
        }
    }

    &--loading {
        background: #fefefe;
        color: #8c95a8;
        border: 1px solid #b7c6e5;

        transition: all 0.2s ease-in-out;

        &::before {
            content: "";

            margin-top: calc($unit / 4) !important;
            margin-right: calc($unit * 2);

            background-image: none;

            border: calc($unit / 2) solid color($white a(0.3));
            border-radius: 100%;
            border-left-color: $white;
            border-top-color: $white;

            animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
        }

        &.c-btn--icon,
        &.c-btn--sml {
            background: #fefefe;
            color: #8c95a8;
            border: 1px solid #b7c6e5;

            &::before {
                animation: spin 0.6s infinite linear, growSml 0.3s forwards ease-out;
                filter: brightness(0) saturate(100%) invert(67%) sepia(10%) saturate(565%) hue-rotate(182deg)
                    brightness(88%) contrast(86%);
            }
        }
    }

    &--underline {
        border-bottom: 1px solid $color-primary-10;

        display: block;
        border-radius: 0;
        color: $color-primary-10;

        font-weight: 500;
        cursor: pointer;

        &:hover {
            color: $color-dblue-10;
            border-bottom: 1px solid $color-dblue-10;
        }

        &--sml {
            white-space: break-spaces;
        }
    }
}

@keyframes spin {
    to {
        transform: rotate(359deg);
    }
}
@keyframes grow {
    to {
        width: 14px;
        height: 14px;
        margin-top: -8px;
        right: 13px;
    }
}

@keyframes growSml {
    to {
        width: 12px;
        height: 12px;
        margin-top: -8px;
        right: 11px;
    }
}
