.c-menu {
    position: fixed;
    height: 100%;
    width: 250px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: calc($unit * 7);
    padding-top: calc($unit * 4);

    background-color: #002547;
    border-right: 1px solid #0f2f4d;

    letter-spacing: 0.1px;
    z-index: 100;
    transition: all 100ms ease-out;

    &--secondary {
        justify-content: space-between;
        width: 100%;

        transform: translateX(100%);

        padding-top: calc($unit * 10);

        &.active {
            transform: translateX(0%);
        }
    }

    &__btn {
        position: absolute;
        left: 234px;
        bottom: 20px;

        width: calc($unit * 8);
        height: calc($unit * 8);

        background: $color-primary-10;
        color: $color-text-light;
        border: 1px solid transparent;

        border-radius: $radius-full;
        transition: all 100ms ease-out;
        z-index: 5;
    }

    &.isCollapsed {
        width: 60px;
        padding: calc($unit * 3);

        & .c-menu__btn {
            left: 44px;
        }

        & .c-menu__item {
            justify-content: center;
            padding: calc($unit * 3) calc($unit * 2);
        }

        & .u-icon {
            margin: 0;
        }

        & .c-menu__item__label,
        & .c-menu__footer {
            display: none;
        }

        & .c-menu__header {
            text-align: center;
            img {
                height: calc($unit * 5);
            }
        }

        & .c-menu__header--additional {
            text-align: center;
            img {
                height: 11px;
            }
        }

        & .u-icon--arrow-down,
        & .u-icon--arrow-up {
            position: absolute;
            top: calc($unit * 3);
            right: -9px;
        }
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;

        margin-bottom: calc($unit * 1.5);
        padding: calc($unit * 2.7) calc($unit * 3);

        border: 1px solid transparent;
        border-radius: calc($unit * 2);

        cursor: pointer;

        &__label {
            font-size: $type-sml;
            font-family: $ff-sans;
            font-weight: $type-weight-regular;

            color: $color-lblue-12;

            word-break: normal;
        }

        &.active {
            background-color: #06325b;
            border-radius: calc($unit * 2);

            .c-menu__item__label {
                color: $white;
            }

            .u-icon {
                filter: brightness(200%);
            }
        }

        &:hover,
        &:focus {
            background-color: #062d51;

            border: 1px solid transparent;
            border-radius: calc($unit * 2);

            .c-menu__item__label {
                color: $white;
            }

            .u-icon {
                filter: brightness(200%);
            }
        }

        &:focus {
            border: 1px solid #073764;
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding: calc($unit * 5);
        gap: 20px;

        background-color: #032d54;
        border-radius: 12px;

        &__title {
            font-size: $type-base;
            font-weight: $type-weight-semibold;
            color: $white;
        }

        &__content {
            font-size: $type-sml;
            color: $color-lblue-14;
        }
    }

    &__header {
        &__logo {
            background-image: svg-load("../postcss-svg/default-icons/logo.svg");
        }

        &--additional {
            /* svg {
                width: 190px;
                height: 32px;
            } */
        }
    }

    &__list {
        border-left: 1px solid $color-dblue-10;
        margin: 0px calc($unit * 5);

        transition: all 0.2s ease-in-out;

        &__item {
            display: flex;
            align-items: center;

            margin-bottom: calc($unit * 3);
            padding: calc($unit * 1) calc($unit * 3);
            border: 1px solid transparent;

            border-left: 1px solid transparent;

            cursor: pointer;
            transition: all 0.2s ease-in-out;

            span {
                font-weight: $type-weight-regular;
                color: $color-lblue-16;
            }

            &:hover {
                border-left: 1px solid $white;
                transition: all 0.2s ease-in-out;
                span {
                    color: white;
                    transition: all 0.2s ease-in-out;
                }
            }

            &.active {
                border-left: 1px solid $white;
                background-color: transparent;

                span {
                    color: $white;
                }
            }
        }

        &--fixed {
            position: fixed;
            left: 60px;
            /* width: 300px; */

            display: flex;
            flex-direction: column;
            gap: calc($unit * 3);

            padding: calc($unit * 3);

            background-color: #002547;

            & .c-menu__item__label {
                display: block !important;
            }

            & .c-menu__list__item {
                margin-bottom: 0;
            }

            @media (--screen-to-sml) {
                position: unset;
            }
        }
    }

    &--sml {
        display: flex;
        padding: 0;

        position: absolute;
        right: calc($unit * 4);
        top: calc($unit * 4);

        background-color: transparent;
        border: none;
        cursor: pointer;

        z-index: 999;

        transform: scale(0.8);

        &__line {
            fill: none;
            stroke: #5e7c98;
            stroke-width: 6;
            transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

            &--1 {
                stroke-dasharray: 60 207;
                stroke-width: 6;
            }

            &--2 {
                stroke-dasharray: 60 60;
                stroke-width: 6;
            }

            &--3 {
                stroke-dasharray: 60 207;
                stroke-width: 6;
            }
        }

        &.isOpen .c-menu--sml__line {
            stroke: $white;
            padding-top: calc((-1) * 80);

            &--1 {
                stroke-dasharray: 90 207;
                stroke-dashoffset: -134;
                stroke-width: 6;
            }

            &--2 {
                stroke-dasharray: 1 60;
                stroke-dashoffset: -30;
                stroke-width: 6;
            }

            &--3 {
                stroke-dasharray: 90 207;
                stroke-dashoffset: -134;
                stroke-width: 6;
            }
        }
    }
}
