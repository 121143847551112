.c-snackbar {
    &__container {
        display: flex;
        flex-direction: column;
        border-radius: calc($unit * 2);
        padding: calc($unit * 5);
        background-color: #ff9800;

        &--error {
            background-color: $color-error-dark;
            border-radius: calc($unit * 2);
            padding: calc($unit * 5);
        }

        &--info {
            background-color: $color-primary-10 !important;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        flex: 1;
        align-items: center;

        div {
            font-size: $type-base;
            font-weight: $type-weight-medium;
        }

        i {
            filter: brightness(0) saturate(100%) invert(13%) sepia(79%) saturate(721%) hue-rotate(174deg)
                brightness(95%) contrast(96%);
            cursor: pointer;

            &:hover {
                /* transform: rotate(180deg); */
            }
        }
    }

    &__details {
        margin-top: 12px;

        font-size: $type-sml;
        font-weight: $type-weight-regular;
    }

    &--white {
        color: $white;
    }

    &__title {
        font-size: $type-base;
        font-weight: $type-weight-medium;
    }

    &__spinner {
        margin-top: calc($unit / 2);
        border: calc($unit / 2) solid color($white a(0.3));
        border-radius: 100%;
        border-left-color: $white;
        border-top-color: $white;

        animation: spin 0.8s infinite linear, grow 0.3s forwards ease-out;
    }
}

@keyframes spin {
    to {
        transform: rotate(359deg);
    }
}
@keyframes grow {
    to {
        width: 18px;
        height: 18px;
    }
}
