.c-search {
    &__wrapper {
        position: relative;

        & .c-input--search,
        & .c-input__wrapper--light,
        & .c-input__wrapper--light:focus,
        & .c-input__wrapper--light:focus-within {
            border-radius: $unit;
            line-height: 18px;
        }

        & .c-input--search {
            max-width: unset;
            min-width: unset;
            flex: 1;
        }

        & .c-input--search__wrapper {
            width: 100%;
        }

        & .c-input__wrapper {
            min-height: calc($unit * 8);
        }

        &.isError {
            & .c-input--select {
                border: 1px solid #df1a6d;
            }
        }

        & .c-input--select {
            background-color: #f7f9ff;
        }

        &.isLight {
            & .c-input--select {
                background-color: $white;
            }
        }

        &.isOpen {
            & .c-search__content {
                opacity: 1;
                height: auto;
            }
        }
    }

    &__value {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        width: 80%;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        user-select: none;
        -webkit-user-select: none;

        color: $color-dblue-6;

        &.isSelected {
            color: $color-dblue-9;
        }
    }

    &__content {
        position: absolute;
        top: 110%;
        left: 0;

        width: 100%;
        height: 0px;

        padding: calc($unit * 2);

        border: 1px solid $color-lblue-8;
        border-radius: calc($unit * 3);

        background-color: $white;
        box-shadow: 0px 12px 20px rgba(104, 124, 174, 0.2);

        opacity: 0;
        overflow: hidden;
        transition: all 100ms ease-out;

        z-index: 4;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: $unit;

        max-height: 220px;
        width: 100%;

        padding-right: $unit;

        overflow: hidden;
        overflow-y: auto;
    }

    &__option {
        padding: calc($unit * 2);

        font-size: $type-sml;
        font-weight: $type-weight-regular;

        color: $color-dblue-7;
        border-radius: calc($unit * 1.5);
        cursor: pointer;

        transition: all 0.15s ease-in-out;

        &:hover {
            color: $color-primary-8;
            background-color: $color-lblue-4;
        }

        &.isActive {
            color: $color-primary-10;
            background-color: $color-lblue-6;
            border-radius: calc($unit * 1.5);

            transition: all 0.15s ease-in-out;
        }
    }
}
