.c-overview {
    width: calc(100vw - 290px);
    min-height: calc(100vh - 380px);
    margin-top: 32px;
    margin-right: calc((-1) * 32px);
    margin-left: calc((-1) * 32px);

    margin-bottom: -32px;
    padding: 32px;

    border-top: 1px solid #d6e1ff;

    z-index: 4;
    background-color: #e3eafd;
    transition: all 0.1s ease-out;

    @media screen and (max-width:900px) {
        width: calc(100vw - 100px);
    }
}

