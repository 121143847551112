.c-filters {
    display: flex;
    flex: 0 0 60%;
    flex-wrap: wrap;
    /* padding: calc($unit * 1); */
    gap: calc($unit * 2);

    border-radius: calc($unit * 3);

    
    @media(--screen-to-lrg) {
        margin-top: calc($unit * 3);
    }

    @media(--screen-to-sml) {
        flex: 0 0 100%;
    }
    
    /* med */
    /* @media(--screen-to-sml) {
        flex: 1;
    } */


    &--primary {
        background-color: $white;
    }

    &--secondary {
        background-color: $color-lblue-7;
    }

    &__item {
    }

    &__dropdown {
        &__option {
            padding: 4px;
            border-radius: 6px;
            /* &:hover {
                color: $color-primary-8;
                background-color: $color-lblue-4;
                transition: all 0.15s ease-in-out;
            } */
        }
        &__title {
            color: $color-lblue-16;
            font-size: $type-xsml;
            font-weight: $type-weight-bold;
            text-transform: uppercase;
        }

        &__select {
            padding: calc($unit * 3);
            margin-top: calc($unit * 2);
            background-color: $color-lblue-4;
            border-radius: calc($unit * 2.5);
            display: flex;
            flex-direction: column;
            gap: calc($unit * 2);

            &__title {
                font-size: $type-sml;
                font-weight: $type-weight-medium;
                color: $color-dblue-7;
            }
        }
    }

    &--align-end {
        align-items: flex-end;
    }
}
