.rs-slider {
    position: relative;
    width: 95%;

    &-bar {
        height: 6px;
        border-radius: 3px;
        background-color: #f2f2f5;
        -webkit-transition: background-color ease-in-out 0.3s;
        transition: background-color ease-in-out 0.3s;
        cursor: pointer;
    }

    &-progress-bar {
        position: absolute;
        height: 6px;
        border-radius: 3px 0 0 3px;
        background-color: $color-primary-10;
    }

    &-handle {
        position: absolute;
        top: -50%;
        outline: none;

        &::before {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 2px solid $color-primary-10;
            background-color: #fff;
            margin-left: -6px;
            cursor: pointer;
            -webkit-transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out,
                -webkit-transform 0.15s ease-in-out;
            transition: background-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out,
                -webkit-transform 0.15s ease-in-out;
            transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out;
            transition: box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out, transform 0.15s ease-in-out,
                -webkit-box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
        }

        &:active::before,
        &.active::before {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }

        &:hover::before,
        &:focus::before {
            -webkit-box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
            box-shadow: 0 0 0 8px rgba(52, 152, 255, 0.25);
        }
    }

    & .rs-tooltip {
         display: none;
    }
}


.rs-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-size: 12px;
    opacity: 0;
    line-height: 1.66666667;
    max-width: 250px;
    padding: 2px 10px;
    color: #fff;
    color: var(--rs-tooltip-text);
    background-color: #272c36;
    background-color: var(--rs-tooltip-bg);
    border-radius: 4px;
    overflow-wrap: break-word;
}

.rs-tooltip[class*="placement-top"] {
    margin-top: -8px;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}
