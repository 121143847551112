.c-grid {
    display: grid;

    &--align {
        &--start {
            align-items: flex-start;
        }

        &--center {
            align-items: center;
        }

        &--end {
            align-items: flex-end;
        }
    }

    &--gap {
        &--tny {
            gap: $unit;
        }

        &--sml {
            gap: calc($unit * 2);
        }

        &--base {
            gap: calc($unit * 4);
        }

        &--med {
            gap: calc($unit * 6);
        }

        &--lrg {
            gap: calc($unit * 8);
        }
    }

    /* COLUMN  */
    &--sml--1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &--sml--2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));

        &--uneven {
            grid-template-columns: 8fr 1fr;
        }
    }

    &--sml--3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    &--sml--4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    &--sml--5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    &--sml--6 {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    &--sml--7 {
        grid-template-columns: repeat(7, minmax(0, 1fr));
    }

    &--sml--8 {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }

    &--sml--9 {
        grid-template-columns: repeat(9, minmax(0, 1fr));
    }

    &--sml--10 {
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }

    &--sml--11 {
        grid-template-columns: repeat(11, minmax(0, 1fr));
    }

    &--sml--12 {
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    /* COLUMN MED */
    @media (--screen-from-med) {
        &--med--1 {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        &--med--2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &--med--3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &--med--4 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        &--med--5 {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        &--med--6 {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }

        &--med--7 {
            grid-template-columns: repeat(7, minmax(0, 1fr));
        }

        &--med--8 {
            grid-template-columns: repeat(8, minmax(0, 1fr));
        }

        &--med--9 {
            grid-template-columns: repeat(9, minmax(0, 1fr));
        }

        &--med--10 {
            grid-template-columns: repeat(10, minmax(0, 1fr));
        }

        &--med--11 {
            grid-template-columns: repeat(11, minmax(0, 1fr));
        }

        &--med--12 {
            grid-template-columns: repeat(12, minmax(0, 1fr));
        }
    }

    /* COLUMN LRG */
    @media (--screen-from-lrg) {
        &--lrg--1 {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        &--lrg--2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &--lrg--3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &--lrg--4 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        &--lrg--5 {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        &--lrg--6 {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }

        &--lrg--7 {
            grid-template-columns: repeat(7, minmax(0, 1fr));
        }

        &--lrg--8 {
            grid-template-columns: repeat(8, minmax(0, 1fr));
        }

        &--lrg--9 {
            grid-template-columns: repeat(9, minmax(0, 1fr));
        }

        &--lrg--10 {
            grid-template-columns: repeat(10, minmax(0, 1fr));
        }

        &--lrg--11 {
            grid-template-columns: repeat(11, minmax(0, 1fr));
        }

        &--lrg--12 {
            grid-template-columns: repeat(12, minmax(0, 1fr));
        }
    }

    /* COLUMN XLRG */
    @media (--screen-from-xlrg) {
        &--xlrg--1 {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        &--xlrg--2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &--xlrg--3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &--xlrg--4 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        &--xlrg--5 {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        &--xlrg--6 {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }

        &--xlrg--7 {
            grid-template-columns: repeat(7, minmax(0, 1fr));
        }

        &--xlrg--8 {
            grid-template-columns: repeat(8, minmax(0, 1fr));
        }

        &--xlrg--9 {
            grid-template-columns: repeat(9, minmax(0, 1fr));
        }

        &--xlrg--10 {
            grid-template-columns: repeat(10, minmax(0, 1fr));
        }

        &--xlrg--11 {
            grid-template-columns: repeat(11, minmax(0, 1fr));
        }

        &--xlrg--12 {
            grid-template-columns: repeat(12, minmax(0, 1fr));
        }
    }

    /* COLUMN XXLRG */
    @media (--screen-from-xxlrg) {
        &--xxlrg--1 {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        &--xxlrg--2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &--xxlrg--3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &--xxlrg--4 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        &--xxlrg--5 {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        &--xxlrg--6 {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }

        &--xxlrg--7 {
            grid-template-columns: repeat(7, minmax(0, 1fr));
        }

        &--xxlrg--8 {
            grid-template-columns: repeat(8, minmax(0, 1fr));
        }

        &--xxlrg--9 {
            grid-template-columns: repeat(9, minmax(0, 1fr));
        }

        &--xxlrg--10 {
            grid-template-columns: repeat(10, minmax(0, 1fr));
        }

        &--xxlrg--11 {
            grid-template-columns: repeat(11, minmax(0, 1fr));
        }

        &--xxlrg--12 {
            grid-template-columns: repeat(12, minmax(0, 1fr));
        }
    }

    /* COLUMN HUGE */
    @media (--screen-from-huge) {
        &--huge--1 {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        &--huge--2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        &--huge--3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &--huge--4 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        &--huge--5 {
            grid-template-columns: repeat(5, minmax(0, 1fr));
        }

        &--huge--6 {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }

        &--huge--7 {
            grid-template-columns: repeat(7, minmax(0, 1fr));
        }

        &--huge--8 {
            grid-template-columns: repeat(8, minmax(0, 1fr));
        }

        &--huge--9 {
            grid-template-columns: repeat(9, minmax(0, 1fr));
        }

        &--huge--10 {
            grid-template-columns: repeat(10, minmax(0, 1fr));
        }

        &--huge--11 {
            grid-template-columns: repeat(11, minmax(0, 1fr));
        }

        &--huge--12 {
            grid-template-columns: repeat(12, minmax(0, 1fr));
        }
    }

    &--span {
        /* COLUMN SPAN */

        &--1 {
            grid-column: span 1 / span 1;
        }

        &--2 {
            grid-column: span 2 / span 2;
        }

        &--3 {
            grid-column: span 3 / span 3;
        }

        &--4 {
            grid-column: span 4 / span 4;
        }

        &--5 {
            grid-column: span 5 / span 5;
        }

        &--6 {
            grid-column: span 6 / span 6;
        }

        &--7 {
            grid-column: span 7 / span 7;
        }

        &--8 {
            grid-column: span 8 / span 8;
        }

        &--9 {
            grid-column: span 9 / span 9;
        }

        &--10 {
            grid-column: span 10 / span 10;
        }

        &--11 {
            grid-column: span 11 / span 11;
        }

        &--12 {
            grid-column: span 12 / span 12;
        }

        /* COLUMN LRG */
        @media (--screen-from-lrg) {
            &--lrg--1 {
                grid-column: span 1 / span 1;
            }

            &--lrg--2 {
                grid-column: span 2 / span 2;
            }

            &--lrg--3 {
                grid-column: span 3 / span 3;
            }

            &--lrg--4 {
                grid-column: span 4 / span 4;
            }

            &--lrg--5 {
                grid-column: span 5 / span 5;
            }

            &--lrg--6 {
                grid-column: span 6 / span 6;
            }

            &--lrg--7 {
                grid-column: span 7 / span 7;
            }

            &--lrg--8 {
                grid-column: span 8 / span 8;
            }

            &--lrg--9 {
                grid-column: span 9 / span 9;
            }

            &--lrg--10 {
                grid-column: span 10 / span 10;
            }

            &--lrg--11 {
                grid-column: span 11 / span 11;
            }

            &--lrg--12 {
                grid-column: span 12 / span 12;
            }
        }

        /* COLUMN XLRG */
        @media (--screen-from-xlrg) {
            &--xlrg--1 {
                grid-column: span 1 / span 1;
            }

            &--xlrg--2 {
                grid-column: span 2 / span 2;
            }

            &--xlrg--3 {
                grid-column: span 3 / span 3;
            }

            &--xlrg--4 {
                grid-column: span 4 / span 4;
            }

            &--xlrg--5 {
                grid-column: span 5 / span 5;
            }

            &--xlrg--6 {
                grid-column: span 6 / span 6;
            }

            &--xlrg--7 {
                grid-column: span 7 / span 7;
            }

            &--xlrg--8 {
                grid-column: span 8 / span 8;
            }

            &--xlrg--9 {
                grid-column: span 9 / span 9;
            }

            &--xlrg--10 {
                grid-column: span 10 / span 10;
            }

            &--xlrg--11 {
                grid-column: span 11 / span 11;
            }

            &--xlrg--12 {
                grid-column: span 12 / span 12;
            }
        }

        /* COLUMN XXLRG */
        @media (--screen-from-xxlrg) {
            &--xxlrg--1 {
                grid-column: span 1 / span 1;
            }

            &--xxlrg--2 {
                grid-column: span 2 / span 2;
            }

            &--xxlrg--3 {
                grid-column: span 3 / span 3;
            }

            &--xxlrg--4 {
                grid-column: span 4 / span 4;
            }

            &--xxlrg--5 {
                grid-column: span 5 / span 5;
            }

            &--xxlrg--6 {
                grid-column: span 6 / span 6;
            }

            &--xxlrg--7 {
                grid-column: span 7 / span 7;
            }

            &--xxlrg--8 {
                grid-column: span 8 / span 8;
            }

            &--xxlrg--9 {
                grid-column: span 9 / span 9;
            }

            &--xxlrg--10 {
                grid-column: span 10 / span 10;
            }

            &--xxlrg--11 {
                grid-column: span 11 / span 11;
            }

            &--xxlrg--12 {
                grid-column: span 12 / span 12;
            }
        }

        /* COLUMN huge */
        @media (--screen-from-huge) {
            &--huge--1 {
                grid-column: span 1 / span 1;
            }

            &--huge--2 {
                grid-column: span 2 / span 2;
            }

            &--huge--3 {
                grid-column: span 3 / span 3;
            }

            &--huge--4 {
                grid-column: span 4 / span 4;
            }

            &--huge--5 {
                grid-column: span 5 / span 5;
            }

            &--huge--6 {
                grid-column: span 6 / span 6;
            }

            &--huge--7 {
                grid-column: span 7 / span 7;
            }

            &--huge--8 {
                grid-column: span 8 / span 8;
            }

            &--huge--9 {
                grid-column: span 9 / span 9;
            }

            &--huge--10 {
                grid-column: span 10 / span 10;
            }

            &--huge--11 {
                grid-column: span 11 / span 11;
            }

            &--huge--12 {
                grid-column: span 12 / span 12;
            }
        }

        /* COLUMN xhuge */
        @media (--screen-from-xhuge) {
            &--xhuge--1 {
                grid-column: span 1 / span 1;
            }

            &--xhuge--2 {
                grid-column: span 2 / span 2;
            }

            &--xhuge--3 {
                grid-column: span 3 / span 3;
            }

            &--xhuge--4 {
                grid-column: span 4 / span 4;
            }

            &--xhuge--5 {
                grid-column: span 5 / span 5;
            }

            &--xhuge--6 {
                grid-column: span 6 / span 6;
            }

            &--xhuge--7 {
                grid-column: span 7 / span 7;
            }

            &--xhuge--8 {
                grid-column: span 8 / span 8;
            }

            &--xhuge--9 {
                grid-column: span 9 / span 9;
            }

            &--xhuge--10 {
                grid-column: span 10 / span 10;
            }

            &--xhuge--11 {
                grid-column: span 11 / span 11;
            }

            &--xhuge--12 {
                grid-column: span 12 / span 12;
            }
        }
    }
}
