/*------------------------------------*\
    # base.globals
\*------------------------------------*/

/**
 * Initialize typomatic in project (sets font-size and line-height
 * on html selector.
 */

*,
*:before,
*:after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    height: 100%;
    font-size: responsive;
    scrollbar-width: thin;
}


div:has(.c-btn--icon) {
    line-height: 0px;
}

body {
    min-height: 100%;
    margin: 0;
    background: $body-background !important;

    font-family: $ff-sans;
    color: #2c2f31;

    overflow-x: hidden;
}

img {
    vertical-align: middle;
}

svg {
    max-width: 100%;
}

div {
    font-size: $type-sml;
    line-height: calc($unit * 4.5);
}

.proba {
    position: absolute;
    top: 40;
    left: 10;

}
/*------------------------------------*\
    # c.loader
\*------------------------------------*/

$loader-style: double;
$loader-size: 70px;
$loader-speed: 1000ms;


.fix {
    width: 63%;
    padding: 16px;
    border-radius: 12px;
}

.boa-dash-fix .c-cards__title{
    width: 200px;
}

    input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: transparent !important;
    color: fieldtext !important;
}

.base-height {
    max-height: 200px;
    overflow-y: auto;
}

.med-height {
    max-height: 400px;
    overflow-y: auto;
}

.lrg-height {
    max-height: 600px;
    overflow-y: auto;
}

.fix-blur {
    background-color: #fff;
    padding: 8px;
}

.notistack-SnackbarContainer {
    max-width: 520px !important;
}