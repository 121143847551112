/*------------------------------------*\
    # component.input
\*------------------------------------*/

.c-input {
    /**
     * Input base
     */
    display: inline-flex;
    width: 100%;

    vertical-align: middle;
    cursor: auto;
    touch-action: manipulation;
    appearance: none;
    -webkit-appearance: none;

    /**
     * Input base project styling
     */
    color: $color-dblue-12;
    font-family: $ff-sans;
    border: 1px solid $color-lblue-12;
    background-color: $color-lblue-3;
    border-radius: $radius-base;
    transition: all 250ms $transition;
    caret-color: $system-primary;

    scrollbar-width: thin;

    &::placeholder {
        color: $color-dblue-6;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $color-lblue-3 inset !important;
        border: 1px solid $color-lblue-12;
    }

    &:invalid {
        border: 1px solid #df1a6d;
    }

    &:focus {
        outline: 0;
        border-radius: 6px;
        box-shadow: 0 0 0 2px $color-secondary;
    }

    &[disabled] {
        color: color($color-dblue-7 a(0.8)) !important;

        background-color: color($color-lblue-10 a(0.7));
        border-color: $color-lblue-12;
    }

    &.search::-webkit-search-cancel-button {
        &:hover {
            cursor: pointer;
        }
    }

    &.date {
        height: 41px;
    }

    &--outline {
        /**
        * Input base project styling
        */
        color: $color-text-light;
        border: 1px solid $color-text-light;
        background-color: transparent;

        &::placeholder {
            color: color($color-text-light a(0.6));
        }

        &:focus {
            background-color: color($color-text-light a(0.1));
            border: 1px solid #339dff;
        }
    }

    /**
     * Input sizes
     */

    &--xxsml {
        min-width: unset !important;
        font-size: $type-sml;
        padding: calc($unit * 2);
    }
    /* xxsml is for filters only */

    &--xsml {
        width: 90%;
        min-width: unset !important;
        font-size: $type-sml;
        padding: calc($unit * 3) calc($unit * 3);
    }
    /* xsml is for table dropdowns only */

    &--sml {
        font-size: $type-sml;
        padding: calc($unit * 3) calc($unit * 3);
        min-width: 104px !important;
    }

    &--base {
        font-size: $type-sml;
        padding: calc($unit * 3) calc($unit * 3);
    }

    &--med {
        font-size: $type-sml;
        padding: calc($unit * 4) calc($unit * 4);
    }

    &--lrg {
        font-size: $type-base;
        height: calc($unit * 11.5) !important;
        line-height: calc($unit * 12);
        padding: calc($unit * 4) calc($unit * 4);

        & .c-input--select__text {
            height: calc($unit * 11.5) !important;
        }
    }

    &--base-len {
        max-width: 220px;
    }

    &--full {
        flex: 1;

        font-size: $type-sml;
        padding: calc($unit * 3) calc($unit * 3);

        min-width: unset !important;
        max-width: unset !important;

        &--sml {
            min-width: 400px !important;
            padding: 0px;
        }
    }

    &--trim {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        line-height: 17px;
        max-width: 110px;
        max-height: calc($unit * 9);
        overflow: hidden;
        text-overflow: ellipsis;
    }

    /**
     * Input types
     */

    &--features {
        border: 0px;
        font-size: $type-sml;
        color: $color-dblue-8;
        font-weight: $type-weight-semibold;

        background-position: center right !important;
        width: 300px;
    }

    &--text {
        width: 100%;
    }

    &--textarea {
        display: block;
        width: 100%;
        max-width: 100%;
        max-height: 100px;

        font-family: $ff-sans;
        font-size: calc($unit * 3);
        line-height: 18px !important;

        padding: calc($unit * 2) calc($unit * 2);

        resize: none;

        &--white {
            background-color: $white;
            border: 1px solid #d6e1ff;
        }
    }

    &--dark {
        background-color: transparent;
        color: $color-text-light;

        border-color: $color-border;

        &::placeholder {
            color: color($color-text-light a(0.8));
        }
    }

    &--numeric {
        display: block;
        height: calc($unit * 8.5);

        padding: calc($unit * 4) calc($unit * 3);

        font-size: $type-sml;
        line-height: calc($unit * 8);

        &--sml {
            width: calc($unit * 17.5);
        }
    }

    &--select,
    &--date,
    &--search,
    &--time,
    &--currency {
        padding-right: calc($unit * 6);

        background-repeat: no-repeat;
        background-size: calc($unit * 4) calc($unit * 4);
        background-position: calc($unit * 4) center;
        vertical-align: middle;
    }

    /* &--date {
        background-image: svg-load(
            './../postcss-svg/default-icons/icon--calendar.svg',
            fill: color($color-text-light a(0.5))
        );
    } */

    &__wrapper,
    &__price-wrapper {
        display: flex;
        padding: calc($unit * 1);
        padding-left: 0px;

        border-radius: calc($unit * 2);
        border: 1px solid #d6e1ff;

        background-color: $white;

        transition: all .15s ease-in-out;

        .c-input--search {
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 30px white inset !important;
                border: 0px;
            }
        }

        &--light {
            border: 0px solid transparent;
            background-color: $color-lblue-4;
            border-radius: 8px;

            .c-input--search {
                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 30px $color-lblue-4 inset !important;
                    border: 0px;
                }
            }
        }

        &:focus-within {
            border-radius: 8px;
            box-shadow: 0 0 0 1.5px #339dff;
            outline: 0;
            transition: all .15s ease-in-out;

            .u-icon--search {
                background-image: svg-load("../postcss-svg/default-icons/icon-search.svg", fill: $color-dblue-9);
            }
        }
    }

    &__wrapper {
        @media (--screen-to-sml) {
            border-radius: calc($unit * 2);
            flex: 1;
            width: 100%;
        }

        &--xlight {
            background-color: #f7f9ff;
            border: 1px solid #d6e1ff;

            .c-input--search {
                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 30px #f7f9ff inset !important;
                    border: 0px;
                }
            }
        }

        &--secondary {
            .c-input--search {
                max-width: none;
                padding-right: calc($unit * 3);
            }

            .c-input--search__wrapper {
                flex: 1;
            }
        }

        &--lrg {
            padding: calc($unit * 3) calc($unit * 2);
        }

        .c-input:focus,
        .c-input:active,
        .c-input:target,
        .c-input:focus-visible {
            box-shadow: 0 0 0 2px transparent;
        }

        .c-input {
            &[disabled] {
                color: color($color-dblue-7 a(0.8)) !important;

                background-color: #f7f9ff;
                border: 0px solid transparent;
            }
        }
    }

    &--search {
        min-width: 230px;
        max-width: 290px;

        margin-bottom: 0px;
        padding: 0px;
        padding-right: calc($unit * 3);

        border-radius: calc($unit * 3);
        border: 1px solid transparent;

        caret-color: $color-dblue-8;
        background: transparent;

        /* padding-left: calc($unit * 12); */

        /* med */
        @media (--screen-to-sml) {
            min-width: unset;
            max-width: unset;

            border-radius: calc($unit * 2);
        }

        &::placeholder,
        &:placeholder-shown {
            /* med */
            @media (--screen-to-sml) {
                text-overflow: ellipsis;
            }
        }

        &__wrapper {
            display: flex;
            align-items: center;
            gap: calc($unit * 3);

            /* med */
            @media (--screen-to-sml) {
                flex: 1;
            }

            &--med {
                min-width: 300px;

                @media (--screen-to-sml) {
                    min-width: unset;
                }
            }
        }

        &:focus {
            border-radius: 12px;
            box-shadow: 0 0 0 2px transparent;
            max-width: unset;
        }

        &.clear {
            background-image: svg-load("./../postcss-svg/default-icons/icon-search.svg", fill: transparent);
            transition: all 30ms $transition;
        }

        &--lrg {
            min-width: 242px;
            max-height: 48px;
        }

        &--sml {
            background-size: 12px 12px;
            background-position: 6px;
            padding-left: calc($unit * 8);

            &:focus {
                padding-left: calc($unit * 8);
            }
        }
    }

    &--select {
        position: relative;

        background-image: svg-load("./../postcss-svg/default-icons/arrow-down.svg", stroke: #596f9b);
        padding-right: 12px;
        background-position: 100% 30%;
        background-origin: content-box;
        background-color: $white;

        cursor: pointer;

        height: calc($unit * 10);

        min-width: 100px;

        @media (--screen-from-huge) {
            /* min-width: 220px; */
            min-width: 140px;
        }

        &:focus {
            background-image: svg-load("./../postcss-svg/default-icons/arrow-up.svg", stroke: #596f9b);
        }

        &::placeholder {
            color: $color-dblue-6;
        }

        &__text {
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-user-select: none;
            user-select: none;
            white-space: nowrap;
            width: 80%;
            color: $color-dblue-6;

            position: absolute;
            height: calc($unit * 10);

            left: 4px;
            top: 10px;
            bottom: auto;
            right: auto;

            padding: 0px calc($unit * 2);

            &--selected {
                color: $color-dblue-9;
            }
        }

        &__wrapper {
            flex: 1;

            @media (--screen-from-lrg) {
                max-width: 340px;
            }

            &.fullWidth {
                width: 100%;
                max-width: 100%;
            }
        }

        &.disabled {
            color: color($color-dblue-7 a(0.8)) !important;

            background-color: color($color-lblue-10 a(0.7));
            border-color: $color-lblue-12;
            cursor: unset;
        }

        &--open {
            background-image: svg-load("./../postcss-svg/default-icons/arrow-up.svg", stroke: #596f9b);
        }

        &__clickable {
            width: 90%;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            user-select: none;
            white-space: nowrap;
        }

        &--light {
            background-color: #f7f9ff;
            border: 1px solid #d6e1ff;
        }

        &__content {
            z-index: 4;

            position: absolute;
            top: 110%;
            left: 0;

            width: 100%;
            height: auto;

            padding: calc($unit * 2);

            border: 1px solid $color-lblue-8;
            border-radius: calc($unit * 3);

            background-color: $white;
            box-shadow: 0px 12px 20px rgba(104, 124, 174, 0.2);

            display: none;
            overflow: hidden;
            transition: all 100ms ease-out;

            

            &.isOpen {
                display: block;
            }
        }

        &__list {
            cursor: pointer;

            max-height: 220px;
            overflow-x: hidden;
            overflow-y: auto;

            width: 100%;
            z-index: 2;

            &.isOpen {
                display: block;
            }

            &--lrg {
                padding: calc($unit * 4);
            }

            &--top {
                bottom: 100%;
                top: auto;

                margin-bottom: 8px;
                box-shadow: 0px -4px 20px rgba(104, 124, 174, 0.15);
            }

            &--bottom {
                top: 100%;
                margin-top: 8px;
            }

            &--secondary {
                width: 130px !important;
                left: unset;
                right: -10px !important;
                margin-top: calc($unit * 4);
            }

            & .c-input--search {
                min-width: auto;
                padding: 0;

                border-radius: 0;

                &__wrapper {
                    gap: calc($unit * 2);
                }
            }
        }

        &--secondary {
            border: 0px solid transparent !important;
            min-width: unset !important;
            background-position: 50% 50%;
        }

        &__option {
            padding: calc($unit * 2);
            font-size: $type-sml;
            font-weight: $type-weight-regular;
            color: $color-dblue-7;
            border-radius: calc($unit * 1.5);

            transition: all 0.15s ease-in-out;

            &:hover {
                color: $color-primary-8;
                background-color: $color-lblue-4;
                transition: all 0.15s ease-in-out;
            }

            &--active {
                color: $color-primary-10;
                padding: 8px;
                background-color: $color-lblue-6;
                border-radius: calc($unit * 1.5);
                transition: all 0.15s ease-in-out;
            }

            &--wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &--secondary {
                padding: calc($unit * 1.5) calc($unit * 2);
            }
        }

        &--sml {
            min-width: 0px;
            padding-right: 0px;
        }
    }

    &--check,
    &--radio,
    &--check--secondary {
        opacity: 0;
        position: absolute;

        & + label {
            position: relative;
            display: inline-block;
            cursor: pointer;
            font-size: $type-sml;
            padding-left: calc($unit * 6);

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 1px;

                width: calc($unit * 4);
                height: calc($unit * 4);

                padding: $unit;

                background-color: $white;
                border: 1px solid #6892ff;

                transition: all 300ms ease-out;
            }

            &:after {
                /* z-index: 1; */
                content: "";
                opacity: 0;
                position: absolute;
                left: 3px;
                top: 5px;
                display: inline-block;

                width: calc($unit * 2.5);
                height: calc($unit * 2.5);

                transition: all 300ms ease-out;
            }
        }

        &:checked {
            & + label {
                &:before {
                    background-color: $color-primary-10;
                    border-color: $color-primary-10;
                }
                &:after {
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }

        &:focus {
            & + label {
                &:before {
                    /* outline: 2px dotted $color-secondary; */
                    /* border-radius: $radius-base; */
                }
            }
        }
    }

    &--check {
        display: none;

        &--base {
            width: 16px;
            height: 16px;
        }
        & + label {
            &:before {
                border-radius: 3px;
            }

            &:after {
                left: 3px;
                top: 5px;
            }
        }

        &:checked {
            &:before {
                & + label {
                    background-color: $color-primary-10;
                    /* border-color: $color-primary-1; */
                }
            }

            & + label {
                &:after {
                    top: 4px;
                    opacity: 1;
                    transform: rotate(0deg);
                    background-image: svg-load("./../postcss-svg/default-icons/check.svg", stroke: $white);
                }
            }
        }

        &[disabled] {
            & + label {
                color: color($color-text-light a(0.5));
                cursor: default;

                &:before {
                     border: 1px solid #bdc4d5;
                        background-color: #fff;
                }
            }

            &:checked {
                & + label {
                    &:before {
                        border: 1px solid #bdc4d5;
                        background-color: #bdc4d5;
                    }

                    &:after {
                        background-image: svg-load("./../postcss-svg/default-icons/check.svg", stroke: #fff);
                    }
                }
            }
        }

        &:checked &--disabled {
            & + label {
                color: color($color-text-light a(0.5));
                cursor: auto;

                &:before {
                    border: 1px solid #bdc4d5;
                        background-color: #fff;
                }

                &:after {
                        background-image: svg-load("./../postcss-svg/default-icons/check.svg", stroke: #596f9b);
                    }
            }
        }

        /* &--disabled {
            & + label {
                color: color($color-text-light a(0.5));
                cursor: auto;

                &:before {
                    border: 1px solid color($color-lblue-12 a(0.9));
                    background-color: color($color-lblue-12 a(0.75));
                }
            }
        } */

        &--no-label {
            display: none;
            cursor: pointer;
            font-size: $type-sml;
            padding-left: calc($unit * 0);
            width: calc($unit * 4);
            height: calc($unit * 4);

            & + label {
                position: absolute;
                top: 33%;
            }

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 1px;

                width: calc($unit * 4);
                height: calc($unit * 4);

                padding: $unit;

                background-color: $color-lblue-12;

                transition: all 300ms ease-out;
            }

            &:after {
                /* z-index: 1; */
                content: "";
                opacity: 0;
                position: absolute;
                left: 3px;
                top: 5px;
                display: inline-block;

                width: calc($unit * 2.5);
                height: calc($unit * 2.5);

                transition: all 300ms ease-out;
            }

            &:checked {
                &:before {
                    background-color: $color-primary-10;
                    /* border-color: $color-primary-1; */
                }

                &:after {
                    top: 4px;
                    opacity: 1;
                    transform: rotate(0deg);
                    background-image: svg-load("./../postcss-svg/default-icons/check.svg", stroke: $white);
                }
            }
        }

        &--secondary {
            & + label {
                &:before {
                    width: calc($unit * 6);
                    height: calc($unit * 6);

                    border-color: $color-border;
                    background-color: $color-border;
                }
            }

            &:checked {
                & + label {
                    &:before {
                        width: calc($unit * 6);
                        height: calc($unit * 6);

                        background-color: red;
                        border-color: red;
                    }

                    &:after {
                        top: 4px;
                        opacity: 1;
                        transform: rotate(0deg);
                        background-image: none;
                    }
                }
            }

            &:hover {
                & + label {
                    &:before {
                        border-color: $grey-3;
                        background-color: $grey-3;
                    }
                }

                &:checked {
                    & + label {
                        &:before {
                            border-color: color(red a(0.3));
                            background-color: color(red a(0.6));
                        }
                    }
                }
            }
        }

        &--tertiary {
            & + label {
                &:before {
                    left: 0;
                    top: 1px;

                    width: calc($unit * 4);
                    height: calc($unit * 4);

                    padding: $unit;

                    border: 2px solid color(white blackness(+30%));
                    border-radius: calc($unit * 4);
                }

                &:after {
                    left: 3px;
                    top: 5px;
                }
            }

            &:checked {
                & + label {
                    &:before {
                        background-color: $color-secondary;
                        border-color: $color-secondary;
                    }

                    &:after {
                        top: 4px;
                        opacity: 1;
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }

    &--radio {
        & + label {
            position: relative;
            &:before {
                border-radius: 50%;
            }

            &:after {
                content: "";
                opacity: 0;
                display: inline-block;
                position: absolute;
                top: 4px;
                left: 3px;

                width: calc($unit * 2.5);
                height: calc($unit * 2.5);

                background-color: $color-text-dark;
                border-radius: $radius-full;
                transform: scale(0);
                transition: all 300ms ease-out;
            }
        }

        &:checked {
            & + label {
                &:after {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }

        &:focus {
            & + label {
                &:before {
                    box-shadow: $z-depth-1;
                }
            }
        }
    }

    &--radio-btn {
        opacity: 0;
        position: absolute;

        &.c-input--radio-btn--first {
            & + label {
                border-top-left-radius: $radius-base;
                border-bottom-left-radius: $radius-base;
            }
        }

        &.c-input--radio-btn--last {
            & + label {
                border-top-right-radius: $radius-base;
                border-bottom-right-radius: $radius-base;
            }
        }

        &.active {
            & + label {
                background: #339dff;
                color: $color-text-dark;
                border: 1px solid transparent;

                &:hover {
                    background: color(#339dff blackness(+10%));
                }

                &:focus {
                    outline: 0;
                    border-radius: $radius-base;
                    box-shadow: 0px 0px 0px 2px $color-secondary inset;
                }
            }
        }

        & + label {
            font-size: $type-base;
            padding: calc($unit * 1) calc($unit * 3);

            background-color: transparent;
            color: #339dff;
            border: 1px solid #339dff;

            cursor: pointer;
            transition: all 300ms $transition;

            &:hover {
                background-color: transparent;
                border: 1px solid color(#339dff a(0.7));
            }

            &:focus {
                background-color: transparent;
                border: 1px solid $color-secondary;
                box-shadow: 0px 0px 0px 2px $color-secondary inset;
            }
        }
    }

    &--file {
        padding: calc($unit * 3);

        background-color: $color-lblue-4;
        border-radius: calc($unit * 4);

        border: 1px solid $color-lblue-12;

        &--drag-area {
            border: 2px dashed $color-lblue-12;
            padding: calc($unit * 3);
            width: 100%;
            border-radius: calc($unit * 2.5);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 16px;
        }
        .drag-area.active {
            border: 2px solid #fff;
        }
        .drag-area img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
        }
    }

    &--warning {
        border: 1px solid red;
    }

    &__label {
        font-size: $type-sml;
        color: $color-dblue-7;
        font-weight: $type-weight-medium;

        &--light {
            font-weight: $type-weight-regular;
        }

        &--none {
            color: transparent;
        }

        @media (--screen-to-sml) {
            text-align: left;
        }
    }

    &--price-input-icon {
        padding-right: 34px;
        margin-bottom: 0;

        background-image: svg-load("./../postcss-svg/default-icons/icon-search.svg", fill: color(#7188bb a(1)));
        background-position: 16px;
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-color: transparent;

        vertical-align: middle;

        border-radius: 12px;
        caret-color: #4968a9;
    }

    &__price-wrapper {
        align-items: center;
        padding: 0px 12px;
        height: 44px;
        border-radius: 8px;
        border: 1px solid $color-lblue-12;
        &:focus-within {
            border-radius: 8px;
            box-shadow: 0 0 0 1px $color-primary-6;
            outline: 0;
        }
    }

    &--price-input {
        /* min-width: 80px; */
        max-width: 80px;

        margin-bottom: 0px;
        padding: 0px;

        padding-right: calc($unit * 0);
        padding-left: calc($unit * 2);

        border-radius: 12px;
        border: 1px solid transparent;

        background-color: transparent;

        caret-color: $color-dblue-8;

        &:focus {
            border-radius: 12px;
            box-shadow: 0 0 0 2px transparent;
        }

        &.clear {
            background-image: svg-load("./../postcss-svg/default-icons/icon-search.svg", fill: color(#7188bb a(0)));
            transition: all 30ms $transition;
        }

        &--lrg {
            min-width: 242px;
            max-height: 48px;
        }

        &--sml {
            background-size: 12px 12px;
            background-position: 6px;
            padding-left: calc($unit * 8);

            &:focus {
                padding-left: calc($unit * 9.5);
            }
        }

        &__divider {
            border-left: 1px solid $color-lblue-9;
            border-right: 1px solid $color-lblue-9;
            height: 22px;
        }

        &__currency {
            color: $color-primary-10;
            font-size: $type-base;
            font-weight: $type-weight-medium;
        }
    }

    /**
     * Error handling
     */

    &__error {
        display: flex;
        align-items: center;

        &__message {
            font-size: $type-sml;
            color: $color-error;
            font-weight: $type-weight-medium;
            flex: 1;
            word-break: break-word;
            text-align: left;

            &--sml {
                /* white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 10px; */
            }
        }
    }

    &__warning {
        &__message {
            font-size: $type-sml;
            color: $color-warning-dark;
            font-weight: $type-weight-medium;

            &--base {
                max-width: 400px;
            }
        }
    }

    &--error {
        border: 1px solid #df1a6d;
    }

    &--white {
        background-color: $white;
    }

    &--price {
        /* height: 44px; */
    }

    &--group {
        padding: calc($unit * 3);
        border-radius: 12px;

        &--light {
            background-color: $color-lblue-4;
        }

        &--white {
            background-color: #fff;
            border: 1px solid #e9effe;
        }

        &--dual {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        &--sml {
            max-width: 252px;
        }
    }

    &--number {
        &::after {
            content: svg-load("../postcss-svg/default-icons/dollar.svg", fill: $color-primary-10 !important;);
            color: $color-primary-10;
            font-size: $type-base;
            font-weight: $type-weight-medium;
        }
    }

    &--percent {
        &::after {
            content: "%";
            color: $color-primary-10;
            font-size: $type-base;
            font-weight: $type-weight-medium;
        }
    }

    &--searchable {
        &__wrapper {
            display: flex;
            padding: calc($unit * 1.5);
            padding-left: 0px;

            border: 1px solid $color-lblue-9;
            background-color: $color-lblue-4;
            padding: calc($unit * 3);
            border-radius: 8px;

            &:focus-within {
                border-radius: 12px;
                box-shadow: 0 0 0 1px $color-primary-4;
                outline: 0;
            }

            .c-input:focus,
            .c-input:active,
            .c-input:target,
            .c-input:focus-visible {
                box-shadow: 0 0 0 2px transparent;
            }

            .c-input {
                &[disabled] {
                    color: color($color-dblue-7 a(0.8)) !important;

                    background-color: #f7f9ff;
                    border: 0px solid transparent;
                }
            }

            &::placeholder {
                color: $color-dblue-6;
            }
        }
    }
}
