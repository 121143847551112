.c-logo {
    border-radius: 100px;
    object-fit: cover;

    &__container {
        display: flex;
        gap: $unit;
        /* margin-left: calc((-1) * 6); */

        svg {
            cursor: pointer;
        }
    }

    &__label {
        font-size: $type-sml;
        font-weight: $type-weight-semibold;
        color: $color-dblue-7;
        margin-left: calc($unit/2);
    }

    &--sml {
        transform: scale(1.3); 
    }
}
