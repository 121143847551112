.c-floating-card {
    position: sticky;
    bottom: 27px;
    left: 50%;
    transform: translateX(calc(-50%));

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    max-width: 535px;
    padding: calc($unit * 4);

    border: 1px solid #dce3f2;
    box-shadow: 0px 6px 18px rgb(96 108 162 / 20%);
    background-color: $white;
    border-radius: calc($radius-base * 1.5);

    i {
        filter: brightness(0) saturate(100%) invert(87%) sepia(0%) saturate(6%) hue-rotate(336deg) brightness(79%)
            contrast(94%);
    }
}
