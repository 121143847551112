.c-tooltip {
    z-index: 4;

    display: none;
    width: 120px;

    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: $unit;
    position: absolute;
    
    top: 120%;
    left: 30%;
    margin-left: -40px;

    &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 43%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #333 transparent;
    }

    &--lrg {
        width: 240px;
        left: 0%;
        padding: calc($unit * 2);

        &::after {
            left: 50%;
        }
    }
}
