/*------------------------------------*\
    # component.table
\*------------------------------------*/

.c-custom-table {
    display: table;

    &--primary {
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;

        &__thead {
            display: table-header-group;
        }

        &__tbody {
            display: table-row-group;
        }

        &__tfoot {
            display: table-footer-group;
        }

        &__tr {
            display: table-row;
        }

        &__th {
            display: table-cell;
            padding: calc($unit * 2) $unit;

            font-size: $type-sml;
            line-height: 18px;
            text-align: left;

            color: $color-dblue-8;
            font-weight: $type-weight-semibold;
            background-color: $white;

            &:last-child {
                text-align: right;
            }

            &--name {
                width: 140px;
            }

            &--id {
                width: 40px;
            }

            &--inventory {
                width: 112px;
            }

            &--price {
                width: 132px;
            }

            &--transfer {
                width: 110px;
            }

            &--total {
                width: 100px;
            }

            &--action {
                width: 60px;
            }
        }

        &__td {
            display: table-cell;
            padding: calc($unit * 2);
            padding-left: 0;

            box-shadow: 0px 1px 0px 0px #f7f9ff inset;
            background-color: $white;

            font-weight: $type-weight-regular;
            font-size: $type-sml;
            line-height: 18px;
            color: #596F9B;

            &:last-child {
                text-align: right;
            }
        }
    }
}
