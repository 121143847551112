/*------------------------------------*\
    # utils.position
\*------------------------------------*/

.u-position {
    &--stc {
        position: static;
    }

    &--stk {
        position: sticky;
    }

    &--rel {
        position: relative;
    }

    &--fix {
        position: fixed;
    }

    &--abs {
        position: absolute;

        &--tr {
            right: 6px;
            top: 6px;
        }

        &--trc {
            right: -3px;
            top: -3px;
        }

        &--tl {
            left: 6px;
            top: 6px;
        }

        &--tlc {
            left: -6px;
            top: -6px;
        }

        &--br {
            right: 6px;
            bottom: 6px;
        }

        &--bl {
            left: 6px;
            bottom: 6px;
        }
    }
}
