.c-anchor {
    display: inline-flex;
    align-items: center;
    align-self: center;
    justify-content: center;

    padding: 0px;

    touch-action: manipulation;
    user-select: none;

    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;

    border: 0px;
    background-color: transparent;
    
    &:focus {
        outline: 0;
    }

    /**
    * Anchor base project styling
    */
    min-width: 70px;
    font-family: $ff-sans;
    font-weight: $type-weight-semibold;
    border-radius: $radius-base;
    transition: all 300ms $transition;

    /**
    * Sizes
    */
    &--tny {
        font-size: $type-tny;
    }

     &--sml {
        font-size: $type-xsml;
    }

    &--base {
        font-size: $type-sml;
    }

    &--lrg {
        font-size: $type-base;
    }

    /**
    * Types
    */

    &--primary {
        color: $color-dblue-6;
        font-weight: $type-weight-regular;

        &:hover {
            color: $color-primary-12;
        }
    }

    &--secondary {
        color: $color-primary-10;
        font-weight: $type-weight-semibold;

        &:hover {
            color: $color-primary-12;
        }
    }

    &--tertiary {
        color: $color-error;
        font-weight: $type-weight-medium;
        border-bottom: 1px solid $color-error;
        border-radius: 0px;
        transition: all .2s ease-in-out;

        &:hover {
            color: $color-error-dark;
            border-bottom: 1px solid $color-error-dark;
        }
    }

    &--icon {
        color: $color-dblue-6;
        font-weight: $type-weight-medium;
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    &--white {
        color: $white;
        border-bottom: 1px solid $white;
        border-radius: 0px;
    }

    &--underline {
        border-bottom: 1px solid #2c2f31;
        border-radius: 0px;
    }
}
