::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    /* background-color: #f5f8ff; */
    border-radius: 0px;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    /* background-color: #f5f5f5; */
}

::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: #A7B5DA;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
}

/* ::-webkit-scrollbar-track-piece:end {
  margin-right: 0px; 
}

::-webkit-scrollbar-track-piece:start {
  margin-left: 0px;
} */