.__react_component_tooltip {
    width: 200px;
    white-space: normal;
    text-transform: none;
    border-radius: calc($unit * 2) !important;
    padding: calc($unit * 2) calc($unit * 3.5) !important;

    font-size: $type-sml !important;
    font-family: $ff-sans;
}

.p-tooltip {
    white-space: break-spaces;
    z-index: 10;

    &--info {
        padding: calc($unit * 2);
    }

    &--error {
        background-color: $color-error !important;
    }
    
    &--warning {
        background-color: $color-warning !important;
    }

    &--tr {
        right: 6px;
        top: 6px;
    }

    &--trc {
        right: -6px;
        top: -6px;
    }

    &--tl {
        left: 6px;
        top: 6px;
    }

    &--tlc {
        left: -6px;
        top: -6px;
    }

    &--br {
        right: 6px;
        bottom: 6px;
    }

    &--bl {
        left: 6px;
        bottom: 6px;
    }

    &--sml {
        max-width: 300px;
    }

    &--base {
        max-width: 300px;
    }
    
    &--med {
        max-width: 400px;
    }
    
    &--lrg {
        max-width: 500px;
    }
}
