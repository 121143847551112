.c-dropdown {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    background-color: $white;
    border-radius: $radius-base;

    cursor: pointer;

    &__btn {
        display: flex;
        align-items: center;
        gap: calc($unit * 2);

        font-weight: $type-weight-medium;
        font-size: $type-sml;
        line-height: 18px;
        color: $color-primary-10;

        &--sml {
            padding: calc($unit * 2) calc($unit * 3);
            font-size: $type-xsml;
            border-radius: calc($unit * 1.5);
        }

        &--base {
            padding: calc($unit * 3);
        }
    }

    &__actions {
        min-width: 123px;

        margin-top: calc($unit * 1);
        margin-right: $unit;

        overflow: hidden;
        overflow-y: auto;

        background-color: $white;
        border-radius: $radius-base;
        box-shadow: 0px 4px 30px rgba(155, 166, 212, 0.3);

        z-index: 200;

        &--sml {
            max-height: 115px;
        }

        &--med {
            max-height: 400px;
        }

        &--top {
            bottom: 115%;
        }

        &--bottom {
            top: 115%;
        }

        &--secondary {
            margin-top: calc($unit * 2);
            min-width: 180px;
        }
    }

    &__action {
        display: flex;
        align-items: center;
        gap: calc($unit * 2);

        padding: calc($unit * 2) calc($unit * 3);

        font-weight: $type-weight-medium;
        color: #455678;

        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: $color-lblue-10;
        }

        &.isOpen {
            .u-icon--arrow-down {
                transform: rotate(180deg);
            }
        }

        &--disabled {
            font-weight: $type-weight-regular;
            color: #97a1b5;
            cursor: context-menu;

            &:hover {
                background-color: $white;
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
    }

    &__option {
        padding: calc($unit * 1);
        padding-left: calc($unit * 6);
        cursor: pointer;
        color: #596f9b;
        transition: background-color 0.3s;

        &:hover {
            background-color: $color-lblue-10;
        }
    }
}

.trigger {
    touch-action: manipulation;
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
}

.content {
    z-index: 200;
}
