/*------------------------------------*\
    # tools.ricky
\*------------------------------------*/

/**
 * Custom grid based on flexbox.
 * $f-grid-gutter - amount of space between columns
 * $f-grid-columns - number of columns of the grid
 */
$grid-gutter: 36px;
$grid-columns: 12;

/**
  * The flex-col mixin is used for building flexbox grid columns
  */
@define-mixin grid-span $grid-span: 1, $context: $grid-columns {
    width: calc($(grid-span) / $(context) * 100%);
}
@define-mixin grid-push $push: 1, $context: $grid-columns {
    margin-left: calc($(push) / $(context) * 100%);
}

/**
  * The grid-pull mixin is used for pulling columns using margin-left
  * for the space of a number of columns
  */
@define-mixin grid-pull $pull: 1, $context: $grid-columns {
    margin-right: calc($(pull) / $(context) * 100%);
}

/**
  * Row
  */
.row {
    /* margin-left: calc(-$grid-gutter / 2);
     margin-right: calc(-$grid-gutter / 2); */
    display: flex;
    justify-content: left;
    flex-wrap: wrap;

    &--align-center {
        align-items: center;
    }

    &--align-end {
        align-items: flex-end;
    }

    &--gap {
        &--sml {
            gap: calc($unit * 2);
        }

        &--base {
            gap: calc($unit * 3);
        }

        &--med {
            grid-gap: calc($unit * 4);
        }

        &--lrg {
            gap: calc($unit * 6);
        }

        &--xlrg {
            gap: calc($unit * 8);
        }
    }

    &--table {
        @media (--screen-to-sml) {
            flex-wrap: nowrap;
        }
    }

    &--1 {
        grid-template-columns: minmax(auto, 100%);
    }

    &--2 {
        display: grid;
        grid-template-columns: minmax(auto, 60%) minmax(auto, 40%);
        gap: calc($unit * 8);

        &--3-1 {
            grid-template-columns: minmax(auto, 60%) minmax(auto, 40%);
        }

        &--4-1 {
            grid-template-columns: minmax(auto, 70%) minmax(auto, 30%);
        }
    }

    &--3 {
        grid-template-columns: minmax(auto, 33.3333%) minmax(auto, 33.3333%) minmax(
                auto,
                33.3333%
            );
    }

    &--4 {
        grid-template-columns:
            minmax(auto, 25%) minmax(auto, 25%) minmax(auto, 25%)
            minmax(auto, 25%);
    }

    &--5 {
        grid-template-columns:
            minmax(auto, 20%) minmax(auto, 20%) minmax(auto, 20%)
            minmax(auto, 20%) minmax(auto, 20%);
    }

    &--6 {
        grid-template-columns:
            minmax(auto, 16.6667%) minmax(auto, 16.6667%) minmax(auto, 16.6667%)
            minmax(auto, 16.6667%) minmax(auto, 16.6667%) minmax(auto, 16.6667%);
    }
}

/**
  * Column
  */
.col {
    padding-left: calc($grid-gutter / 2);
    padding-right: calc($grid-gutter / 2);
    transition: all 250ms ease-out;
}
