.c-pagination {
    display: flex;
    gap: 16px;

    &__pages {
        display: flex;
    }
    &__page {
        padding: 4px 10px;
        border: 0px;

        border-radius: 100px;
        background-color: transparent;

        font-size: $type-sml;
        line-height: 18px;

        font-weight: $type-weight-bold;
        color: $color-dblue-6;

        cursor: pointer;

        transition: all 0.18s ease-in-out;

        &:hover {
            background-color: $color-lblue-8;
            color: $color-dblue-6;
            transition: all 0.18s ease-in-out;
        }

        &--active {
            background-color: $color-primary-10;
            color: $white;
            transition: all 0.18s ease-in-out;

            &:hover {
                background-color: $color-primary-10;
                color: $white;
                transition: all 0.18s ease-in-out;
            }
        }
        
        &--secondary {
        padding: 6px 10px;
        font-weight: $type-weight-semibold;
    }
    }

    &__button {
        padding: 8px 11px;
        background-color: $white;
        border-radius: 100px;
        border: 1px solid $color-lblue-12;

        cursor: pointer;

        &:disabled {
            opacity: 0.6;
            cursor: default;
        }
    }

    
}

.c-pagination .active .c-pagination__page {
     background-color: $color-primary-10;
            color: $white;
            transition: all 0.18s ease-in-out;

            &:hover {
                background-color: $color-primary-10;
                color: $white;
                transition: all 0.18s ease-in-out;
            }
}
