/*------------------------------------*\
    # utils.display
\*------------------------------------*/

.u-display {
    &--i {
        display: inline;
    }

    &--b {
        display: block !important;
    }

    &--ib {
        display: inline-block;
    }

    &--none {
        display: none;

        &--sml {
            @media (--screen-to-sml) {
                display: none !important;
            }
        }

        &--lrg {
            @media (--screen-from-sml) {
                display: none !important;
            }
        }
    }

    &--table {
        display: table;
    }

    &--unset {
        display: unset !important;
    }

    &--grid {
        display: grid;
    }

    &--width {
        &--full {
            width: 100%;
        }

        &--sml {
            width: 60px;
        }
        &--base {
            width: 400px;

            &--max {
                max-width: 400px;
            }
        }
        &--unset {
            width: unset;
        }
        &--med {
            max-width: 800px;
        }

        &--lrg {
            max-width: 1000px;
        }
    }

    &--separated {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: calc($unit * 2);
        flex-wrap: wrap;
        row-gap: calc($unit * 3);

        margin-bottom: $unit;

        &--nw {
            flex-wrap: nowrap;

            /* med */
            @media (--screen-to-sml) {
                flex-wrap: wrap;
            }
        }
    }

    &--flex {
        display: flex;

        &--fd {
            &--column {
                flex-direction: column;

                &--to {
                    &--xlrg {
                        @media (--screen-to-xlrg) {
                            flex-direction: column;
                        }
                    }

                    &--med {
                        @media (--screen-to-med) {
                            flex-direction: column;
                        }
                    }
                }
            }

            &--row {
                flex-direction: row;
            }
        }

        &--wrap {
            flex-wrap: wrap;
        }

        &--gap {
            &--xsml {
                gap: calc($unit * 1);
            }

            &--sml {
                gap: calc($unit * 2);
            }

            &--base {
                gap: calc($unit * 3);
            }

            &--med {
                grid-gap: calc($unit * 4);
            }

            &--lrg {
                gap: calc($unit * 6);
            }

            &--xlrg {
                gap: calc($unit * 8);
            }
        }

        &--jc {
            &--center {
                justify-content: center;
            }

            &--sb {
                justify-content: space-between;

                &--sml {
                    /* med */
                    @media (--screen-to-sml) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }

            &--flex-start {
                justify-content: flex-start;
            }

            &--flex-end {
                justify-content: flex-end;
            }
        }

        &--ai {
            &--center {
                align-items: center;
            }

            &--flex-start {
                align-items: flex-start;
            }

            &--flex-end {
                align-items: flex-end;
            }

            &--baseline {
                align-items: baseline;
            }
        }

        &--wrap {
            flex-wrap: wrap;

            &--to {
                &--xlrg {
                    @media (--screen-to-xlrg) {
                        flex-wrap: wrap;
                    }
                }
            }
        }

        &--fill {
            flex: 1;
        }

        &--fill-lrg {
            flex: 1.5;
        }

        &--reverse {
            flex-direction: column-reverse;

            &--to {
                &--lrg {
                    @media (--screen-to-lrg) {
                        flex-direction: column-reverse;
                    }
                }
            }
        }
    }

    &--flex-col-fill {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    &--flex-row-sb {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &--width-fit {
        width: fit-content;
    }

    &--sml {
        &--row {
            @media (--screen-to-lrg) {
                flex-direction: row;
            }
        }

        &--col {
            @media (--screen-to-lrg) {
                flex-direction: column !important;
            }
        }

        &--flex {
            @media (--screen-to-sml) {
                display: flex;
            }
        }

        &--wrap {
            @media (--screen-to-sml) {
                flex-wrap: wrap;
            }
        }

        &--fill {
            @media (--screen-to-sml) {
                flex: 1;
            }
        }

        &--full {
            @media (--screen-to-sml) {
                width: 100%;
            }
        }
    }

    &--med {
        &--full {
            @media (--screen-to-lrg) {
                width: 100%;
            }
        }
    }

    &--height {
        &--full {
            height: 100%;
        }
    }

    &--nw {
        flex-wrap: nowrap;
    }
}
