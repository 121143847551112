/*------------------------------------*\
    # tools.clearing
\*------------------------------------*/

@define-mixin clearfix {
    &:after {
        content: "";
        clear: both;
        display: block;
        height: 0;
        visibility: hidden;
        font-size: 0;
    }
}

.clearfix {
    @mixin clearfix;
}

.group {
    overflow: hidden;
}

/*clearfix for IE6 & above*/
/*

// IE6
* html .clearfix { 
    zoom: 1;
}
    
// IE7
*:first-child+html .clearfix { 
    zoom: 1;
} 

*/