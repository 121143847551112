.c-error {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-top: 100px;
    }

    &__text {
        font-size: $type-sml;
        color: $color-error;
        font-weight: $type-weight-medium;
    }

    &__copy {
        width: 400px;
        margin-top: calc($unit * 8);
        margin-bottom: calc($unit * 8);
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $color-dblue-10;

        &__subtext {
            font-size: $type-base;
            color: $color-dblue-7;
            font-weight: $type-weight-regular;
            text-align: center;
        }
    }

    &__img {
        width: 300px;
    }

    &--sml,
    &--warning,
    &--filled {
        padding: calc($unit * 3);
        background: color($color-error a(0.07));
        color: $color-error;
        display: flex;
        align-items: center;
        border-radius: calc($unit * 2);

        i {
            height: 20px;
            width: 20px;
        }
    }

    &--warning,
    &--filled {
        background: color($color-note a(0.1));
        color: $color-note;

        i {
            filter: brightness(0) saturate(100%) invert(51%) sepia(45%)
                saturate(3551%) hue-rotate(357deg) brightness(91%) contrast(96%);

            height: 28px;
            width: 28px;
        }
    }

    &--warning {
        &__text {
            color: $color-warning;
        }
    }

    &--xsml {
        padding: 0px;
        background-color: transparent;

        i {
            height: calc($unit * 3.5);
            width: calc($unit * 3.5);
        }
    }

    &--filled {
        background: color($color-error-dark a(0.1));
        color: $color-error-dark;

        i {
            filter: brightness(0) saturate(100%) invert(14%) sepia(100%)
                saturate(5597%) hue-rotate(328deg) brightness(83%)
                contrast(105%);
        }
    }
}
