.c-single {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: calc($unit * 4);

        margin-bottom: calc($unit * 6);
    }

    &__title {
        max-width: 600px;

        font-size: $type-xlrg;
        color: $color-dblue-12;

        @media (--screen-to-lrg) {
            width: 380px;
        }

        @media (--screen-to-sml) {
            width: auto;
        }
    }

    &__tag {
        &--id {
            background-color: #e6edfe;
            border-radius: 100px;
            color: #4c82f9;
            font-size: calc($unit * 3);
            font-weight: 600;
            padding: calc($unit * 1) calc($unit * 2);
            display: inline-block;
        }
    }

    &__image {
        width: 600px;
        /* height: auto;
        max-height: 356px; */
        height: 356px;
        object-fit: cover;
        border-radius: calc($unit * 3);
        /* filter: brightness(92%); */

        @media (--screen-to-xxlrg) {
            width: auto;
            max-width: 488px;
        }

        @media (--screen-to-lrg) {
            width: auto;
            max-width: 210px;
        }

        @media (--screen-to-med) {
            width: auto;
            max-width: 100%;
        }

         @media (--screen-to-sml) {
                width: 100%;
            }
    }

    &--isOpen {
        white-space: unset;
        text-overflow: unset;
        max-width: unset;
    }

    &__date {
        text-align: right;
    }
}
