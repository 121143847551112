.c-banner {
    padding: calc($unit * 3);
    border-radius: calc($unit * 2);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--info {
        background-color: color($color-lblue-12 a(0.8));
        border: 1px solid color($color-primary-10 a(0.8));
    }

    &--warning {
        background-color: $status-empty-background;
        border: 1px solid color(#e4690c a(0.4));
    }

    &__dismiss {
        color: $color-primary-10;
        border-bottom: 1px solid $color-primary-10;

        font-size: $type-sbase;
        font-weight: $type-weight-medium;
        word-break: normal;

        &:hover {
            cursor: pointer;
            color: $color-primary-12;
            border-bottom: 1px solid $color-primary-12;
        }
    }

    &__message {
        font-size: $type-sml;
        font-weight: $type-weight-medium;
        color: #1e3668;
    }

    &__title {
        font-size: $type-sbase;
        font-weight: $type-weight-semibold;
        color: $color-dblue-14;
        margin-bottom: $unit;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: calc($unit / 2);
        flex: 1;
    }
}
