.c-notification {
    border-radius: calc($unit * 2);

    &--info,
    &--success-info {
        background-color: color($color-lblue-12 a(0.3));
        border: 1px solid color($color-primary-10 a(0.08));
    }

    &--success {
        background-color: color($color-success-light a(0.7));
        border: 1px solid color($color-success a(0.1));
    }

    &--warning {
        background-color: color($color-warning a(0.08));
        border: 1px solid color($color-warning a(0.3));
    }

    &--error {
        background-color: $color-error-background;
        border: 1px solid #fce8ed;
    }

    &--sml {
        padding: calc($unit * 1) calc($unit * 2);
        border-radius: calc($unit * 1.5);
    }

    &--base {
        padding: calc($unit * 2);
    }

    &--med {
        padding: calc($unit * 3);
    }

    &__message {
        font-size: $type-xsml;
        font-weight: $type-weight-medium;

        &--info,
        &--success-info {
            color: $color-primary-10;
        }

        &--success {
            color: $color-success;
        }

        &--warning {
            color: $color-warning-dark;
        }

        &--error {
            color: $color-error;
        }
    }

}


