/*------------------------------------*\
    # base.typography
\*------------------------------------*/

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-family: $ff-sans;
    font-weight: $type-weight-bold;
}

h1 {
    font-size: responsive $type-lrg $type-xlrg;
    font-range: 320px 1440px;

    line-height: responsive 40px 44px;
    line-height-range: 320px 1440px;
}

h2 {
    /* font-size: responsive $type-huge $type-xhuge !important;
     font-range: 1000px 1440px !important;
 
     line-height: responsive 28px 36px !important;
     line-height-range: 320px 1440px !important; */

     font-size: $type-xhuge;
     line-height: 28px;

}

h3 {
    font-size: responsive $type-sml $type-base;
    font-range: 320px 1440px;

    line-height: responsive 20px 24px;
    line-height-range: 320px 1440px;
}

/**
  * Paragraphs
  */
p {
    font-size: responsive $type-xsml $type-base;
    font-range: 320px 1440px;

    line-height: responsive 14px 20px;
    line-height-range: 320px 1440px;
    margin: 0;
}

/**
  * Lists (ol, ul, dd)
  */
ol,
ul,
dl {
    margin: 0;
    padding: 0;
    list-style: none;
}
ol {
}
ol li {
}
ul {
}
ul li {
}
dd {
}
dl dt {
}
dl dd {
}

/**
  * Anchors
  */
a {
    color: $color-primary;
    text-decoration: none;
    text-decoration-skip-ink: auto; /* Ensure that underlines don’t pass through descenders. */
    transition: all 250ms $transition;
    word-break: break-all;

}
a:link {
}
a:hover {
    color: $color-dblue-10;
}
a:focus {
    outline: 0;
    border-radius: 2px;
    color: $color-dblue-10;
}
a:visited {
}

/**
  * Typographic details
  */
hr {
    border-top: 1px solid #bfbfbf;
    width: 100%;
}
em {
}
b,
strong {
    font-weight: bold;
}
address {
}
small {
}
pre {
}
code {
    background-color: color(#fff lightness(+80%));
    padding: calc($unit / 2);
    color: $color-secondary;
}
sub {
}
sup {
}
strike {
}
