.c-message {
    padding: calc($unit * 2);
    background-color: $color-lblue-4;
    border: 1px solid #ebf0ff;
    border-radius: calc($unit * 3);
    max-width: 600px;

    &--secondary {
        background-color: $color-primary-10;
       

        p {
            color: $white;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: calc($unit * 2);
        align-items: flex-start;
        
        &--right {
            align-items: flex-end;
        }
    }
}
