/*------------------------------------*\
    # plugin.ag
\*------------------------------------*/
.ag {
    font-family: $ff-sans;
    border-radius: 20px !important;

    &-theme-material {
        width: 100% !important;
        border-radius: 20px !important;
    }

    &-row-group {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-root-wrapper {
        border-radius: calc($unit * 2);
    }

    &-theme-material {
        &.loading {
            display: grid;
            grid-template-columns: 1fr;
            grid-auto-rows: 320px 74px;
        }

        & .ag-layout-auto-height {
            height: 100%;
        }

        & .ag-full-width-container {
            height: 100% !important;
        }
        & .ag-row-loading {
            height: 100% !important;
        }

        &--sml {
            grid-auto-rows: 269px 1fr;
        }

        &--empty {
            & .ag-body-horizontal-scroll-viewport {
                display: none;
            }
        }

        &--secondary {
            & .ag-header {
                &-row {
                    & .ag-header-cell-label {
                        justify-content: flex-end;
                    }

                    & .ag-header-cell {
                        &:last-child {
                            justify-content: flex-end;
                        }
                    }
                }
            }

            & .ag-row {
                & .ag-cell {
                    &:last-child {
                        justify-content: flex-end;
                    }
                }
            }
        }

        & .ag-layout-auto-height {
            & .ag-center-cols-container {
                min-height: 0px !important;
            }
        }
    }

    &-row-level-1 {
        & .ag-root-wrapper {
            border-radius: 0px !important;
        }

        & .ag-header {
            height: calc($unit * 10) !important;
            min-height: calc($unit * 10) !important;
        }

        & .ag-header-row {
            height: calc($unit * 10) !important;
        }
        & .ag-header-cell {
            background-color: $color-lblue-6 !important;
        }

        & .ag-details-row {
            border-radius: 0px !important;
        }

        & .ag-cell {
            background-color: #fff !important;
        }
    }

    &-root-wrapper {
        border: 0 !important;
        background-color: $white !important;
    }

    &-dnd-ghost {
        display: flex !important;

        &-ghost-label {
            position: relative !important;
        }
    }

    &-header {
        background-color: $white !important;
        border-bottom: 0 !important;

        &-row {
            font-family: $ff-sans;
            color: $color-dblue-8 !important;
        }

        &-cell {
            &-moving {
                background-color: $color-lblue-10 !important;
            }
        }
    }

    &-cell {
        padding-right: 0 !important;
        background-color: $color-lblue-2 !important;
    }

    &-row {
        font-family: $ff-sans;
        background-color: $color-lblue-2 !important;
        color: color($color-dblue-9 a(0.8)) !important;
        font-weight: $type-weight-medium !important;

        border-color: transparent !important;
        box-shadow: inset 0px -1px 0px $color-lblue-6 !important;

        &:hover {
            color: color($color-dblue-9 a(1)) !important;
        }

        &-last {
            box-shadow: inset 0 -1px 0 transparent !important;
        }

        &.isCollected {
            background-color: color($color-primary-10 a(0.2)) !important;
        }

        &-selected {
            color: $color-dblue-9 !important;

            &::before {
                background-color: $color-lblue-8 !important;
                background-image: none !important;
            }
        }

        &-hover {
            & .ag-cell {
                background-color: color($color-lblue-8 a(0.6)) !important;
            }
        }
    }

    &-cell {
        display: flex !important;
        align-items: center;
        height: calc($unit * 10);

        font-size: $type-sml;
        line-height: 20px;

        border: 0 !important;

        &-expandable {
            align-items: center !important;
        }
    }

    &-overlay-loading-wrapper {
        background-color: rgba(64, 65, 67, 0.5) !important;
    }

    &-react-container {
        min-height: calc($unit * 5);

        & div {
            display: flex;

            font-size: $type-sml;
            line-height: 20px;

            & .ag-icon {
                margin-left: calc($unit * 2);
            }
        }
    }

    &-header-viewport {
        border-bottom: 1px solid color($color-lblue-8 a(0.7)) !important;
    }

    &-details-row {
        padding: 0px !important;

        .ag-paging-panel {
            display: none;
        }

        .ag-row {
            background-color: $white !important;
        }
    }
}

.empty-state {
    & .ag-body-viewport {
        min-height: 470px !important;
    }
}

.ag-body-viewport.ag-layout-normal {
    scrollbar-color: $color-primary $elevation-08;
    scrollbar-width: thin;
}

.ag-body-horizontal-scroll {
    max-height: 10px !important;
    min-height: auto !important;
    border-radius: 100px !important;

    &-viewport {
        scrollbar-width: thin !important;

        max-height: 10px !important;
        min-height: 8px !important;

        border-radius: 100px !important;
    }

    &-container {
        max-height: 8px !important;
        min-height: 8px !important;

        border-radius: 100px !important;
    }
}

.ag-horizontal-right-spacer {
    max-width: 0 !important;
    min-width: 0 !important;
}

.ag-center-cols-container {
    min-width: 100% !important;
}

.ag-row-hover:not(.ag-full-width-row)::before {
    background-color: transparent !important;
}

.ag-dnd-ghost {
    max-width: 200px;
}

.ag-header-active {
    background-color: $color-lblue-10 !important;
}

[class*="ag-theme-"] {
    border-radius: 20px !important;
}

.ag-center-cols-clipper .ag-center-cols-viewport {
    overflow: hidden;
}

.ag-input-field-input.ag-checkbox-input {
    cursor: pointer !important;
}

.ag-checkbox-input-wrapper::after {
    content: "" !important;
    width: 14px;
    height: 14px;
    background-color: transparent !important;
    border: 1px solid $color-primary-6 !important;
    border-radius: 4px;

    cursor: pointer !important;
}

.ag-cell-wrapper .ag-selection-checkbox {
    cursor: pointer !important;
}

..ag-checkbox {
    cursor: pointer !important;
}

.ag-checked::after {
    background-color: $color-primary-10 !important;
    border: 1px solid $color-primary-10 !important;
    cursor: pointer !important;
    top: 4px;
    opacity: 1;
    transform: rotate(0deg);
    background-image: svg-load("./../postcss-svg/default-icons/check.svg", stroke: $white);
    background-repeat: no-repeat;
    background-position: center center;
}

.ag-checkbox-input-wrapper:focus-within {
    box-shadow: none !important;
    cursor: pointer !important;
}

.ag-checkbox-input-wrapper.ag-disabled {
    &::after {
        border: 1px solid $color-lblue-10 !important;
        background-color: $color-lblue-12 !important;
    }
}
.ag-cell {
    line-height: 16px !important;

    &-inline-editing {
        height: 47px !important;
        box-shadow: none !important;
        background-color: $color-lblue-6 !important;
        border: 1px solid $color-lblue-14 !important;
    }

    &:has(.c-tag--icon) {
        line-height: unset !important;
    }
}

.ag-row,
.ag-cell {
    color: $color-dblue-7 !important;
}

.ag-theme-material .ag-row .ag-cell-inline-editing {
    border-color: $color-lblue-14 !important;
}

.ag-theme-material .ag-has-focus .ag-row .ag-cell-inline-editing {
    border-color: $color-primary-4 !important;
}

input[class^="ag-"][type="text"] {
    height: unset !important;
    padding-bottom: 0px !important;
    border-bottom: 0px solid transparent !important;
    border-bottom-color: none !important;
}

.editable-cell {
    &:after {
        content: svg-load("../postcss-svg/default-icons/edit.svg", stroke: $color-primary-10);
        width: 16px;
        height: 16px;
        margin-left: 8px;
        opacity: 0;
    }
}

.editable-row {
    &:hover {
        cursor: pointer;
        .editable-cell {
            &:after {
                opacity: 1;
            }
        }
    }
}

.ag-row-selected {
    .editable-cell {
        &:after {
            opacity: 1;
        }
    }
}

.ag-overlay-loading-center {
    border: 0px solid transparent !important;
    background: transparent !important;
    border-radius: 0px !important;
    box-shadow: unset !important;
    padding: 0px !important;

    div {
        display: flex;
        flex-direction: column;

        & .c-empty__title {
            font-size: $type-med;
        }

        & .c-empty__text {
            font-size: $type-base;
        }
    }
}

/* Blocking table's overflow from showing */
.ag-center-cols-clipper .ag-center-cols-container {
    /* width: unset !important; */
}

.ag-layout-auto-height .ag-center-cols-clipper {
    height: auto !important;
}

.ag-theme-material .ag-layout-auto-height .ag-center-cols-clipper {
    min-height: auto !important;
}

.ag-details-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 320px 74px;
}

.ag-details-row .ag-center-cols-clipper .ag-center-cols-viewport .ag-center-cols-container {
    height: 100% !important;
    overflow-x: hidden !important;
}

.ag-details-row .ag-root-wrapper .ag-paging-panel {
    display: flex;
    justify-content: space-between;
}

.cellDropdown.ag-cell-value {
    overflow: visible !important;
}

.ag-withDropdown {
    & .ag-layout-auto-height {
        & .ag-center-cols-container,
        & .ag-center-cols-clipper {
            min-height: 250px !important;
        }
    }

    & .ag-row-focus {
        z-index: 3 !important;

        & .ag-cell-focus {
            overflow: visible !important;
        }
    }

    & .ag-center-cols-viewport {
        /* overflow-y: visible !important; */
    }
}

.ag-row-group {
    overflow: visible !important;
}

.ag-row:hover {
    z-index: 1;
}

.ag-root-wrapper-body.ag-layout-normal {
    height: 100% !important;
}

.ag-row-disabled .ag-cell {
    background: #eef0f1 !important;
    color: #9095a1 !important;
}