.c-login {
    display: flex;
    min-height: 100vh;
    background-color: #fff;

    &--additional {
        padding-bottom: calc($unit * 30);

        flex-direction: column;
        align-items: center;

        background-color: #f0f2ff;

        @media (--screen-to-sml) {
            background-color: #fff;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 0 0 60%;
        padding: 20px;
        padding-bottom: calc($unit * 10);
        text-align: left;

        /* med */
        @media (--screen-to-sml) {
            flex: 1;
            text-align: center;
        }

        &--additional {
            padding: calc($unit * 8);
            background: $white;
            margin-top: calc((-1) * $unit * 18);
            border-radius: calc($unit * 3);

            @media (--screen-to-sml) {
                margin-top: 0px;
                padding: calc($unit * 5);
                width: 100%;
            }
        }
    }

    &__header {
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;

        font-weight: $type-weight-bold;
        color: $color-dblue-10;

        &__title {
            flex: 0 0 34%;
        }

        &__description {
            display: flex;
            align-items: flex-start;
            padding: 12px;
            width: 400px;

            font-size: $type-sml;
            font-weight: $type-weight-medium;
            color: $color-dblue-9;

            border-radius: 8px;
            background-color: $color-lblue-4;

            @media (--screen-to-sml) {
                width: 100%;
            }
        }

        &__img {
            width: 300px;

            &--additional {
                width: 260px;
            }
        }

        @media (--screen-to-sml) {
            width: 100%;
        }
    }

    &__auth {
        max-width: 400px;
        width: 100%;
    }

    &__aside {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        width: 40%;
        background-color: #0f1929;

        &--additional {
            position: unset;
            width: 100%;
            height: 255px;
            background-color: #061a35;
        }

        &__wrapper {
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__logo {
            &--big {
                position: absolute;
                bottom: -125px;
                left: -25px;

                @media (--screen-to-lrg) {
                    left: -125px;
                    top: 30%;
                    max-width: unset;
                    width: 600px;
                    bottom: 0px;
                }
            }

            &--regular {
                width: 140px;
                height: auto;
            }

            &--sml {
                width: 110px !important;
                height: auto;
            }
        }

        &__content {
            padding: 125px;
            padding-top: 100px;
            display: flex;
            flex-direction: column;
            gap: calc($unit * 10);
            align-items: center;

            svg {
                width: 140px;
                height: auto;
            }

            &--additional {
                padding-top: calc($unit * 20);

                svg {
                    width: 240px;
                    height: auto;
                }
            }

            @media (--screen-to-huge) {
                padding: 60px;
                padding-top: 80px;
            }
        }

        &__description {
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: rgba(255, 255, 255, 0.9);
        }

        &__img-dashboard {
            position: absolute;
            /* width: 646px; */
            height: auto;
            bottom: 0px;
            right: 0;
            z-index: -2;

            background-image: url("../img/components.png");
            height: 100%;
            background-size: cover;
            width: 100%;

            @media (--screen-to-xxlrg) {
                height: calc(100% - 60px);
            }

            @media (--screen-to-xlrg) {
                width: 100%;
                height: 100%;
            }

            @media (--screen-to-lrg) {
                display: none;
            }

            @media (--screen-from-huge) {
                bottom: 28px;
            }
        }

        /* med */
        @media (--screen-to-sml) {
            display: none;
        }
    }

    &__title {
        width: 400px;
        margin-top: calc($unit * 8);
        margin-bottom: calc($unit * 8);
        color: $color-dblue-12;

        display: flex;
        flex-direction: column;
        gap: calc($unit * 3);

        &--additional {
            gap: calc($unit * 3);
            margin-top: 0px !important;
        }



        @media (--screen-to-sml) {
            width: 100%;
            margin-top: calc($unit * 10) !important;
            margin-bottom: calc($unit * 15);
        }
    }

    &__subtitle {
        font-size: $type-lrg;
    }

    &__separator {
        border: 0.5px solid $color-lblue-7;

        &--color-med {
            border: 0.5px solid $color-lblue-12;
        }
    }

    &__subtext {
        font-size: $type-base;
        color: $color-dblue-7;
        font-weight: $type-weight-regular;
        line-height: calc($unit * 5);
    }

    &--mt {
        &--lrg {
            margin-top: 60px;

            @media (--screen-to-sml) {
                margin-top: 0px;
            }
        }
        &--xlrg {
            margin-top: 120px;

            @media (--screen-to-sml) {
                margin-top: 0px;
            }
        }
    }
}
