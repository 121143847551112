.c-header {
    height: 73px;

    left: 250px;
    right: 0;
    position: fixed;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: calc($unit * 4) calc($unit * 8);
    margin-bottom: calc($unit * 8);

    background-color: #fafbff;
    border-bottom: 1px solid #d6e1ff;

    font-size: $type-sml;

    @media (--screen-to-sml) {
        padding: calc($unit * 4);
    }

    &.isCollapsed {
        left: 60px;

        @media (--screen-to-sml) {
            left: 0px;
        }
    }

    &__user {
        display: flex;
        /* flex-direction: column; */
        align-items: center;

        &__name {
            color: $color-dblue-10;
        }

        &__level {
            position: relative;

            &__icon {
                height: 40px;
                width: auto;
            }

            &__tooltip {
            }

            &:hover {
                & .c-tooltip {
                    display: block;
                }
            }
        }
    }

    &__wrapper {
        &--shopper {
            display: flex;
            align-items: center;
            gap: 12px;
        }

        &--dealer {
            display: flex;
            flex-direction: column;
        }
    }

    
}
