.c-loader {
    display: none;

    &__main {
        position: fixed;
        top: 0;
        left: 250px;
        height: 4px;
        display: block;
        width: 100%;
        background-color: color($color-primary-8 a(30%));
        border-radius: 2px;
        background-clip: padding-box;
        overflow: hidden;
        z-index: 999;
    }

    &__progress {
        background-color: $color-primary-8;

        &:before {
            content: '';
            position: absolute;
            background-color: inherit;
            top: 0;
            left: 0;
            bottom: 0;
            width: auto;
            will-change: left, right;
            -webkit-animation: loader 2.1s
                cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
            animation: loader 5.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
                infinite;
            border: 0;
            border-radius: 0;
        }

        &:after {
            content: '';
            position: absolute;
            background-color: inherit;
            top: 0;
            left: 0;
            bottom: 0;
            width: auto;
            will-change: left, right;
            -webkit-animation: loader-short 2.1s
                cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation: loader-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
                infinite;
            -webkit-animation-delay: 1.15s;
            animation-delay: 1.15s;
            border: 0;
            border-radius: 0;
        }
    }

    &--secondary {
        width: 100%;
        height: 4.8px;
        display: inline-block;
        position: relative;
        background: $color-lblue-4;
        overflow: hidden;
        border-radius: 100px;

        &:after {
            content: '';
            box-sizing: border-box;
            width: 0;
            height: 4.8px;
            background: $color-primary-8;
            position: absolute;
            top: 0;
            left: 0;
            animation: animFw 6s linear infinite;
            border-radius: 100px;
        }
    }

    &--tertiary {
        position: fixed;
        top: 0;
        left: 0px;
        height: 4.8px;
        display: block;
        width: 100%;
        background: $color-lblue-4;
        overflow: hidden;
        border-radius: 100px;
        background-clip: padding-box;

        &:after {
            content: '';
            box-sizing: border-box;
            width: 0;
            height: 4.8px;
            background: $color-primary-8;
            position: absolute;
            top: 0;
            left: 0;
            animation: animFw 6s linear infinite;
            border-radius: 100px;
        }
    }
}
@keyframes animFw {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@-webkit-keyframes loader {
    0% {
        left: -35%;
        right: 100%;
    }
    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}
@keyframes loader {
    0% {
        left: -35%;
        right: 100%;
    }
    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}
@-webkit-keyframes loader-short {
    0% {
        left: -200%;
        right: 100%;
    }
    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
}
@keyframes loader-short {
    0% {
        left: -200%;
        right: 100%;
    }
    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
}
