/*------------------------------------*\
    # utils.type
\*------------------------------------*/

.u-type {
    /**
     * Font letterform
     */
    &--sans {
        font-family: $ff-sans;
    }

    &--serif {
        font-family: $ff-serif;
    }

    /**
     * Font sizes
     */
    &--tny {
        font-size: $type-tny;
        line-height: 16px;
    }

    &--xsml {
        font-size: $type-xsml;
        line-height: 16px;
    }

    &--sml {
        font-size: $type-sml;
        line-height: 18px;
    }

    &--sbase {
        font-size: $type-sbase;
        font-range: 20px;
    }

    &--base {
        font-size: $type-base;
        font-range: 22px;
    }

    &--med {
        font-size: responsive $type-sml $type-base;
        font-range: 320px 1440px;

        line-height: responsive 24px 28px;
        line-height-range: 320px 1440px;
    }

    &--lrg {
        font-size: responsive $type-med $type-lrg;
        font-range: 320px 1440px;

        line-height: responsive 24px 26px;
        line-height-range: 320px 1440px;
    }

    &--xlrg {
        font-size: responsive $type-lrg $type-xlrg;
        font-range: 320px 1440px;

        line-height: responsive 40px 44px;
        line-height-range: 320px 1440px;
    }

    /**
     * Font weights
     */
    &--wgt {
        &--regular {
            font-weight: $type-weight-regular;
        }

        &--medium {
            font-weight: $type-weight-medium;
        }

        &--semibold {
            font-weight: $type-weight-semibold;
        }

        &--bold {
            font-weight: $type-weight-bold;
        }
    }

    &--style {
        &--italic {
            font-style: italic;
        }
        &--normal {
            font-style: normal;
        }
        &--oblique {
            font-style: oblique;
        }
    }

    /**
     * Type colors
     */
    &--color {
        &--text {
            color: $color-dblue-9;
        }

        &--med {
            color: $color-dblue-8;
        }

        &--light {
            color: $color-dblue-7;
        }

        &--light-sec {
            color: $color-dblue-7 !important;
        }

        &--xlight {
            color: $color-dblue-6;
        }

        &--xxlight {
            color: $color-lblue-16;
        }

        &--title {
            color: $color-dblue-12 !important;
        }

        &--opaque {
            color: color(#fff a(0.6));
        }

        &--primary {
            color: $color-primary-10;
        }

        &--secondary {
            color: $color-secondary;
        }

        &--tertiary {
            color: $color-tertiary;
        }

        &--negative {
            color: white;
        }

        &--warning {
            color: $color-warning;
        }

        &--error {
            color: $color-error;
        }

        &--success {
            color: $color-success;
        }

        &--important {
            color: $color-important;
        }

        &--note {
            color: $color-note;
        }

        &--title {
            color: #415680;
        }
    }

    &--link {
        display: inline-block;

        color: $color-primary;
        cursor: pointer;
        transition: 250ms all ease-in-out;

        &:hover {
            color: color(#f33fff a(0.8));
        }
    }

    &--title {
        font-size: $type-base;
        color: #415680;
        font-weight: $type-weight-semibold;
    }

    /**
     * Type case
     */
    &--uppercase {
        text-transform: uppercase;
    }

    &--lowercase {
        text-transform: lowercase;
    }

    &--narrow {
        letter-spacing: 0.0625em;
    }

    &--wide {
        letter-spacing: 0.125em;
    }

    /**
     * Type positioning
     */
    &--center {
        text-align: center;
    }

    &--left {
        text-align: left;
    }

    &--right {
        text-align: right;
    }

    &--accent {
        font-size: $type-xsml;
        line-height: 16px;
        font-weight: $type-weight-semibold;
        color: $color-primary-10;
        white-space: pre-line;

        &--sml {
            font-size: $type-sml;
            line-height: 18px;
        }
    }

    &--ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &--lrg {
            max-width: 450px;
        }
    }

    &--clip {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: unset;
    }

    &--break {
        word-break: break-all;

        &--word {
            word-break: break-word;
        }
    }

    &--shrink {
        max-width: 150px;
    }
}
