.c-cards {
    border: 1px solid #eff4ff;
    box-shadow: 0px 4px 30px rgba(155, 166, 212, 0.1);
    background-color: $white;
    flex: 1;

    &--color {
        background-color: $color-lblue-4;
        box-shadow: none;
    }

    &--row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        /* max-width: 640px; */
    }

    &--col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &--xsml {
        padding: calc($unit * 2);
    }

    &--sml {
        padding: calc($unit * 3);
        border-radius: calc($unit * 3);
    }

    &--base {
        padding: calc($unit * 3);
        border-radius: calc($unit * 3);
        min-width: 250px;
        /* Height & display needed for Dealer->Catalog (all cards should be the same height) */
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &--med {
        max-width: 650px;
    }

    &--lrg {
        padding: calc($unit * 3);
        border-radius: calc($unit * 3);
    }

    &--xlrg {
        padding: 16px;
        border-radius: calc($unit * 3);
        /* min-width: 246px;*/

        @media (--screen-from-xlrg) {
            max-width: 300px;
        }
    }

    &--xxlrg {
        padding: 16px;
        border-radius: calc($unit * 3);
        width: 340px;

        @media (--screen-to-sml) {
            width: unset;
            min-width: 340px;
        }
    }

    &--xxxlrg {
        padding: 16px;
        border-radius: calc($unit * 3);
        width: 600px;
    }

    &--full {
        padding: 16px;
        border-radius: calc($unit * 3);
        width: 100%;
    }

    &--latest {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: calc($unit * 3);

        width: 100%;

        padding: calc($unit * 3);
        border-radius: calc($unit * 3);

        & .c-cards__header {
            flex: 1;
            align-items: center;
            gap: calc($unit * 2);
        }

        & .u-display--width-fit {
            min-width: calc($unit * 50);

            @media (--screen-to-sml) {
                min-width: unset;
            }
        }

        & .c-cards__title--link {
            min-width: 0;
            max-width: 450px !important;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            &:hover {
                text-decoration: underline;
            }

            @media (--screen-to-sml) {
                max-width: 80px !important;
                word-break: break-word;
            }
        }

        & .c-cards__tag {
            min-width: 80px;

            @media (--screen-to-sml) {
                min-width: 60px;
            }

            &--sml {
                min-width: unset;
            }
        }
    }

    &--dashboard-deal,
    &--deals-deal,
    &--commitments-commitment,
    &--mydeals-deal {
        padding: calc($unit * 3);
        border-radius: calc($unit * 3);
    }

    &--deals-deal,
    &--commitments-commitment,
    &--product {
        max-width: 252px;
        min-width: 220px;

        @media (--screen-from-lrg) {
            min-width: 252px;
        }
    }

    &--dashboard-deal {
        @media (--screen-from-huge) {
            width: 248px;

            /* Previously: max-width: 250px; - runner dashboard deals were too thin */
        }
    }

    &--mydeals-deal {
        flex: unset;
        max-width: 250px;
        /* previously: 247px */
    }

    &--commitments-commitment,
    &--deals-deal,
    &--mydeals-deal,
    &--product {
        @media (--screen-to-sml) {
            max-width: unset;
            flex: 1;
        }
    }

    &__header {
        line-height: 18px;
        display: flex;

        &--horizontal {
            /* align-items: center; */
        }

        &--vertical {
            gap: calc($unit * 3);
            flex-direction: column;
        }
    }

    &__title {
        font-size: $type-sml;
        line-height: 17px;
        color: $color-dblue-10;
        font-weight: $type-weight-semibold;
        /* width: 252px; */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;

        max-height: 34px;

        &--xsml {
            font-size: $type-xsml;
        }

        &--sml {
            width: 200px;

            text-overflow: ellipsis;
            overflow: hidden;
            /* white-space: nowrap; */
            height: 38px;
        }

        &--base {
            width: 158px;
            max-height: 32px;
        }

        &--lrg {
            width: 180px;
            text-overflow: ellipsis;
            overflow: hidden;
            height: 38px;
            font-weight: $type-weight-medium;
        }

        &--xlrg {
            width: 200px;
            height: 32px;
            color: $color-dblue-10;
            font-weight: $type-weight-semibold;
        }

        &--link {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            /* max-width: 120px; */
            max-height: 36px;

            overflow: hidden;
            text-overflow: ellipsis;

            font-weight: $type-weight-semibold;
            color: $color-dblue-10;
            text-align: left;

            border-radius: 0px;
            text-decoration: none;
            word-break: unset;

            &:hover {
                text-decoration: underline;
                transition: all 0.2s ease-in-out;
            }

            &--lrg {
                -webkit-line-clamp: 3;
                max-height: 55px;
            }

            &--xlrg {
                max-width: 500px;
            }

            &--xxlrg {
                max-width: 100%;
            }

            &--underline {
                text-decoration: underline;

                &:hover {
                    color: $color-dblue-12;
                }
            }

            &--big {
                -webkit-line-clamp: 3;
                max-height: 56px;
            }
        }
    }

    &__content {
        margin: calc($unit * 3) 0px;
        padding: calc($unit * 3);

        position: relative;

        background-color: $color-lblue-4;
        border: 1px solid #ebf0ff;
        border-radius: calc($unit * 2);

        @media (--screen-from-xlrg) {
            padding: calc($unit * 4);
        }

        &--fit {
            width: fit-content;
        }

        &--xxsml {
            margin: calc($unit * 2) 0px;
            padding: calc($unit * 2);
        }

        &--xsml {
            padding: calc($unit * 2);
        }

        &--sml {
            margin-top: calc($unit * 3);
            margin-bottom: 0px;
        }

        &--med {
            padding: calc($unit * 2);
            margin: 0px;
            flex-grow: 1;
            flex-basis: 200px;
        }

        &--base,
        &--small {
            margin: 0px;
            flex-grow: 1;
            flex-basis: 200px;
        }

        &--small {
            flex-basis: 100px;
        }

        &--medium {
            display: flex;
            flex-direction: column;
            flex: 1;
            flex-grow: 1;

            @media (--screen-from-lrg) {
                flex-basis: 125px;
            }
        }

        &--lrg {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        &--xlrg {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            min-width: 400px;

            @media (--screen-to-sml) {
                min-width: unset;
            }
        }

        &__container {
            display: flex;
            gap: calc($unit * 5);
        }

        &__item {
            display: flex;
            flex-direction: column;
            gap: 2px;
            min-width: 80px;

            &--full {
                min-width: 0px;
                width: 100%;
            }

            &--lrg {
                width: 540px;
            }
        }

        &__value {
            font-size: $type-xsml;
            font-weight: $type-weight-bold;
            color: $color-primary-10;
        }

        &--secondary {
            display: flex;
            justify-content: space-between;
            flex: 1;

            padding: calc($unit * 3);
            margin-top: calc($unit * 2);

            border-radius: calc($unit * 2);

            cursor: pointer;

            &:hover {
                i {
                    transform: rotate(90deg);
                }
            }
        }

        &--light {
            background-color: $white;
        }

        &--white {
            background-color: $white;
            border: 1px solid #d6e1ff;
        }

        &--scrollable {
            max-height: 400px;
            overflow-y: auto;
        }
    }

    &--stretch {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-self: stretch;
    }

    &__tag {
        padding: $unit;
        /* background-color: $color-lblue-4; */
        padding: calc($unit * 1);
        padding-right: calc($unit * 1.5);
        border-radius: 100px;
        color: $color-dblue-6;
        font-size: $type-xsml;
        font-weight: $type-weight-semibold;

        max-width: 90px;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        /* height: 38px; */

        &__icon {
            padding: calc($unit * 2.5);
            border-radius: 100px;
            background-color: $color-lblue-9;
            margin-right: $unit;
        }

        &--sml {
            width: fit-content;
        }

        &--secondary {
            bottom: 6px;
            right: 6px;
            padding: 3px 6px;
            background: red;
            border-radius: 100px;
            background-color: $color-lblue-10;
            border: 1px solid $color-lblue-12;

            font-size: $type-tny;
            text-transform: uppercase;
            font-weight: $type-weight-semibold;
            color: $color-dblue-10;
        }
    }

    &--product {
        width: 100%;
        background-color: white;

        &__title {
            font-size: calc($unit * 3);
            color: $color-dblue-7;
            font-weight: $type-weight-bold;
            max-width: 150px;
        }

        &__image {
            height: 32px;
            width: 32px;
            border-radius: calc($unit * 1.5);
        }
    }

    &__image {
        height: 40px;
        width: 40px;
        border-radius: calc($unit * 2);
        object-fit: cover;

        &--background {
            background-size: cover;
            background-repeat: no-repeat;
        }

        &--overlay {
            border-radius: calc($unit * 3);
            position: relative;
            display: inline-block;
            height: fit-content;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: inline-block;
                background: rgba(21, 40, 137, 0.12);
                border-radius: calc($unit * 2);
            }
            img {
                display: block;
            }

            &:has(.c-single__image) {
                @media (--screen-to-sml) {
                    width: 100%;
                }
            }
        }

        &--sml {
            height: 32px;
            width: 32px;

            border-radius: calc($unit * 1);
        }

        &--base {
            height: 40px;
            width: 40px;

            border-radius: calc($unit * 1);
        }

        &--med {
            height: 55px;
            width: 55px;
        }

        &--mlrg {
            height: 65px;
            width: 65px;
        }

        &--lrg {
            height: 70px;
            width: 70px;
        }

        &--xlrg {
            width: 100%;
            object-fit: cover;
            height: 80px;
        }
    }

    &__wrapper {
        /* height: 300px; */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__action {
    }

    &--inactive {
        margin: 0px;
        margin-top: calc($unit * 3);
        padding: calc($unit * 2);
        background-color: $color-error-background;
        border: 1px solid #fce8ed;
        border-radius: calc($unit * 2);

        &__text {
            font-size: $type-xsml;
            font-weight: $type-weight-medium;
            color: $color-error;
        }

        &--sml {
            padding: 2px 6px;
        }
    }

    &__text {
        color: $color-dblue-6;
        font-size: $type-sml;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        gap: calc($unit * 2);

        &--base {
            max-height: 300px;
            overflow-y: auto;
        }
    }

    &--link-hover {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            box-shadow: rgba(3, 18, 32, 0.05) 0px 20px 20px, rgba(3, 18, 32, 0.05) 0px 10px 15px;
            transition: all 0.2s ease-in-out;
        }
    }

    &__expandable {
        position: relative;
        margin: calc($unit * 2) 0px;
        padding: calc($unit * 2);
        background-color: $color-lblue-4;
        border: 1px solid #ebf0ff;
        border-radius: calc($unit * 2);

        cursor: pointer;

        &.isOpen {
            .u-icon--arrow-down {
                transform: rotate(180deg);
                transition: all 0.2s ease-in-out;
            }
        }

        &__main {
            display: none;
            overflow-y: auto;
            overflow-x: hidden;

            width: 100%;

            &.isOpen {
                display: block;
            }
        }
    }

    &__list {
        li {
            margin-left: calc($unit * 4);
            list-style-type: circle;
            color: $color-dblue-9;

            @media (--screen-to-sml) {
                text-align: left;
            }
        }
    }

    &__link {
        position: relative;

        &:hover {
            & .c-tooltip {
                display: block;
            }
        }
    }

    &__badge {
        z-index: 3;

        position: absolute;
        top: -14px;

        &--left {
            left: 4px;
        }

        &--tl {
            top: 12px;
            left: 12px;

            &--secondary {
                top: 16px;
                left: 16px;
            }

            &--tertiary {
                top: 12px;
                left: 68px;
            }
        }

        &--bl {
            top: auto;
            bottom: 12px;
            left: 12px;
        }
    }
}
