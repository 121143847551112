/*------------------------------------*\
    # component.container
\*------------------------------------*/

.c-container {
    max-width: 1440px;
    margin: 0 auto;

    &--lrg {
        max-width: 1080px;
    }

    /*make container stretch from one side to another*/
    &--fluid {
        width: 100%;
        margin: 0;
    }

    &--xsml {
        position: absolute;
        left: 250px;
        top: 73px;
        width: calc(100% - 828px);
        height: calc(100% - 73px);
        overflow: auto;
        overflow-x: hidden;

        /* margin: -32px; */
        padding: 32px;
        padding-right: 0px;
    }

    &--sml {
        left: 250px;
        /* max-width: 1312px; */
        padding: 32px;
        position: absolute;
        top: 73px;
        width: calc(100% - 610px);
        overflow: auto;
        height: calc(100% - 73px);
        overflow-x: hidden;

        &.isCollapsed {
            left: 60px;
        }
    }

    &--sml-optional {
        left: 0px;
        padding: 32px;
        position: absolute;
        top: 0px;
        width: calc(100% - 360px);
        overflow: auto;
        height: 100%;
        overflow-x: hidden;

        &.isCollapsed {
            left: 60px;
        }
    }

    &--base {
        left: 250px;
        /* max-width: 1670px; */
        padding: 32px;
        position: absolute;
        top: 73px;
        width: calc(100% - 250px);
        overflow: auto;
        height: calc(100% - 73px);
        overflow-x: hidden;

        &.isCollapsed {
            left: 60px;
            width: calc(100% - 60px);

            @media (--screen-to-sml) {
                left: 0px;
                width: 100%;
            }

            &:has(.asideIsClosed) {
                width: calc(100% - 100px);

                @media (--screen-to-sml) {
                    width: calc(100% - 40px);
                }
            }

            &:has(.asideIsOpen) {
                width: calc(100% - 420px);
            }
        }

        &:has(.asideIsClosed) {
            width: calc(100% - 280px);
        }

        @media (--screen-to-sml) {
            padding: calc($unit * 5);
        }
    }

    &__title {
        margin-bottom: calc($unit * 6);
        color: $color-dblue-12;
        font-size: $type-lrg;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &--sml {
            margin-bottom: 0px;
        }

        &--med {
            color: $color-dblue-8;
            font-size: $type-sbase;
            font-weight: $type-weight-medium;
        }

        &--base {
            @media (--screen-to-sml) {
                max-width: 220px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: unset;
                line-height: calc($unit * 6);
            }
        }
    }

    &--content {
        width: calc(100vw - 610px);
    }
}
