.c-steps {
    display: flex;
    justify-content: space-between;
    flex: 1;
    position: relative;

    &__container {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-lblue-6;
        border-radius: 100px;
        margin-bottom: calc($unit * 1);
    }

    &__number {
        font-weight: $type-weight-semibold;
        color: $color-primary-10;
        font-size: $type-base;
    }

    &__step {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__title {
        font-size: $type-sml;
        color: $color-dblue-12;
        font-weight: $type-weight-medium;
    }

    &--active {
        .c-steps__container {
            background-color: $color-lblue-10;
            border: 1px solid $color-primary-8;
        }
        .c-steps__number, .c-steps__title {
            color: $color-primary-10;
        }
    }

    &--visited {
        .c-steps__container {
            background-color: $color-primary-10;
            border: 1px solid $color-primary-10;
        }
        .c-steps__number {
            color: $white;
        }
    }

    

    &__overview {
        padding: calc($unit * 3);
        background: $color-lblue-6;
        border-radius: calc($unit * 2);

        &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__progress {
        background-color: $color-primary-10;
        position: absolute;
        top: 35%;
        left: 0;
        height: 2px;
        width: 45%;
        margin-left: $unit;
        transform: translateY(-50%);
        z-index: -1;
        transition: 0.4s ease-in;
    }

    &::before {
        content: '';
        background-color: $color-lblue-8;
        position: absolute;
        top: 35%;
        left: 0;
        height: 2px;
        width: 95%;
        margin-left: $unit;
        transform: translateY(-50%);
        z-index: -1;
        transition: 0.4s ease-in;
    }
}
