/*------------------------------------*\
# utils.margins
\*------------------------------------*/

.u {
    &-mt {
        &--sml {
            &--0 {
                margin-top: 0px;
            }
            &--05 {
                margin-top: calc($unit / 2);
            }
            &--1 {
                margin-top: $unit;
            }
            &--2 {
                margin-top: calc($unit * 2);
            }
            &--3 {
                margin-top: calc($unit * 3);
            }
            &--4 {
                margin-top: calc($unit * 4);
            }
            &--5 {
                margin-top: calc($unit * 5);
            }
            &--6 {
                margin-top: calc($unit * 6);
            }
            &--7 {
                margin-top: calc($unit * 7);
            }
            &--8 {
                margin-top: calc($unit * 8);
            }
            &--9 {
                margin-top: calc($unit * 9);
            }
            &--10 {
                margin-top: calc($unit * 10);
            }

            &--15 {
                margin-top: calc($unit * 15);
            }
        }

        &--med {
            @media (--screen-from-sml) {
                &--0 {
                    margin-top: 0px;
                }
                &--1 {
                    margin-top: $unit;
                }
                &--2 {
                    margin-top: calc($unit * 2);
                }
                &--3 {
                    margin-top: calc($unit * 3);
                }
                &--4 {
                    margin-top: calc($unit * 4);
                }
                &--5 {
                    margin-top: calc($unit * 5);
                }
                &--6 {
                    margin-top: calc($unit * 6);
                }
                &--7 {
                    margin-top: calc($unit * 7);
                }
                &--8 {
                    margin-top: calc($unit * 8);
                }
                &--9 {
                    margin-top: calc($unit * 9);
                }
                &--10 {
                    margin-top: calc($unit * 10);
                }
            }
        }

        &--lrg {
            @media (--screen-from-med) {
                &--1 {
                    margin-top: $unit;
                }
                &--2 {
                    margin-top: calc($unit * 2);
                }
                &--3 {
                    margin-top: calc($unit * 3);
                }
                &--4 {
                    margin-top: calc($unit * 4);
                }
                &--5 {
                    margin-top: calc($unit * 5);
                }
                &--6 {
                    margin-top: calc($unit * 6);
                }
                &--7 {
                    margin-top: calc($unit * 7);
                }
                &--8 {
                    margin-top: calc($unit * 8);
                }
                &--9 {
                    margin-top: calc($unit * 9);
                }
                &--10 {
                    margin-top: calc($unit * 10);
                }
            }
        }

        &--xlrg {
            @media (--screen-from-lrg) {
                &--1 {
                    margin-top: $unit;
                }
                &--2 {
                    margin-top: calc($unit * 2);
                }
                &--3 {
                    margin-top: calc($unit * 3);
                }
                &--4 {
                    margin-top: calc($unit * 4);
                }
                &--5 {
                    margin-top: calc($unit * 5);
                }
                &--6 {
                    margin-top: calc($unit * 6);
                }
                &--7 {
                    margin-top: calc($unit * 7);
                }
                &--8 {
                    margin-top: calc($unit * 8);
                }
                &--9 {
                    margin-top: calc($unit * 9);
                }
                &--10 {
                    margin-top: calc($unit * 10);
                }
            }
        }

        &--xxlrg {
            @media (--screen-from-xlrg) {
                &--1 {
                    margin-top: $unit;
                }
                &--2 {
                    margin-top: calc($unit * 2);
                }
                &--3 {
                    margin-top: calc($unit * 3);
                }
                &--4 {
                    margin-top: calc($unit * 4);
                }
                &--5 {
                    margin-top: calc($unit * 5);
                }
                &--6 {
                    margin-top: calc($unit * 6);
                }
                &--7 {
                    margin-top: calc($unit * 7);
                }
                &--8 {
                    margin-top: calc($unit * 8);
                }
                &--9 {
                    margin-top: calc($unit * 9);
                }
                &--10 {
                    margin-top: calc($unit * 10);
                }
            }
        }

        &--huge {
            @media (--screen-from-xxlrg) {
                &--1 {
                    margin-top: $unit;
                }
                &--2 {
                    margin-top: calc($unit * 2);
                }
                &--3 {
                    margin-top: calc($unit * 3);
                }
                &--4 {
                    margin-top: calc($unit * 4);
                }
                &--5 {
                    margin-top: calc($unit * 5);
                }
                &--6 {
                    margin-top: calc($unit * 6);
                }
                &--7 {
                    margin-top: calc($unit * 7);
                }
                &--8 {
                    margin-top: calc($unit * 8);
                }
                &--9 {
                    margin-top: calc($unit * 9);
                }
                &--10 {
                    margin-top: calc($unit * 10);
                }
            }
        }
    }

    &-mr {
        &--sml {
            &--1 {
                margin-right: $unit;
            }
            &--2 {
                margin-right: calc($unit * 2);
            }
            &--3 {
                margin-right: calc($unit * 3);
            }
            &--4 {
                margin-right: calc($unit * 4);
            }
            &--5 {
                margin-right: calc($unit * 5);
            }
            &--6 {
                margin-right: calc($unit * 6);
            }
            &--7 {
                margin-right: calc($unit * 7);
            }
            &--8 {
                margin-right: calc($unit * 8);
            }
            &--9 {
                margin-right: calc($unit * 9);
            }
            &--10 {
                margin-right: calc($unit * 10);
            }
        }

        &--med {
            @media (--screen-from-sml) {
                &--1 {
                    margin-right: $unit;
                }
                &--2 {
                    margin-right: calc($unit * 2);
                }
                &--3 {
                    margin-right: calc($unit * 3);
                }
                &--4 {
                    margin-right: calc($unit * 4);
                }
                &--5 {
                    margin-right: calc($unit * 5);
                }
                &--6 {
                    margin-right: calc($unit * 6);
                }
                &--7 {
                    margin-right: calc($unit * 7);
                }
                &--8 {
                    margin-right: calc($unit * 8);
                }
                &--9 {
                    margin-right: calc($unit * 9);
                }
                &--10 {
                    margin-right: calc($unit * 10);
                }
            }
        }

        &--lrg {
            @media (--screen-from-med) {
                &--1 {
                    margin-right: $unit;
                }
                &--2 {
                    margin-right: calc($unit * 2);
                }
                &--3 {
                    margin-right: calc($unit * 3);
                }
                &--4 {
                    margin-right: calc($unit * 4);
                }
                &--5 {
                    margin-right: calc($unit * 5);
                }
                &--6 {
                    margin-right: calc($unit * 6);
                }
                &--7 {
                    margin-right: calc($unit * 7);
                }
                &--8 {
                    margin-right: calc($unit * 8);
                }
                &--9 {
                    margin-right: calc($unit * 9);
                }
                &--10 {
                    margin-right: calc($unit * 10);
                }
            }
        }

        &--xlrg {
            @media (--screen-from-lrg) {
                &--1 {
                    margin-right: $unit;
                }
                &--2 {
                    margin-right: calc($unit * 2);
                }
                &--3 {
                    margin-right: calc($unit * 3);
                }
                &--4 {
                    margin-right: calc($unit * 4);
                }
                &--5 {
                    margin-right: calc($unit * 5);
                }
                &--6 {
                    margin-right: calc($unit * 6);
                }
                &--7 {
                    margin-right: calc($unit * 7);
                }
                &--8 {
                    margin-right: calc($unit * 8);
                }
                &--9 {
                    margin-right: calc($unit * 9);
                }
                &--10 {
                    margin-right: calc($unit * 10);
                }
            }
        }

        &--xxlrg {
            @media (--screen-from-xlrg) {
                &--1 {
                    margin-right: $unit;
                }
                &--2 {
                    margin-right: calc($unit * 2);
                }
                &--3 {
                    margin-right: calc($unit * 3);
                }
                &--4 {
                    margin-right: calc($unit * 4);
                }
                &--5 {
                    margin-right: calc($unit * 5);
                }
                &--6 {
                    margin-right: calc($unit * 6);
                }
                &--7 {
                    margin-right: calc($unit * 7);
                }
                &--8 {
                    margin-right: calc($unit * 8);
                }
                &--9 {
                    margin-right: calc($unit * 9);
                }
                &--10 {
                    margin-right: calc($unit * 10);
                }
            }
        }

        &--huge {
            @media (--screen-from-xxlrg) {
                &--1 {
                    margin-right: $unit;
                }
                &--2 {
                    margin-right: calc($unit * 2);
                }
                &--3 {
                    margin-right: calc($unit * 3);
                }
                &--4 {
                    margin-right: calc($unit * 4);
                }
                &--5 {
                    margin-right: calc($unit * 5);
                }
                &--6 {
                    margin-right: calc($unit * 6);
                }
                &--7 {
                    margin-right: calc($unit * 7);
                }
                &--8 {
                    margin-right: calc($unit * 8);
                }
                &--9 {
                    margin-right: calc($unit * 9);
                }
                &--10 {
                    margin-right: calc($unit * 10);
                }
            }
        }
    }

    &-mb {
        &--sml {
            &--0 {
                margin-bottom: 0px;
            }

            &--05 {
                margin-bottom: calc($unit / 2);
            }

            &--1 {
                margin-bottom: $unit;
            }
            &--2 {
                margin-bottom: calc($unit * 2);
            }
            &--3 {
                margin-bottom: calc($unit * 3);
            }
            &--4 {
                margin-bottom: calc($unit * 4);
            }
            &--5 {
                margin-bottom: calc($unit * 5);
            }
            &--6 {
                margin-bottom: calc($unit * 6);
            }
            &--7 {
                margin-bottom: calc($unit * 7);
            }
            &--8 {
                margin-bottom: calc($unit * 8);
            }
            &--9 {
                margin-bottom: calc($unit * 9);
            }
            &--10 {
                margin-bottom: calc($unit * 10);
            }
            &--15 {
                margin-bottom: calc($unit * 15);
            }

            &--20 {
                margin-bottom: calc($unit * 20);
            }
        }

        &--med {
            @media (--screen-from-sml) {
                &--0 {
                    margin-bottom: 0px;
                }
                &--1 {
                    margin-bottom: $unit;
                }
                &--2 {
                    margin-bottom: calc($unit * 2);
                }
                &--3 {
                    margin-bottom: calc($unit * 3);
                }
                &--4 {
                    margin-bottom: calc($unit * 4);
                }
                &--5 {
                    margin-bottom: calc($unit * 5);
                }
                &--6 {
                    margin-bottom: calc($unit * 6);
                }
                &--7 {
                    margin-bottom: calc($unit * 7);
                }
                &--8 {
                    margin-bottom: calc($unit * 8);
                }
                &--9 {
                    margin-bottom: calc($unit * 9);
                }
                &--10 {
                    margin-bottom: calc($unit * 10);
                }
                &--20 {
                    margin-bottom: calc($unit * 20);
                }
            }
        }

        &--lrg {
            @media (--screen-from-med) {
                &--1 {
                    margin-bottom: $unit;
                }
                &--2 {
                    margin-bottom: calc($unit * 2);
                }
                &--3 {
                    margin-bottom: calc($unit * 3);
                }
                &--4 {
                    margin-bottom: calc($unit * 4);
                }
                &--5 {
                    margin-bottom: calc($unit * 5);
                }
                &--6 {
                    margin-bottom: calc($unit * 6);
                }
                &--7 {
                    margin-bottom: calc($unit * 7);
                }
                &--8 {
                    margin-bottom: calc($unit * 8);
                }
                &--9 {
                    margin-bottom: calc($unit * 9);
                }
                &--10 {
                    margin-bottom: calc($unit * 10);
                }
            }
        }

        &--xlrg {
            @media (--screen-from-lrg) {
                &--1 {
                    margin-bottom: $unit;
                }
                &--2 {
                    margin-bottom: calc($unit * 2);
                }
                &--3 {
                    margin-bottom: calc($unit * 3);
                }
                &--4 {
                    margin-bottom: calc($unit * 4);
                }
                &--5 {
                    margin-bottom: calc($unit * 5);
                }
                &--6 {
                    margin-bottom: calc($unit * 6);
                }
                &--7 {
                    margin-bottom: calc($unit * 7);
                }
                &--8 {
                    margin-bottom: calc($unit * 8);
                }
                &--9 {
                    margin-bottom: calc($unit * 9);
                }
                &--10 {
                    margin-bottom: calc($unit * 10);
                }
            }
        }

        &--xxlrg {
            @media (--screen-from-xlrg) {
                &--1 {
                    margin-bottom: $unit;
                }
                &--2 {
                    margin-bottom: calc($unit * 2);
                }
                &--3 {
                    margin-bottom: calc($unit * 3);
                }
                &--4 {
                    margin-bottom: calc($unit * 4);
                }
                &--5 {
                    margin-bottom: calc($unit * 5);
                }
                &--6 {
                    margin-bottom: calc($unit * 6);
                }
                &--7 {
                    margin-bottom: calc($unit * 7);
                }
                &--8 {
                    margin-bottom: calc($unit * 8);
                }
                &--9 {
                    margin-bottom: calc($unit * 9);
                }
                &--10 {
                    margin-bottom: calc($unit * 10);
                }
            }
        }

        &--huge {
            @media (--screen-from-xxlrg) {
                &--1 {
                    margin-bottom: $unit;
                }
                &--2 {
                    margin-bottom: calc($unit * 2);
                }
                &--3 {
                    margin-bottom: calc($unit * 3);
                }
                &--4 {
                    margin-bottom: calc($unit * 4);
                }
                &--5 {
                    margin-bottom: calc($unit * 5);
                }
                &--6 {
                    margin-bottom: calc($unit * 6);
                }
                &--7 {
                    margin-bottom: calc($unit * 7);
                }
                &--8 {
                    margin-bottom: calc($unit * 8);
                }
                &--9 {
                    margin-bottom: calc($unit * 9);
                }
                &--10 {
                    margin-bottom: calc($unit * 10);
                }
            }
        }
    }

    &-ml {
        &--sml {
            &--1 {
                margin-left: $unit;
            }
            &--2 {
                margin-left: calc($unit * 2);
            }
            &--3 {
                margin-left: calc($unit * 3);
            }
            &--4 {
                margin-left: calc($unit * 4);
            }
            &--5 {
                margin-left: calc($unit * 5);
            }
            &--6 {
                margin-left: calc($unit * 6);
            }
            &--7 {
                margin-left: calc($unit * 7);
            }
            &--8 {
                margin-left: calc($unit * 8);
            }
            &--9 {
                margin-left: calc($unit * 9);
            }
            &--10 {
                margin-left: calc($unit * 10);
            }
        }

        &--med {
            @media (--screen-from-sml) {
                &--1 {
                    margin-left: $unit;
                }
                &--2 {
                    margin-left: calc($unit * 2);
                }
                &--3 {
                    margin-left: calc($unit * 3);
                }
                &--4 {
                    margin-left: calc($unit * 4);
                }
                &--5 {
                    margin-left: calc($unit * 5);
                }
                &--6 {
                    margin-left: calc($unit * 6);
                }
                &--7 {
                    margin-left: calc($unit * 7);
                }
                &--8 {
                    margin-left: calc($unit * 8);
                }
                &--9 {
                    margin-left: calc($unit * 9);
                }
                &--10 {
                    margin-left: calc($unit * 10);
                }
            }
        }

        &--lrg {
            @media (--screen-from-med) {
                &--1 {
                    margin-left: $unit;
                }
                &--2 {
                    margin-left: calc($unit * 2);
                }
                &--3 {
                    margin-left: calc($unit * 3);
                }
                &--4 {
                    margin-left: calc($unit * 4);
                }
                &--5 {
                    margin-left: calc($unit * 5);
                }
                &--6 {
                    margin-left: calc($unit * 6);
                }
                &--7 {
                    margin-left: calc($unit * 7);
                }
                &--8 {
                    margin-left: calc($unit * 8);
                }
                &--9 {
                    margin-left: calc($unit * 9);
                }
                &--10 {
                    margin-left: calc($unit * 10);
                }
            }
        }

        &--xlrg {
            @media (--screen-from-lrg) {
                &--1 {
                    margin-left: $unit;
                }
                &--2 {
                    margin-left: calc($unit * 2);
                }
                &--3 {
                    margin-left: calc($unit * 3);
                }
                &--4 {
                    margin-left: calc($unit * 4);
                }
                &--5 {
                    margin-left: calc($unit * 5);
                }
                &--6 {
                    margin-left: calc($unit * 6);
                }
                &--7 {
                    margin-left: calc($unit * 7);
                }
                &--8 {
                    margin-left: calc($unit * 8);
                }
                &--9 {
                    margin-left: calc($unit * 9);
                }
                &--10 {
                    margin-left: calc($unit * 10);
                }
            }
        }

        &--xxlrg {
            @media (--screen-from-xlrg) {
                &--1 {
                    margin-left: $unit;
                }
                &--2 {
                    margin-left: calc($unit * 2);
                }
                &--3 {
                    margin-left: calc($unit * 3);
                }
                &--4 {
                    margin-left: calc($unit * 4);
                }
                &--5 {
                    margin-left: calc($unit * 5);
                }
                &--6 {
                    margin-left: calc($unit * 6);
                }
                &--7 {
                    margin-left: calc($unit * 7);
                }
                &--8 {
                    margin-left: calc($unit * 8);
                }
                &--9 {
                    margin-left: calc($unit * 9);
                }
                &--10 {
                    margin-left: calc($unit * 10);
                }
            }
        }

        &--huge {
            @media (--screen-from-xxlrg) {
                &--1 {
                    margin-left: $unit;
                }
                &--2 {
                    margin-left: calc($unit * 2);
                }
                &--3 {
                    margin-left: calc($unit * 3);
                }
                &--4 {
                    margin-left: calc($unit * 4);
                }
                &--5 {
                    margin-left: calc($unit * 5);
                }
                &--6 {
                    margin-left: calc($unit * 6);
                }
                &--7 {
                    margin-left: calc($unit * 7);
                }
                &--8 {
                    margin-left: calc($unit * 8);
                }
                &--9 {
                    margin-left: calc($unit * 9);
                }
                &--10 {
                    margin-left: calc($unit * 10);
                }
            }
        }
    }
}
