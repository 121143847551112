.c-aside {
    position: fixed;
    top: $header-height;
    height: calc(100vh-73px);
    border-left: 1px solid $color-lblue-12;

    &__content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        overflow-y: auto;
        padding: calc($unit * 8);
    }

    &--right {
        right: 0;
        width: 360px;

        background-color: #e3eafd;
        transition: all 100ms ease-out;

        overflow-y: auto;
        padding: calc($unit * 8);

        z-index: 4;

        &--lrg {
            width: 578px;
        }

        &--xlrg {
            width: 35%;
        }

        &--xxlrg {
            width: 40%;
        }

        &--base,
        &--huge {
            padding: 0px;
            width: calc($unit * 10);
            overflow: unset;
        }

        & .c-btn--collapse {
            position: absolute;
            left: calc(-$unit * 4);
            top: calc($unit * 6);

            width: calc($unit * 8);
            height: calc($unit * 8);

            background: $color-primary-10;
            color: $color-text-light;
            border: 1px solid transparent;

            border-radius: $radius-full;
            transition: all 100ms ease-out;
            z-index: 4;
        }

        &.asideIsOpen {

            &.c-aside--right {
                &--base {
                    width: 360px;
                }

                &--huge {
                    width: 500px;
    
                    @media (--screen-from-xlrg) {
                        width: 800px;
                    }
                }
            }
        }
    }

    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 360px;
        bottom: 0;

        background-color: color($color-text-dark a(0.8));

        opacity: 0;
        visibility: hidden;
        transition: all 150ms ease-out;
        z-index: 3;

        &.show {
            opacity: 1;
            visibility: visible;
        }
    }

    &--menu {
        position: fixed;
    }
}
