.c-section {
    display: flex;
    align-items: flex-start;

    &--deals {
        width: 50%;

        &--sml {
            width: 50%;

            @media (--screen-to-lrg) {
                width: 100%;
            }
        }
    }

    &--my-deals {
        width: 50%;
    }

    &--catalog {
        &__title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: calc($unit * 4);

            @media (--screen-to-med) {
                flex-direction: column;
                align-items: flex-start;
                 margin-bottom: calc($unit * 2);

                 h3 {
                     margin-bottom: calc($unit * 4);
                 }

                 & .c-input__wrapper, 
                 & .c-input--search__wrapper {
                    width: 100%;
                 }
            }
        }
    }

    &--commitments {
        /* width: 40%; */
        flex: 1;

        @media (--screen-to-huge) {
            width: 100%;
        }

        @media (--screen-from-huge) {
            /* width: 354px; */
        }
    }

    &--cards {
        &--sml {
            gap: 8px;
        }

        &--base {
            gap: 12px;
        }

        &--med {
            gap: 16px;
            width: 300px;
        }

        &--lrg {
            gap: 32px;
        }

        &--vertical {
            flex-direction: column;
        }
    }

    &--products {
        flex: 1;
    }

    &--settings {
        max-width: 500px;
        /* // ! before: 400px  */
    }

    &--finances {
        &__new {
            max-width: 490px;
            padding: calc($unit * 4);
            border-radius: calc($unit * 3);
            background-color: white;
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: calc($unit * 4);

        &--base {
            font-size: $type-base;
            font-weight: $type-weight-semibold;
            color: $color-dblue-12;
            margin-bottom: calc($unit * 4);
        }

        &--sbase {
            font-size: $type-base;
            font-weight: $type-weight-semibold;
            color: $color-dblue-12;
            margin-bottom: calc($unit * 3);
        }

        &--sml {
            font-size: $type-base;
            font-weight: $type-weight-semibold;
            color: $color-dblue-12;
            margin-bottom: 0px;
        }

        &--xsml {
            font-size: $type-sbase;
            font-weight: $type-weight-medium;
            color: $color-dblue-12;
            margin-bottom: 0px;
        }
    }

    &--balance {
        padding: calc($unit * 5);
        padding-top: calc($unit * 4);

        border-radius: calc($unit * 4);

        background-color: $color-lblue-8;

        &__card {
            border-radius: 12px;

            &--lrg {
                padding: calc($unit * 4);
                background-color: $white;
                display: inline-flex;
                align-items: center;
                gap: calc($unit * 6);
            }

            &--base {
                padding: calc($unit * 3) calc($unit * 4);
                background-color: $white;
            }

            &__value {
                font-size: $type-lrg;
                color: $color-dblue-10;
                margin-bottom: 4px;
            }

            &__title {
                font-size: $type-sml;
                color: #7188bb;
            }

            &__icon {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
                width: 48px;
                background-color: $color-lblue-6;
                border-radius: calc($unit * 2);
            }

            &--secondary {
                flex-basis: 200px;
                flex-grow: 1;

                margin: 0;
                padding: calc($unit * 2);

                border-radius: calc($unit * 2);
                border: 1px solid $color-lblue-8;

                background-color: #f5f8ff;

                &__title {
                    font-size: $type-sml;
                    font-weight: $type-weight-medium;
                    color: color(#03325e a(0.7)) !important;
                    /* color: #7188bb; */
                }

                &__value {
                    font-size: 16px;
                    color: $color-dblue-10;
                    margin-bottom: 4px;
                    font-weight: $type-weight-semibold;
                }
            }
        }
    }

    &--latest-deals {
        @media (--screen-to-huge) {
            width: 100%;
        }
    }
    &--dashboard {
        display: flex;
        /* display: grid;
        grid-template-columns: 1fr; */
        align-items: flex-start;
        gap: calc($unit * 5);
        width: 100%;

        margin-bottom: calc($unit * 5);

        @media (--screen-to-xxlrg) {
            flex-direction: column;
        }

        /* @media (--screen-from-xxlrg) {
            grid-template-columns: 1fr 1fr; 
           
        } */

        &__item {
            width: 100%;
            max-width: 800px;
        }
    }

    &__mydeals {
        &:has(.c-empty) {
            width: 100%;
        }
    }
}
